import { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { PropTypes } from "./BulkProducts.types";
import { Heading } from "../Heading";
import { BulkProductsTable } from "../BulkProductsTable";
import { BasicModal } from "../BasicModal";
import { HistoricalCertificationInfo } from "../HistoricalCertificationInfo";
import { UserContext } from "../../contexts/user";
import { useLocation } from "react-router-dom";

export function BulkProducts({ inModal, inModalCallback }: PropTypes) {
	const { user } = useContext(UserContext);
	const location = useLocation();
	const [showHistoricalModal, setShowHistoricalModal] = useState(false);

	useEffect(() => {
		if (
			!user?.is_superuser &&
			!user?.company.historically_approved &&
			!location.state?.hideInformationModal
		) {
			setShowHistoricalModal(true);
		}
	}, [location.state?.hideInformationModal, user]);

	return (
		<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Heading
				title={"Add Products"}
				showSearch={false}
				setShowProductInformation={undefined}
				noMarginBottom
			/>
			<BulkProductsTable
				inModal={inModal}
				inModalCallback={inModalCallback}
			></BulkProductsTable>

			<BasicModal
				width={600}
				showModal={showHistoricalModal}
				handleClose={() => {
					setShowHistoricalModal(false);
				}}
				children={
					<HistoricalCertificationInfo
						title="Enter your historical data before you certify new products"
						subtitle={
							<p>
								On this page, you will enter all product and LCA information of
								products you have registered with ClimeCo or Carbonfund.org in
								the past. You will then add your historical sales data and a
								ClimeCo representative will approve your account.
								<br />
								<br />
								Please make sure you are entering in the information correctly
								as it will have to be entered in again if any aspects are
								inaccurate.
								<br />
								<br /> Once you have been approved, you can start adding and
								certifying new products along with updating your sales numbers
								and managing your offset inventory.
							</p>
						}
						buttonTitle="Ok"
						setShowHistoricalModal={setShowHistoricalModal}
					/>
				}
			/>
		</Box>
	);
}
