import { Chip } from "@mui/material";

const getChipBackgroundColor = (status: "ACTIVE" | "PENDING" | "OUTDATED") => {
	if (status.toLowerCase() === "active") {
		return "#6ab04c";
	} else if (status.toLowerCase() === "outdated") {
		return "#eb4d4b";
	} else if (status.toLowerCase() === "pending") {
		return "#f9ca24";
	}
};

const getChipColor = (status: "ACTIVE" | "PENDING" | "OUTDATED") => {
	if (status.toLowerCase() === "active") {
		return "#fff";
	} else if (status.toLowerCase() === "outdated") {
		return "#fff";
	} else if (status.toLowerCase() === "pending") {
		return "#000";
	}
};

export function ProductStatusChip({
	status,
	size = "small",
}: {
	status: "ACTIVE" | "PENDING" | "OUTDATED";
	size?: "medium" | "small";
}) {
	return (
		<Chip
			label={status}
			size={size}
			sx={{
				backgroundColor: getChipBackgroundColor(status),
				color: getChipColor(status),
				fontWeight: "bold",
				fontSize: 10,
			}}
		/>
	);
}
