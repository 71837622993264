import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Raleway, Arial',
        h4: {
            fontSize: 20,
        },
    },
    palette: {
        primary: {
            main: "#25406D"
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    fontSize: '12px',
                    //fontWeight: "bold",
                    borderRadius: "80px",
                    //textTransform: "none",
                    cursor: "pointer",
                    "&:disabled": {
                        backgroundColor: "#25406D",
                        opacity: 0.4,
                        color: "#fff"
                    }
                },

            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                },
                inputRoot: {
                    overflow: "hidden",
                },
                input: {
                    padding: "8px 4px 8px 5px; !important"
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fafafa",
                    "& fieldset": { border: "none", height: "60px" },
                    marginRight: 2,
                },
            },

        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: "#fafafa",
                    '&:focus': {
                        backgroundColor: "#fafafa",
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                },
                icon: {
                    color: "rgba(0, 0, 0, 0.54)",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        backgroundColor: "#fafafa",
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                    "& fieldset": {
                        border: "none",
                    },

                    color: "rgba(0, 0, 0, 0.87)",
                },
                notchedOutline: {
                    border: "none",
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                    color: "rgba(0, 0, 0, 0.54)",
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: 14
                }
            },
        }
    },
});

export default theme