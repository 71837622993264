import { useContext, useState, useEffect } from "react";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { Heading } from "../Heading";
import { Stepper } from "../Stepper";
import { makeStyles } from "@mui/styles";
import {
	BasicModal,
	BulkProducts,
	BulkProductsTable,
	CertificationAgree,
	CertificationConfirmation,
} from "..";
import {
	ICertification,
	ICertificationSteps,
	IEstimation,
	IProduct,
	IStep,
	ILCA,
} from "../../models";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	certification as certificationActions,
	certificationProcessStep as certificationProcessStepActions,
	estimation as estimationsActions,
	sale as saleActions,
	strings as stringActions,
	product as productActions,
	lca as lcaActions,
	productLCAVersionEmission as productLCAVersionEmissionActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { ConfirmationDataPropTypes } from "../CertificationConfirmation/CertificationConfirmation.types";
import { PurchaseOffsets } from "../PurchaseOffsets";
import { NumericFormat } from "react-number-format";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfettiExplosion from "react-confetti-explosion";
import ReactHtmlParser from "react-html-parser";
import { motion } from "framer-motion";
import { UploadContracts } from "../UploadContracts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ProductSummarys } from "../ProductSummarys";
import { ISubscription } from "../../models/subscription-model";
import LCACollapsible from "../LCACollapsible/LCACollapsible";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
		color: "#888",
	},
});

let loadingDocuSignResultInterval: NodeJS.Timer | null = null;
let loadingOffsetsInterval: NodeJS.Timer | null = null;

export function ClimecoCertificationProcesss() {
	const classes = useStyles();
	const { id } = useParams();
	const location = useLocation();
	const [refetchedCertification, setRefetchedCertification] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentProducts, setCurrentProducts] = useState(
		location.state?.products
			? (location.state.products as Array<IProduct>)
			: Array<IProduct>()
	);
	const [currentEstimations] = useState(Array<IEstimation>());
	const [originalEstimations, setOriginalEstimations] = useState(
		Array<IEstimation>()
	);
	const [products, setAuxProducts] = useState<Array<IProduct>>([]);
	const setProducts = (newProducts: Array<IProduct>) => {
		setAuxProducts(newProducts);
		setCurrentProducts(newProducts);
	};
	const [description, setDescription] = useState<string | undefined>(undefined);
	const [certification, setCertification] = useState<ICertification | null>(
		null
	);
	const [reloadCertification, setReloadCertification] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] =
		useState<ConfirmationDataPropTypes>({
			title: "",
			description: "",
			textfield: null,
			callback: () => {},
		});
	const [loadingDocuSignResult, setLoadingDocuSignResult] = useState(false);
	const [loadingOffsetsResult, setLoadingOffsetsResult] = useState(false);
	const { user } = useContext(UserContext);
	const [showCreateProductModal, setShowCreateProductModal] = useState(false);
	const [showMarkItAsPaidModal, setShowMarkItAsPaidModal] = useState(false);
	const [balance, setBalance] = useState<number>(0);
	const [productsCount, setProductsCount] = useState<number>(0);
	const [showAgreeModal, setShowAgreeModal] = useState(false);
	const [showNewLCAVersionModal, setShowNewLCAVersionModal] = useState(false);
	const [manualOffsets, setManualOffsets] = useState<number | undefined>(
		undefined
	);
	const [manualOffsetsPrice, setManualOffsetsPrice] = useState<number | string>(
		""
	);
	const [messagesIndex, setMessagesIndex] = useState(0);
	const [showPurchaseOffsets, setShowPurchaseOffsets] = useState(false);
	const [docuSignLoadingMessages, setDocuSignLoadingMessages] = useState([
		"Waiting for DocuSign to callback...",
		"Finalizing contracts...",
		"Fetching offsets information...",
		"Configuring available offers...",
		"Almost finishing!",
	]);
	const [offsetsLoadingMessages, setOffsetsLoadingMessages] = useState([
		"Creating invoice...",
		"Reserving offsets...",
		"Checking details...",
		"Finalizing certification...",
		"About to finish!",
	]);
	const [subscriptions, setSubscriptions] = useState<Array<ISubscription>>(
		new Array<ISubscription>()
	);
	const [LCA, setLCA] = useState<ILCA | null>(null);
	const [annualFeeToPay, setAnnualFeeToPay] = useState<number>(0);
	const [lcaFeeToPay, setLcaFeeToPay] = useState<number>(-1);
	const [lcaFeeToPayMultiplier, setLcaFeeToPayMultiplier] = useState(0);
	const [revisedLCAFeeToPay, setRevisedLCAFeeToPay] = useState<number>(-1);
	const [revisedLCAFeeToPayMultiplier, setRevisedLCAFeeToPayMultiplier] =
		useState<number>(0);
	const [rejectionsLCAFeeToPay, setRejectionsLCAFeeToPay] =
		useState<number>(-1);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const [showTitleModal, setShowTitleModal] = useState(false);
	const [showAmazonModal, setShowAmazonModal] = useState(false);

	useQuery("strings", () => stringActions.list(), {
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
		onSuccess: (res) => {
			if (res.results.length > 0) {
				setDocuSignLoadingMessages([
					(res.results[0] as any)["docusign_loading_1"],
					(res.results[0] as any)["docusign_loading_2"],
					(res.results[0] as any)["docusign_loading_3"],
					(res.results[0] as any)["docusign_loading_4"],
					(res.results[0] as any)["docusign_loading_5"],
				]);
				setOffsetsLoadingMessages([
					(res.results[0] as any)["offsets_loading_1"],
					(res.results[0] as any)["offsets_loading_2"],
					(res.results[0] as any)["offsets_loading_3"],
					(res.results[0] as any)["offsets_loading_4"],
					(res.results[0] as any)["offsets_loading_5"],
				]);
			}
		},
		onError: (error: any) => {
			console.log(error);
		},
	});

	const { refetch: refetchLink } = useQuery(
		"generate-link",
		() =>
			certificationActions.generateLink(
				true,
				parseInt(id!),
				user?.is_superuser ? certification?.company.id : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
				searchParams.delete("event");
				setSearchParams(searchParams);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchActiveProducts } = useQuery(
		"products-active",
		() =>
			productActions.list(
				user?.is_superuser ? certification?.company.id : user?.company.id,
				"",
				undefined,
				"ClimeCo certified"
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProductsCount(
					Math.max(0, res.count - (certification?.estimations.length ?? 0))
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchBalance } = useQuery(
		"balance",
		() => saleActions.getBalance([], certification?.company.id),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				/*const num =
					Math.sign((res?.estimated_amount ?? 0) - (res?.sales_amount ?? 0)) *
						Math.ceil(
							Math.abs((res?.estimated_amount ?? 0) - (res?.sales_amount ?? 0))
						) +
					Math.ceil(res?.offsets_amount ?? 0);
				setBalance(
					Math.ceil(num) === -0 ? 0 : Math.sign(num) * Math.ceil(Math.abs(num))
				);*/
				setBalance(
					(res?.purchased_offsets ?? 0) -
						(res?.sales_amount ?? 0) +
						(res?.offsets_amount ?? 0) -
						(res?.estimated_amount ?? 0)
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (loadingDocuSignResult) {
			setMessagesIndex(0);
			loadingDocuSignResultInterval = setInterval(() => {
				setMessagesIndex((prevIndex) =>
					prevIndex === docuSignLoadingMessages.length - 1 ? 0 : prevIndex + 1
				);
			}, 5000);
		} else if (loadingDocuSignResultInterval) {
			clearInterval(loadingDocuSignResultInterval);
		}

		return () => {
			if (loadingDocuSignResultInterval) {
				clearInterval(loadingDocuSignResultInterval);
			}
		};
	}, [loadingDocuSignResult, docuSignLoadingMessages.length]);

	useEffect(() => {
		if (loadingOffsetsResult) {
			setMessagesIndex(0);
			loadingOffsetsInterval = setInterval(() => {
				setMessagesIndex((prevIndex) =>
					prevIndex === offsetsLoadingMessages.length - 1 ? 0 : prevIndex + 1
				);
			}, 5000);
		} else if (loadingOffsetsInterval) {
			clearInterval(loadingOffsetsInterval);
		}

		return () => {
			if (loadingOffsetsInterval) {
				clearInterval(loadingOffsetsInterval);
			}
		};
	}, [loadingOffsetsResult, offsetsLoadingMessages.length]);

	useEffect(() => {
		if (
			!user?.is_superuser &&
			certification &&
			certification.certification_process_steps[currentStep].step.step_type ===
				"OTHER" &&
			!(certification.estimations ?? []).find(
				(estimation) => (estimation.product.marketplaces ?? []).length > 0
			)
				? true
				: false
		) {
			setShowAmazonModal(true);
		}
	}, [certification, currentStep, user]);

	const { refetch: refetchCertificationCartSubscriptions } = useQuery(
		"lca-detail",
		() => certificationActions.getCartSubscriptions(certification!.id),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res: {
				annual_fee_to_pay: number;
				lca_fee_to_pay: number;
				lca_fee_to_pay_multiplier: number;
				revised_lca_fee_to_pay: number;
				revised_lca_fee_to_pay_multiplier: number;
				rejections_lca_fee_to_pay: number;
				subscriptions: Array<ISubscription>;
			}) => {
				setSubscriptions(res.subscriptions);
				setAnnualFeeToPay(res.annual_fee_to_pay);
				setLcaFeeToPay(res.lca_fee_to_pay);
				setLcaFeeToPayMultiplier(res.lca_fee_to_pay_multiplier);
				setRevisedLCAFeeToPay(res.revised_lca_fee_to_pay);
				setRevisedLCAFeeToPayMultiplier(res.revised_lca_fee_to_pay_multiplier);
				setRejectionsLCAFeeToPay(res.rejections_lca_fee_to_pay);
			},
			onError: (error: any) => {
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading: isLCALoading, refetch: refetchLCA } = useQuery(
		"lca",
		() =>
			lcaActions.getOne(
				certification!.lca_versions![0].lca_id!,
				user?.is_superuser ? undefined : user?.company.id ?? 0
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setLCA(res);
				if (
					res.lca_versions &&
					res.lca_versions.length > 0 &&
					res.lca_versions[0].certification_process?.id &&
					id &&
					res.lca_versions[0].certification_process!.id !== parseInt(id)
				) {
					window.location.href =
						window.location.origin +
						"/climeco-certificationss/" +
						res.lca_versions![0].certification_process.id;
					//setShowNewLCAVersionModal(true);
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const {
		refetch: refetchCertification,
		isLoading: isLoadingRefecthCertification,
	} = useQuery(
		"climeco-certifications-detail",
		() =>
			certificationActions.getOne(
				true,
				parseInt(id!),
				user?.is_superuser ? undefined : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (res.is_historical) {
					navigate("/historical-data/" + id);
				} else {
					let step = 0;
					const rejectedStep = res.certification_process_steps.findIndex(
						(step) => step.status === "REJECTED"
					);

					const pendingStep = res.certification_process_steps.findIndex(
						(step) => step.status === "PENDING"
					);

					step =
						rejectedStep > -1
							? rejectedStep
							: pendingStep > -1
							? pendingStep
							: res.certification_process_steps.length - 1;

					if (
						res.certification_process_steps[step].step.step_type ===
						"PRODUCT_LIST"
					) {
						res.estimations.map((estimation) => {
							if (!estimation.amount) {
								estimation.amount = undefined;
							}
							return estimation;
						});
					}
					if (res.lca_versions) {
						res.lca_versions.sort(
							(a, b) =>
								new Date(a.created_at!).getTime() -
								new Date(b.created_at!).getTime()
						);
					}
					setCertification(res);
					setReloadCertification(true);

					const initialLCAProducts = (res: ICertification) => {
						const updatedProducts = Array<IProduct>();
						if (
							(res.lca_versions?.length ?? 0) > 0 &&
							!res.lca_versions?.find(
								(lcaVersion) => lcaVersion.products.length === 0
							)
						) {
							res.lca_versions?.forEach((lcaVersion) => {
								lcaVersion.products?.forEach((p) => {
									if (
										p.product_lca_version_emissions &&
										p.product_lca_version_emissions.length > 0
									) {
										p.product_lca_version_emission =
											p.product_lca_version_emissions[0];
										p.product_lca_version_emission.lca_version = lcaVersion;
									} else {
										p.product_lca_version_emission = {
											id: -new Date().getTime(),
											lca_version: lcaVersion,
										};
									}

									updatedProducts.push(p);
								});
							});
						} else {
							(res.lca_versions ?? []).forEach((lcaVersion) => {
								updatedProducts.push({
									id: new Date().getTime(),
									status: "PENDING" as "PENDING",
									name: "",
									description: "",
									asin: "",
									upc: "",
									lca_documents: [
										{
											id: new Date().getTime(),
											lca_document: undefined,
											certification_date: "",
											lca_provider: "",
											emissions: null,
										},
									],
									product_lca_version_emission: {
										id: -new Date().getTime(),
										lca_version: lcaVersion,
									},
								});
							});
						}

						return updatedProducts;
					};

					const certificationProducts = initialLCAProducts(res);
					//setCurrentProducts(certificationProducts);
					setProducts(certificationProducts);
					setRefetchedCertification(true);
				}
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
				setRefetchedCertification(true);
			},
		}
	);

	useEffect(() => {
		if (certification && refetchedCertification) {
			refetchActiveProducts();
			refetchBalance();
			refetchCertificationCartSubscriptions();
			if ((certification.lca_versions ?? []).length > 0) {
				refetchLCA();
			}
			setRefetchedCertification(false);
		}
	}, [
		certification,
		refetchCertificationCartSubscriptions,
		refetchLCA,
		refetchActiveProducts,
		refetchBalance,
		refetchedCertification,
	]);

	const getEstimations = () => {
		if (currentStep === 0) {
			return currentProducts.map((product, index) => {
				return {
					id: index,
					value: 0,
					//emissions: 0,
					product: product,
				};
			});
		} else {
			return currentEstimations;
		}
	};

	const {
		isLoading: isLoadingCreateCertification,
		mutate: createCertification,
	} = useMutation(
		"create-certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: true,
				name: "New",
				companyId: user!.company.id,
				isHistorical: false,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingCreateEstimation,
		mutate: createEstimationMutate,
	} = useMutation(
		"estimations",
		(estimation: IEstimation) =>
			estimationsActions.create({
				amount: estimation.amount,
				//emissions: estimation.emissions,
				product: estimation.product.id,
				certificationProcess:
					estimation.certification_process?.id ?? certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingDeleteEstimation,
		mutate: deleteEstimationMutate,
	} = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingUpdateEstimation,
		mutate: updateEstimationMutate,
	} = useMutation(
		"estimations-update",
		(estimation: IEstimation) =>
			estimationsActions.update({
				id: estimation.id,
				amount: estimation.amount,
				//emissions: estimation.emissions,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading, mutate } = useMutation(
		"certification-detail",
		() =>
			certificationActions.update({
				isClimeCo: true,
				estimations: getEstimations(),
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
				id: certification?.id ?? -1,
				description: description,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingMarkItAsPaid, mutate: mutateMarkItAsPaid } =
		useMutation(
			"mark-it-as-paid",
			() =>
				certificationProcessStepActions.markItAsPaid({
					id: certification!.certification_process_steps[currentStep].id,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					refetchCertification();
					setShowMarkItAsPaidModal(false);
					enqueueSnackbar("Certification marked as paid.", {
						variant: "success",
					});
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const { isLoading: isLoadingMutateAddOffsets, mutate: mutateAddOffsets } =
		useMutation(
			"certification-add-offsets",
			() =>
				certificationActions.addOffsets({
					isClimeCo: true,
					id: certification?.id ?? -1,
					companyId: certification?.company.id,
					quantity: manualOffsets,
					amount:
						typeof manualOffsetsPrice === "string"
							? parseFloat(manualOffsets!.toString()) * 12.5
							: parseFloat(manualOffsets!.toString()) * manualOffsetsPrice,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					refetchCertification();
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const { isLoading: isLoadingApproval, mutate: mutateApproval } = useMutation(
		"certification-detail-approval",
		() =>
			certificationActions.toggleApproval({
				isClimeCo: true,
				id: certification?.id ?? -1,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
				setShowAgreeModal(false);
				enqueueSnackbar(
					"Certification requested. Now, waiting for Admin's approval.",
					{ variant: "success" }
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchCertificationSteps } = useQuery(
		"fetch-steps",
		() =>
			certificationActions.getSteps("CLIMECO_INTERNAL_CERTIFICATION", false),
		{
			enabled: false,
			retry: false,
			onSuccess: (res: IStep[]) => {
				const certificationSteps = res.map((step) => {
					return {
						id: new Date().getTime(),
						status: "PENDING",
						payment_status: null,
						reject_reason: "",
						step,
					} as ICertificationSteps;
				});
				setCertification({
					id: -1,
					name: "ClimeCo Certification - " + new Date().getTime(),
					certification_process_steps: certificationSteps,
					company: user!.company,
					estimations: Array<IEstimation>(),
					is_historical: false,
					status: "COMPLETED",
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (id && user) {
			if (id === "create") {
				refetchCertificationSteps();
			} else {
				refetchCertification();
			}
		}
	}, [
		id,
		refetchCertification,
		user,
		enqueueSnackbar,
		refetchCertificationSteps,
	]);

	useEffect(() => {
		if (user && certification && currentStep) {
			if (searchParams.get("event") === "ttl_expired") {
				if (
					(!user?.is_superuser &&
						certification.certification_process_steps[currentStep].step
							.step_type === "REDIRECTION") ||
					(user?.is_superuser &&
						certification.certification_process_steps[currentStep].step
							.step_type === "WAITING")
				) {
					enqueueSnackbar("Link has expired. Generating a new one...", {
						variant: "info",
					});
					refetchLink();
				} else {
					searchParams.delete("event");
					setSearchParams(searchParams);
				}
			} else if (searchParams.get("event") !== null) {
				setLoadingDocuSignResult(true);
				enqueueSnackbar(
					"Signing successful. Reloading... (it might take a minute)",
					{
						variant: "success",
						autoHideDuration: 25000,
					}
				);
				searchParams.delete("event");
				setSearchParams(searchParams);
				setTimeout(() => {
					refetchCertification();
					setLoadingDocuSignResult(false);
				}, 25000);
			} /*else if (
				user?.is_superuser &&
				certification.certification_process_steps[currentStep].step
					.step_type === "WAITING" &&
				!certification?.climeco_contract_url_admin
			) {
				enqueueSnackbar("Generating a DocuSign link...", { variant: "info" });
				refetchLink();
			}*/
		}
	}, [
		searchParams,
		certification,
		refetchLink,
		enqueueSnackbar,
		refetchCertification,
		setSearchParams,
		currentStep,
		user,
	]);

	useEffect(() => {
		if (
			reloadCertification &&
			certification &&
			certification.certification_process_steps
		) {
			const rejectedStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "REJECTED"
			);

			const pendingStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "PENDING"
			);

			if (
				currentStep &&
				currentStep < certification.certification_process_steps.length &&
				certification.certification_process_steps[currentStep].step
					.step_type === "CHOOSE_PRODUCTS"
			) {
				setCurrentStep(currentStep + 1);
			} else {
				let auxCurrentStep =
					rejectedStep > -1
						? rejectedStep
						: pendingStep > -1
						? pendingStep
						: certification.certification_process_steps.length - 1;

				if (
					auxCurrentStep &&
					auxCurrentStep < certification.certification_process_steps.length &&
					certification.certification_process_steps[auxCurrentStep].step
						.step_type === "CERTIFICATION_SUMMARY" &&
					certification.estimations.find((e) => !e.amount)
				) {
					setCurrentStep(auxCurrentStep - 1);
				} else {
					setCurrentStep(auxCurrentStep);
				}
			}
			setReloadCertification(false);
		}
	}, [certification, reloadCertification, currentStep]);

	useEffect(() => {
		if (
			!loadingDocuSignResult &&
			certification?.certification_process_steps[currentStep].step.step_type ===
				"PURCHASE_OFFSETS" &&
			certification?.certification_process_steps[currentStep].payment_status ===
				"NOT_STARTED" &&
			(!certification?.manual_approval || !user?.is_superuser) &&
			lcaFeeToPay > -1 &&
			!user?.is_superuser
		) {
			setShowPurchaseOffsets(true);
		}
	}, [loadingDocuSignResult, certification, currentStep, user, lcaFeeToPay]);

	const getTitle = () => {
		if (!certification?.estimations || certification.estimations.length === 0) {
			return "ClimeCo Certification";
		}

		if (certification.estimations.length <= 2) {
			return (
				"ClimeCo Certification - " +
				certification.estimations
					.map(
						(est) =>
							`${est.product?.name} - ${
								est.product?.asin || est.product?.upc || "--"
							}`
					)
					.join(", ")
			);
		}

		const firstTwoProducts = certification.estimations
			.slice(0, 2)
			.map(
				(est) =>
					`${est.product?.name} - ${
						est.product?.asin || est.product?.upc || "--"
					}`
			)
			.join(", ");
		const remainingCount = certification.estimations.length - 2;

		return `ClimeCo Certification - ${firstTwoProducts} and ${remainingCount} more products`;
	};

	const { isLoading: isLoadingCreateProduct, mutate: mutateCreateProduct } =
		useMutation(
			"create-product",
			({ product }: { product: IProduct }) =>
				productActions.create({
					name: product?.name,
					description: product?.description,
					companyId: user?.is_superuser ? product.company_id : user?.company.id,
					external_id: product?.external_id,
					sku: product?.sku,
					asin: product?.asin,
					ean: product?.ean,
					isbn: product?.isbn,
					upc: product?.upc,
					gtin: product?.gtin,
					websites: product?.websites ?? [""],
					status: user?.is_superuser ? product?.status : undefined,
					brand: product?.brand,
				}),
			{
				retry: false,
			}
		);

	const { isLoading: isLoadingUpdateProduct, mutate: mutateUpdateProduct } =
		useMutation(
			"update-product",
			({ product }: { product: IProduct }) =>
				productActions.update({
					id: product.id,
					name: product?.name,
					description: product?.description,
					companyId: user?.is_superuser ? product.company_id : user?.company.id,
					external_id: product?.external_id,
					sku: product?.sku,
					asin: product?.asin,
					ean: product?.ean,
					isbn: product?.isbn,
					upc: product?.upc,
					gtin: product?.gtin,
					websites: product?.websites ?? [""],
					status: user?.is_superuser ? product?.status : undefined,
					brand: product?.brand,
				}),
			{
				retry: false,
			}
		);

	const {
		isLoading: isLoadingCreateProductLCAVersionEmission,
		mutate: mutateCreateProductLCAVersionEmission,
	} = useMutation(
		"create-product-lca-version-emission",
		({
			productLcaVersionEmission,
			lcaVersionId,
			productId,
			lcaId,
			companyId,
		}: {
			productLcaVersionEmission: number;
			lcaVersionId: number;
			productId: number;
			lcaId: number;
			companyId?: number;
		}) =>
			productLCAVersionEmissionActions.create(
				productLcaVersionEmission,
				lcaVersionId,
				productId,
				lcaId,
				companyId
			),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingUpdateProductLCAVersionEmission,
		mutate: mutateUpdateProductLCAVersionEmission,
	} = useMutation(
		"update-product-lca-version-emission",
		({
			id,
			productLcaVersionEmission,
			lcaVersionId,
			lcaId,
			companyId,
		}: {
			id: number;
			productLcaVersionEmission: number;
			lcaVersionId: number;
			lcaId: number;
			companyId?: number;
		}) =>
			productLCAVersionEmissionActions.update(
				id,
				productLcaVersionEmission,
				lcaVersionId,
				lcaId,
				companyId
			),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingDeleteProductLCAVersionEmission,
		mutate: mutateDeleteProductLCAVersionEmission,
	} = useMutation(
		"delete-product-lca-version-emission",
		({
			id,
			lcaVersionId,
			lcaId,
			companyId,
		}: {
			id: number;
			lcaVersionId: number;
			lcaId: number;
			companyId?: number;
		}) =>
			productLCAVersionEmissionActions.delete(
				id,
				lcaVersionId,
				lcaId,
				companyId
			),
		{
			retry: false,
		}
	);

	const handleKitDownload = () => {
		const fileUrl =
			"/ClimeCo-Certified-Product-Marketing-Kit-and-Brand-Guidelines.zip";

		const link = document.createElement("a");
		link.href = fileUrl;
		link.download =
			"ClimeCo-Certified-Product-Marketing-Kit-and-Brand-Guidelines.zip";

		link.click();
	};

	const isValidASIN = (asin: string): boolean => {
		const asinRegex = /^(B[\dA-Z]{9}|\d{9}[\dX])$/;
		return asinRegex.test(asin);
	};

	return (
		<Box>
			{/*<Typography
				variant="body2"
				fontSize={10}
				marginBottom={2}
				sx={{ cursor: "pointer" }}
				onClick={() => {
					navigate("/climeco-certifications");
				}}
			>
				{"BACK"}
			</Typography>*/}

			{currentStep ===
				(certification?.certification_process_steps.length ?? 0) - 1 && (
				<Box
					sx={{
						position: "absolute",
						top: "20%",
						left: "50%",
						transform: "translate(-20%, -50%)",
					}}
				>
					<ConfettiExplosion />
				</Box>
			)}

			<Heading
				title={getTitle()}
				showSearch={false}
				onTitleClick={() =>
					certification && certification.estimations.length > 2
						? setShowTitleModal(true)
						: null
				}
			/>

			{!certification ||
			isLoading ||
			isLoadingRefecthCertification ||
			isLCALoading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignContent: "center",
						justifyContent: "center",
						width: "100%",
						minHeight: 200,
						padding: 4,
					}}
				>
					<CircularProgress size={24} />
				</Box>
			) : (
				<>
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "row",
								sm: "column",
							},
							gap: !matches ? 4 : 0,
						}}
					>
						{certification &&
							certification?.certification_process_steps &&
							certification?.certification_process_steps.length > 0 && (
								<>
									<Stepper
										orientation={!matches ? "vertical" : "horizontal"}
										currentStep={currentStep}
										lastStep={
											certification?.certification_process_steps.length - 1
										}
										steps={certification?.certification_process_steps.map(
											(step) => step.step.step_title
										)}
									/>

									{certification?.certification_process_steps[currentStep].step
										.step_type === "CERTIFICATION_SUMMARY" &&
									!user?.is_superuser ? (
										(certification?.lca_versions ?? []).find(
											(lcaVersion) => lcaVersion.rejected_date
										) === undefined && certification?.requested_approval ? (
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													marginTop: 4,
													width: "100%",
												}}
											>
												<Alert severity="info">
													Certification requested. Now, waiting for Admin's
													approval.
												</Alert>
											</Box>
										) : null
									) : null}

									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											width: {
												xs: "calc(100% - 82px)",
												sm: "100%",
											},
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Box>
												<Typography
													variant="h5"
													sx={{
														fontWeight: 700,
														marginTop: !matches ? 0 : 4,
													}}
												>
													{
														certification?.certification_process_steps[
															currentStep
														].step.name
													}
												</Typography>
												<Typography
													sx={{ marginTop: 1, marginBottom: 4 }}
													variant="subtitle1"
												>
													{ReactHtmlParser(
														certification?.certification_process_steps[
															currentStep
														].step.description ?? ""
													)}
												</Typography>
											</Box>
											<Box
												sx={{ display: "flex", flexDirection: "row", gap: 1 }}
											>
												{(certification?.certification_process_steps[
													currentStep
												].step.step_type === "INFORMATION" ||
													certification?.certification_process_steps[
														currentStep
													].step.step_type === "PRODUCT_LIST") /*||
													certification?.certification_process_steps[
														currentStep
													].step.step_type === "CHOOSE_PRODUCTS"*/ && (
													<Button
														variant="outlined"
														onClick={() => {
															if (
																false &&
																certification?.certification_process_steps[
																	currentStep
																].step.step_type === "CHOOSE_PRODUCTS"
															) {
																navigate(
																	"/lcas/" + certification!.lca_version?.lca_id
																);
															} else {
																setCurrentStep(currentStep - 1);
																setOriginalEstimations(
																	certification.estimations
																);
																const initialLCAProducts = (
																	res: ICertification
																) => {
																	const updatedProducts = Array<IProduct>();
																	(res.lca_versions ?? []).forEach(
																		(lcaVersion) => {
																			updatedProducts.push({
																				id: new Date().getTime(),
																				status: "PENDING" as "PENDING",
																				name: "",
																				description: "",
																				asin: "",
																				upc: "",
																				lca_documents: [
																					{
																						id: new Date().getTime(),
																						lca_document: undefined,
																						certification_date: "",
																						lca_provider: "",
																						emissions: null,
																					},
																				],
																				product_lca_version_emission: {
																					id: -new Date().getTime(),
																					lca_version: lcaVersion,
																				},
																			});
																		}
																	);
																	return updatedProducts;
																};
																setCurrentProducts(
																	/*certification.lca_version?.products &&
																		certification.lca_version?.products.length >
																			0
																		? certification.lca_version?.products.map(
																				(p) => {
																					if (
																						p.product_lca_version_emissions &&
																						p.product_lca_version_emissions
																							.length > 0
																					) {
																						p.product_lca_version_emission =
																							p.product_lca_version_emissions[0];
																					} else {
																						p.product_lca_version_emission = {
																							id: -new Date().getTime(),
																						};
																					}
																					return p;
																				}
																		  )
																		: */ initialLCAProducts(certification)
																);
															}
														}}
														sx={{ marginRight: 0 }}
													>
														BACK
													</Button>
												)}
												{!loadingDocuSignResult &&
													certification?.certification_process_steps[
														currentStep
													].step.button_title && (
														<Button
															variant="contained"
															sx={{
																minHeight: 34,
																display:
																	(certification?.certification_process_steps[
																		currentStep
																	].step.step_type === "WAITING" &&
																		!user?.is_superuser) ||
																	(certification?.certification_process_steps[
																		currentStep
																	].step.step_type === "REDIRECTION" &&
																		user?.is_superuser) ||
																	(certification?.certification_process_steps[
																		currentStep
																	].step.step_type === "PURCHASE_OFFSETS" &&
																		certification?.manual_approval &&
																		user?.is_superuser)
																		? "none"
																		: "block",
															}}
															disabled={(() => {
																switch (
																	certification?.certification_process_steps[
																		currentStep
																	].step.step_type
																) {
																	case "CHOOSE_PRODUCTS": {
																		return (
																			currentProducts.length === 0 ||
																			!!currentProducts.find(
																				(currentProduct) =>
																					!currentProduct.name ||
																					/*!currentProduct.upc ||*/
																					(currentProduct.upc &&
																						currentProduct.upc.length !== 12) ||
																					!currentProduct.brand ||
																					!currentProduct
																						.product_lca_version_emission
																						?.emissions
																			) ||
																			currentProducts
																				.filter((r) => r.asin)
																				.map((r) => r.asin).length !==
																				new Set(
																					currentProducts
																						.filter((r) => r.asin)
																						.map((r) => r.asin)
																				).size ||
																			currentProducts.filter(
																				(r) => r.asin && !isValidASIN(r.asin)
																			).length > 0
																		);
																	}
																	case "INFORMATION": {
																		return !description;
																	}
																	case "PRODUCT_LIST": {
																		return certification.estimations.find(
																			(estimation) =>
																				estimation.amount === undefined ||
																				estimation.amount === 0
																		)
																			? true
																			: false;
																	}
																	case "CERTIFICATION_SUMMARY": {
																		return false;
																	}
																	case "REDIRECTION": {
																		return searchParams.get("event") ||
																			(!user?.is_superuser &&
																				!certification?.climeco_contract_url_customer)
																			? true
																			: false;
																	}
																	case "WAITING": {
																		return (user?.is_superuser ?? false) &&
																			!certification?.climeco_contract_url_admin
																			? true
																			: false;
																	}

																	default: {
																		return false;
																	}
																}
															})()}
															onClick={async () => {
																switch (
																	certification?.certification_process_steps[
																		currentStep
																	].step.step_type
																) {
																	case "CHOOSE_PRODUCTS": {
																		//TO DO: CHANGE IT HERE
																		// for new products
																		// 		create them
																		// 		create the product lca version emissions
																		// for existing products
																		// 		update values
																		// 		update the product lca version emissions
																		// for missing products
																		// 		delete the product lca version emissions
																		if (
																			(certification.lca_versions ?? []).reduce(
																				(prev, next) =>
																					prev + (next.products ?? []).length,
																				0
																			) > 5
																		) {
																			enqueueSnackbar(
																				"Please, wait. Uploading several products at the same time might take up to a few minutes.",
																				{
																					variant: "info",
																				}
																			);
																		}
																		for (const newProduct of currentProducts) {
																			let found = false;
																			for (const lcaVersion of certification.lca_versions ??
																				[]) {
																				for (const product of lcaVersion.products ??
																					[]) {
																					if (newProduct.id === product.id) {
																						found = true;
																					}
																				}
																			}
																			const lcaVersion =
																				newProduct.product_lca_version_emission
																					?.lca_version ?? null;
																			if (!found) {
																				const createdProduct: IProduct | null =
																					await new Promise(
																						(resolve, reject) => {
																							mutateCreateProduct(
																								{ product: newProduct },
																								{
																									onSuccess: (res) => {
																										resolve(res as IProduct);
																									},
																									onError: (error) => {
																										console.log(error);
																										enqueueSnackbar(
																											"Something went wrong.",
																											{
																												variant: "error",
																											}
																										);
																										reject(null);
																									},
																								}
																							);
																						}
																					);
																				if (createdProduct) {
																					setCurrentProducts(
																						(auxCurrentProducts) => {
																							const auxProduct =
																								auxCurrentProducts.find(
																									(cp) =>
																										cp.id === newProduct.id
																								);
																							if (auxProduct) {
																								auxProduct!.id = (
																									createdProduct as IProduct
																								).id;
																							}
																							return auxCurrentProducts;
																						}
																					);
																					await new Promise(
																						(resolve, reject) => {
																							mutateCreateProductLCAVersionEmission(
																								{
																									productLcaVersionEmission:
																										newProduct.product_lca_version_emission!
																											.emissions ?? 0,
																									lcaVersionId:
																										lcaVersion?.id ?? -1,
																									productId: (
																										createdProduct as IProduct
																									).id,
																									lcaId:
																										lcaVersion?.lca_id ?? -1,
																									companyId:
																										certification.company.id,
																								},
																								{
																									onSuccess: (res) => {
																										resolve(res);
																									},
																									onError: (error) => {
																										console.log(error);
																										enqueueSnackbar(
																											"Something went wrong.",
																											{
																												variant: "error",
																											}
																										);
																										reject();
																									},
																								}
																							);
																						}
																					);
																				}
																			} else {
																				await new Promise((resolve, reject) => {
																					mutateUpdateProduct(
																						{ product: newProduct },
																						{
																							onSuccess: (res) => {
																								resolve(res as IProduct);
																							},
																							onError: (error) => {
																								console.log(error);
																								enqueueSnackbar(
																									"Something went wrong.",
																									{
																										variant: "error",
																									}
																								);
																								reject(null);
																							},
																						}
																					);
																				});

																				await new Promise((resolve, reject) => {
																					mutateUpdateProductLCAVersionEmission(
																						{
																							id: newProduct.product_lca_version_emission!
																								.id,
																							productLcaVersionEmission:
																								newProduct.product_lca_version_emission!
																									.emissions ?? 0,
																							lcaVersionId:
																								lcaVersion?.id ?? -1,
																							lcaId: lcaVersion?.lca_id ?? -1,
																							companyId:
																								certification.company.id,
																						},
																						{
																							onSuccess: (res) => {
																								resolve(res);
																							},
																							onError: (error) => {
																								console.log(error);
																								enqueueSnackbar(
																									"Something went wrong.",
																									{
																										variant: "error",
																									}
																								);
																								reject();
																							},
																						}
																					);
																				});
																			}
																		}

																		for (const lcaVersion of certification.lca_versions ??
																			[]) {
																			for (const product of lcaVersion?.products ??
																				[]) {
																				let found = false;
																				for (const newProduct of currentProducts) {
																					if (newProduct.id === product.id) {
																						found = true;
																					}
																				}
																				if (!found) {
																					// TO DO; CHECK HERE WHY product_lca_version_emission is empty
																					await new Promise(
																						(resolve, reject) => {
																							console.log(product);
																							console.log(certification);
																							mutateDeleteProductLCAVersionEmission(
																								{
																									id: product.product_lca_version_emission!
																										.id,
																									lcaVersionId:
																										product.product_lca_version_emission!
																											.lca_version!.id,
																									/*certification.lca_version!
																											.id,*/
																									lcaId:
																										product.product_lca_version_emission!
																											.lca_version!.lca_id!,
																									companyId:
																										certification.company.id,
																								},
																								{
																									onSuccess: (res) => {
																										resolve(res);
																									},
																									onError: (error) => {
																										console.log(error);
																										enqueueSnackbar(
																											"Something went wrong.",
																											{
																												variant: "error",
																											}
																										);
																										reject();
																									},
																								}
																							);
																						}
																					);
																				}
																			}
																		}

																		// END TO DO

																		let index = 0;

																		// TO DO: recheck it here
																		// Check here what to really delete

																		const createEstimation = async (
																			estimationId: number,
																			amount: number,
																			//emissions: number,
																			product: IProduct,
																			index: number,
																			certificationProcess?: ICertification
																		) => {
																			const onSuccess = async () => {
																				index += 1;
																				if (index < currentProducts.length) {
																					createEstimation(
																						index,
																						amount,
																						//0,
																						currentProducts[index],
																						index,
																						certificationProcess
																					);
																				} else {
																					// delete here estimations
																					const auxOriginalEstimations =
																						originalEstimations.filter(
																							(e) =>
																								currentProducts.findIndex(
																									(p) => p.id === e.product.id
																								) === -1
																						);
																					for (const estimation of auxOriginalEstimations) {
																						await new Promise(
																							(resolve, reject) => {
																								deleteEstimationMutate(
																									estimation,
																									{
																										onSuccess: () => {
																											resolve(true);
																										},
																										onError: () => {
																											resolve(true);
																											//reject();
																										},
																									}
																								);
																							}
																						);
																					}
																					if (
																						certificationProcess &&
																						id === "create"
																					) {
																						navigate(
																							"/climeco-certificationss/" +
																								certificationProcess!.id
																						);
																					} else {
																						refetchCertification();
																					}
																				}
																			};

																			let estimationAlreadyExists =
																				certification.estimations.find(
																					(estimation) =>
																						currentProducts[index].id ===
																						estimation.product.id
																				) !== undefined;

																			if (estimationAlreadyExists) {
																				onSuccess();
																			} else {
																				createEstimationMutate(
																					{
																						id: estimationId,
																						amount: amount,
																						//emissions: emissions,
																						product: product,
																						certification_process:
																							certificationProcess,
																					},
																					{
																						onSuccess: () => {
																							onSuccess();
																						},
																						onError: (error) => {
																							console.log(error);
																							enqueueSnackbar(
																								"Something went wrong.",
																								{
																									variant: "error",
																								}
																							);
																						},
																					}
																				);
																			}
																		};

																		if (certification.id === -1) {
																			createCertification(null, {
																				onSuccess: (res) => {
																					createEstimation(
																						index,
																						currentProducts[index]?.estimations
																							? currentProducts[index]
																									?.estimations![0].amount ?? 0
																							: 0,
																						//0,
																						currentProducts[index],
																						index,
																						res
																					);
																				},
																				onError: (error) => {
																					console.log(error);
																					enqueueSnackbar(
																						"Something went wrong.",
																						{
																							variant: "error",
																						}
																					);
																				},
																			});
																		} else {
																			createEstimation(
																				index,
																				currentProducts[index]?.estimations
																					? currentProducts[index]
																							?.estimations![0].amount ?? 0
																					: 0,
																				//0,
																				currentProducts[index],
																				index
																			);
																		}

																		break;
																	}
																	case "INFORMATION": {
																		mutate();
																		break;
																	}
																	case "REDIRECTION": {
																		if (
																			!user?.is_superuser &&
																			certification?.climeco_contract_url_customer
																		) {
																			window.location.href =
																				certification?.climeco_contract_url_customer;
																		}
																		break;
																	}
																	case "WAITING": {
																		if (
																			(user?.is_superuser ?? false) &&
																			certification?.climeco_contract_url_admin
																		) {
																			window.location.href =
																				certification?.climeco_contract_url_admin;
																		}
																		break;
																	}
																	case "PRODUCT_LIST": {
																		let index = 0;

																		const updateEstimation = (
																			id: number,
																			amount: number,
																			//emissions: number,
																			product: IProduct,
																			index: number
																		) => {
																			updateEstimationMutate(
																				{
																					id: id,
																					amount: amount,
																					//emissions: emissions,
																					product: product,
																				},
																				{
																					onSuccess: () => {
																						index += 1;
																						if (
																							index <
																							certification.estimations.length
																						) {
																							updateEstimation(
																								certification.estimations[index]
																									.id,
																								certification.estimations[index]
																									.amount!,
																								/*certification.estimations[index]
																						.emissions!,*/
																								certification.estimations[index]
																									.product,
																								index
																							);
																						} else {
																							refetchCertification();
																						}
																						setShowConfirmation(false);
																					},
																					onError: (error) => {
																						console.log(error);
																						enqueueSnackbar(
																							"Something went wrong.",
																							{
																								variant: "error",
																							}
																						);
																						setShowConfirmation(false);
																					},
																				}
																			);
																		};

																		/*setShowConfirmation(true);
															setConfirmationData({
																title:
																	"Do you want to proceed with the Climeco Certification?",
																description:
																	"Have in mind, once you continue you will not be able to modify it",
																textfield: null,
																callback: () => {
																	updateEstimation(
																		certification.estimations[index].id,
																		certification.estimations[index].amount!,
																		//certification.estimations[index].emissions!,
																		certification.estimations[index].product,
																		index
																	);
																},
															});*/

																		updateEstimation(
																			certification.estimations[index].id,
																			certification.estimations[index].amount!,
																			//certification.estimations[index].emissions!,
																			certification.estimations[index].product,
																			index
																		);

																		break;
																	}
																	case "OTHER": {
																		navigate("/products");
																		break;
																	}
																	default: {
																		if (
																			currentStep + 1 <
																			certification.certification_process_steps
																				.length
																		) {
																			setCurrentStep(currentStep + 1);
																		}
																	}
																}
															}}
														>
															{isLoadingCreateEstimation ||
															isLoadingUpdateEstimation ||
															isLoading ||
															isLoadingDeleteEstimation ||
															isLoadingCreateCertification ||
															isLoadingCreateProduct ||
															isLoadingUpdateProduct ||
															isLoadingCreateProductLCAVersionEmission ||
															isLoadingUpdateProductLCAVersionEmission ||
															isLoadingDeleteProductLCAVersionEmission ? (
																<CircularProgress
																	size={12}
																	sx={{ color: "white" }}
																/>
															) : (
																certification?.certification_process_steps[
																	currentStep
																].step.button_title
															)}
														</Button>
													)}
											</Box>
										</Box>
										{loadingDocuSignResult && (
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													padding: 4,
													flexDirection: "column",
												}}
											>
												<CircularProgress size={24} />
												<motion.div
													key={messagesIndex}
													initial={{ opacity: 0, x: -100 }}
													animate={{ opacity: 1, x: 0 }}
													exit={{ opacity: 0, x: 100 }}
												>
													<Typography
														variant="body2"
														sx={{
															marginTop: 2,
															maxWidth: 400,
															textAlign: "center",
														}}
													>
														{docuSignLoadingMessages[messagesIndex]}
													</Typography>
												</motion.div>
											</Box>
										)}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												.step.step_type === "CHOOSE_PRODUCTS" && (
												<>
													{/* TO DO: Collapsible component */}
													{(certification?.lca_versions ?? []).map(
														(lcaVersion, index) => (
															<Box sx={{ marginBottom: 4 }} key={lcaVersion.id}>
																<LCACollapsible
																	title={
																		<Typography
																			variant="h6"
																			sx={{
																				fontWeight: "bold",
																				color: "#333",
																				fontSize: 14,
																			}}
																		>
																			LCA:{" "}
																			{lcaVersion.title ??
																				lcaVersion.lca?.title ??
																				index}
																		</Typography>
																	}
																	defaultExpanded
																>
																	<Typography
																		variant="subtitle1"
																		sx={{
																			fontWeight: "bold",
																			color: "#444",
																			marginTop: "12px",
																			fontSize: 14,
																		}}
																	>
																		PRODUCTS
																	</Typography>

																	<Divider sx={{ marginBottom: "16px" }} />
																	<BulkProductsTable
																		lcaVersion={lcaVersion}
																		newIntegration
																		initialProducts={products.filter(
																			(p) =>
																				p.product_lca_version_emission
																					?.lca_version?.id === lcaVersion.id
																		)}
																		setInitialProducts={(
																			newProducts: Array<IProduct>
																		) => {
																			let updatedProducts = products.filter(
																				(p: IProduct) =>
																					p.product_lca_version_emission
																						?.lca_version?.id !== lcaVersion.id
																			);
																			setProducts([
																				...updatedProducts,
																				...newProducts,
																			]);
																		}}
																	/>
																</LCACollapsible>
															</Box>
														)
													)}
												</>
											)}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												.step.step_type === "INFORMATION" && (
												<Box sx={{ flex: 1 }}>
													<TextField
														sx={{ width: "100%" }}
														autoFocus
														multiline
														rows={8}
														placeholder="Write here"
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/>
												</Box>
											)}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												.step.step_type === "PRODUCT_LIST" && (
												<Box>
													{(certification?.lca_versions ?? []).map(
														(lcaVersion, index) => (
															<Box sx={{ marginBottom: 4 }} key={lcaVersion.id}>
																<LCACollapsible
																	title={
																		<Typography
																			variant="h6"
																			sx={{
																				fontWeight: "bold",
																				color: "#333",
																				fontSize: 14,
																			}}
																		>
																			LCA:{" "}
																			{lcaVersion.title ??
																				lcaVersion.lca?.title ??
																				index}
																		</Typography>
																	}
																	defaultExpanded
																>
																	<Typography
																		variant="subtitle1"
																		sx={{
																			fontWeight: "bold",
																			color: "#444",
																			marginTop: "12px",
																			fontSize: 14,
																		}}
																	>
																		ESTIMATIONS
																	</Typography>

																	<Divider sx={{ marginBottom: "16px" }} />
																	{certification?.estimations &&
																		certification?.estimations
																			.filter(
																				(estimation) =>
																					estimation
																						.product_lca_version_emission
																						?.lca_version?.id === lcaVersion.id
																			)
																			.sort((a, b) => {
																				const dateA = new Date(
																					a.created_at ?? ""
																				).getTime();
																				const dateB = new Date(
																					b.created_at ?? ""
																				).getTime();

																				return dateA - dateB;
																			})
																			.map((estimation, index) => {
																				return (
																					<Box
																						key={estimation.id}
																						sx={{
																							display: "flex",
																							flexDirection: "column",
																						}}
																					>
																						<Box
																							sx={{
																								display: "flex",
																								alignItems: "center",
																								flexDirection: {
																									xs: "column",
																									sm: "column",
																									md: "row",
																								},
																								gap: 2,
																								flex: 1,
																							}}
																						>
																							<Typography
																								flex={1}
																								sx={{
																									marginTop: "20px",
																									flex: 1,
																									maxWidth: 400,
																								}}
																							>
																								{estimation.product.asin
																									? estimation.product.name +
																									  " - " +
																									  estimation.product.asin
																									: estimation.product.upc
																									? estimation.product.name +
																									  " - " +
																									  estimation.product.upc
																									: estimation.product.name}
																							</Typography>
																							<Box
																								sx={{
																									display: "flex",
																									flexDirection: "column",
																									width: "100%",
																									flex: 1,
																								}}
																							>
																								<span
																									className={classes.inputLabel}
																								>
																									Emissions
																								</span>
																								<NumericFormat
																									value={
																										estimation
																											.product_lca_version_emission
																											?.emissions ?? 0
																									}
																									name={"emissions"}
																									placeholder="kg of CO2e"
																									customInput={TextField}
																									style={{
																										background: "white",
																									}}
																									suffix={" kg of CO2e"}
																									type="text"
																									allowNegative={false}
																									disabled={true}
																									thousandSeparator={","}
																									onValueChange={({
																										value: v,
																									}) => {
																										/*const auxCertification = {
																				...certification,
																			};
																			auxCertification.estimations[
																				index
																			].emissions = parseInt(v);
																			setCertification(auxCertification);*/
																									}}
																								/>
																							</Box>
																							<Box
																								sx={{
																									display: "flex",
																									flexDirection: "column",
																									width: "100%",
																									flex: 1,
																								}}
																							>
																								<span
																									className={classes.inputLabel}
																								>
																									Estimated Total Units Sold for
																									12 Months
																								</span>
																								<NumericFormat
																									value={estimation.amount}
																									style={{
																										border:
																											"1px solid rgb(3, 176, 147)",
																										borderRadius: 4,
																									}}
																									name={"amount"}
																									//placeholder={`Estimate Total Units Sold The Rest of ${new Date().getUTCFullYear()}`}
																									customInput={TextField}
																									type="text"
																									allowNegative={false}
																									decimalScale={0}
																									autoFocus={index === 0}
																									thousandSeparator={","}
																									onValueChange={({
																										value: v,
																									}) => {
																										const auxCertification = {
																											...certification,
																										};
																										let estimationIndex =
																											auxCertification.estimations.findIndex(
																												(e) =>
																													e.id === estimation.id
																											);
																										if (estimationIndex > -1) {
																											auxCertification.estimations[
																												estimationIndex
																											].amount = v
																												? parseInt(v)
																												: undefined;
																											setCertification(
																												auxCertification
																											);
																										}
																									}}
																								/>
																							</Box>
																							<Box
																								sx={{
																									display: "flex",
																									flexDirection: "column",
																									textAlign: "right",
																									paddingRight: "20px",
																									"& input": {
																										width: "60px",
																										textAlign: "right",
																									},
																								}}
																							>
																								<span
																									className={classes.inputLabel}
																								>
																									Offsets (MT)
																								</span>
																								<NumericFormat
																									value={Intl.NumberFormat(
																										"en",
																										{
																											maximumFractionDigits: 0,
																											minimumFractionDigits: 0,
																										}
																									).format(
																										Math.ceil(
																											(estimation
																												.product_lca_version_emission
																												?.emissions ?? 0) *
																												(estimation.amount ??
																													0) *
																												0.001 ?? 0
																										)
																									)}
																									name={"offsets"}
																									placeholder="0"
																									customInput={TextField}
																									style={{
																										background: "white",
																									}}
																									type="text"
																									disabled={true}
																									thousandSeparator={","}
																									onValueChange={({
																										value: v,
																									}) => {}}
																								/>
																							</Box>
																						</Box>
																						<Divider
																							sx={{
																								marginTop: 2,
																								marginBottom: 2,
																								opacity: 0.5,
																							}}
																						/>
																					</Box>
																				);
																			})}
																</LCACollapsible>
															</Box>
														)
													)}
													<Box
														sx={{
															backgroundColor: "#fafafa",
															padding: 4,
															display: "flex",
															gap: 12,
															justifyContent: {
																xs: "space-between",
																sm: "space-between",
																md: "flex-end",
															},
														}}
													>
														<Typography fontWeight={500}>
															Offsets to be purchased:
														</Typography>
														<Typography fontWeight={500}>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 0,
																minimumFractionDigits: 0,
															}).format(
																Math.ceil(
																	certification?.estimations.reduce(
																		(prev, current) => {
																			return (
																				prev +
																				Math.ceil(
																					(current.product_lca_version_emission
																						?.emissions ?? 0) *
																						(!isNaN(current.amount ?? 0) &&
																						current.amount
																							? current.amount
																							: 0) *
																						0.001
																				)
																			);
																		},
																		0
																	) ?? 0
																)
															)}{" "}
															MT
														</Typography>
													</Box>
												</Box>
											)}

										{!loadingDocuSignResult &&
											certification &&
											certification?.certification_process_steps[currentStep]
												.step.step_type === "OTHER" && (
												<Box
													sx={{
														marginTop: 2,
														marginBottom: 2,
														display: "flex",
														flexDirection: "column",
														justifyContent: "flex-start",
														gap: 2,
													}}
												>
													{/*<ShareImpact
													first_time_fee_paid={
														certification?.first_time_fee_paid ?? null
													}
													estimations={certification?.estimations}
												/>*/}
													<Typography>
														You can now download ClimeCo's Marketing Kit and
														Brand Guidelines to showcase your new certification
														to your stakeholders.
													</Typography>
													<Button
														variant="contained"
														sx={{ width: 200 }}
														onClick={() => {
															handleKitDownload();
														}}
													>
														Download the Kit
													</Button>
												</Box>
											)}
										{certification?.certification_process_steps[currentStep]
											.step.step_type === "CERTIFICATION_SUMMARY" &&
										!user?.is_superuser ? (
											!certification?.requested_approval ? (
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														marginTop: 2,
														width: "100%",
													}}
												>
													<Typography sx={{ marginBottom: 1 }}>
														By clicking the "Agree" button, you are agreeing to
														purchase the displayed options.
													</Typography>
													<Box
														sx={{
															display: "flex",
															gap: 1,
															justifyContent: "flex-end",
														}}
													>
														{!certification?.requested_approval && (
															<Button
																variant="outlined"
																sx={{ maxWidth: 150 }}
																onClick={() => {
																	setCurrentStep(currentStep - 1);
																	setOriginalEstimations(
																		certification.estimations
																	);
																	setCurrentProducts(
																		certification.estimations.map(
																			(estimation) => estimation.product
																		)
																	);
																}}
															>
																Back
															</Button>
														)}
														<Button
															variant="contained"
															sx={{ maxWidth: 150 }}
															onClick={() => {
																setShowAgreeModal(true);
															}}
														>
															Agree
														</Button>
													</Box>
												</Box>
											) : null
										) : null}
										{certification.certification_process_steps[currentStep].step
											.step_type === "CERTIFICATION_SUMMARY" && (
											<Grid
												container
												spacing={2}
												sx={{
													flex: 1,
													marginTop: -4,
													display: "flex",
													justifyContent:
														user && user.is_superuser
															? "space-between"
															: "flex-end",
												}}
											>
												<Grid
													item
													xs={12}
													sm={12}
													md={12}
													lg={!user?.is_superuser ? 12 : 6}
													sx={{
														height: { md: "auto", lg: "100%" },
														display: "flex",
														flexDirection: "column",
													}}
												>
													<Typography
														sx={{
															fontSize: 12,
															marginBottom: 1,
															marginTop: 4,
														}}
													>
														PURCHASE SUMMARY
													</Typography>
													<Box
														sx={{
															backgroundColor: "#fafafa",
															padding: 4,
															minHeight: "256px",
															display: "flex",
															flexDirection: "column",
														}}
													>
														<Box
															sx={{
																display: "flex",
																justifyContent: "space-between",
																flexDirection: {
																	xs: "column",
																	sm: "row",
																},
															}}
														>
															<Typography fontWeight={500}>
																Price of offsets to be purchased:
															</Typography>
															<Typography fontWeight={500}>
																{Intl.NumberFormat("en", {
																	maximumFractionDigits: 2,
																	minimumFractionDigits: 2,
																	style: "currency",
																	currency: "usd",
																}).format(12.5)}{" "}
																x{" "}
																{Intl.NumberFormat("en", {
																	maximumFractionDigits: 0,
																	minimumFractionDigits: 0,
																}).format(
																	Math.ceil(
																		certification?.estimations.reduce(
																			(prev, current) => {
																				return (
																					prev +
																					Math.ceil(
																						(current
																							.product_lca_version_emission
																							?.emissions ?? 0) *
																							(!isNaN(current.amount ?? 0) &&
																							current.amount
																								? current.amount
																								: 0) *
																							0.001
																					)
																				);
																			},
																			0
																		) ?? 0
																	)
																)}{" "}
																={" "}
																{Intl.NumberFormat("en", {
																	maximumFractionDigits: 2,
																	minimumFractionDigits: 2,
																	style: "currency",
																	currency: "usd",
																}).format(
																	Math.ceil(
																		certification?.estimations.reduce(
																			(prev, current) => {
																				return (
																					prev +
																					Math.ceil(
																						(current
																							.product_lca_version_emission
																							?.emissions ?? 0) *
																							(!isNaN(current.amount ?? 0) &&
																							current.amount
																								? current.amount
																								: 0) *
																							0.001
																					)
																				);
																			},
																			0
																		) ?? 0
																	) * 12.5
																)}
															</Typography>
														</Box>

														{Math.ceil(
															certification?.estimations.reduce(
																(prev, current) => {
																	return (
																		prev +
																		Math.ceil(
																			(current.product_lca_version_emission
																				?.emissions ?? 0) *
																				(!isNaN(current.amount ?? 0) &&
																				current.amount
																					? current.amount
																					: 0) *
																				0.001
																		)
																	);
																},
																0
															) ?? 0
														) *
															12.5 +
															annualFeeToPay +
															lcaFeeToPay +
															revisedLCAFeeToPay /*-
															(balance > 0 ? balance : 0)*/ <
															5000 &&
															(!certification.company.first_time_fee_paid ||
																(certification.company.first_time_fee_paid &&
																	new Date(
																		certification.company.first_time_fee_paid
																	).getTime() <
																		new Date().getTime() -
																			365 * 24 * 60 * 60 * 1000)) && (
																<>
																	<Box
																		sx={{
																			display: "flex",
																			justifyContent: "space-between",
																			marginTop: 1,
																		}}
																	>
																		<Typography
																			sx={{
																				fontWeight: 500,
																				maxWidth: 320,
																				color: "#EB4B4B",
																			}}
																		>
																			Price of Additional offsets you will be
																			purchasing to reach minimum:
																		</Typography>
																		<Typography
																			sx={{ fontWeight: 500, color: "#EB4B4B" }}
																		>
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 2,
																				minimumFractionDigits: 2,
																				style: "currency",
																				currency: "usd",
																			}).format(12.5)}{" "}
																			x{" "}
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 0,
																				minimumFractionDigits: 0,
																			}).format(
																				(5000 -
																					(Math.ceil(
																						certification?.estimations.reduce(
																							(prev, current) => {
																								return (
																									prev +
																									Math.ceil(
																										(current
																											.product_lca_version_emission
																											?.emissions ?? 0) *
																											(!isNaN(
																												current.amount ?? 0
																											) && current.amount
																												? current.amount
																												: 0) *
																											0.001
																									)
																								);
																							},
																							0
																						) ?? 0
																					) *
																						12.5 +
																						annualFeeToPay +
																						lcaFeeToPay +
																						revisedLCAFeeToPay)) /*+
																						rejectionsLCAFeeToPay*/ /*-
																				(balance > 0 ? balance : 0)*/ /
																					12.5
																			)}
																			{" = "}
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 2,
																				minimumFractionDigits: 2,
																				style: "currency",
																				currency: "usd",
																			}).format(
																				5000 -
																					(Math.ceil(
																						certification?.estimations.reduce(
																							(prev, current) => {
																								return (
																									prev +
																									Math.ceil(
																										(current
																											.product_lca_version_emission
																											?.emissions ?? 0) *
																											(!isNaN(
																												current.amount ?? 0
																											) && current.amount
																												? current.amount
																												: 0) *
																											0.001
																									)
																								);
																							},
																							0
																						) ?? 0
																					) *
																						12.5 +
																						annualFeeToPay +
																						lcaFeeToPay +
																						revisedLCAFeeToPay) /*+
																						rejectionsLCAFeeToPay*/ /*-
																				(balance > 0 ? balance : 0)*/
																			)}
																		</Typography>
																	</Box>
																	<Typography
																		sx={{
																			fontStyle: "italic",
																			marginBottom: 2,
																		}}
																	>
																		<small>
																			*Additional offsets will be applied to a
																			company's balance sheet which they can
																			leverage in the future.
																		</small>
																	</Typography>
																</>
															)}

														{annualFeeToPay > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	{
																		subscriptions.find(
																			(s) =>
																				s.subscription_type === "ANNUAL_FEE"
																		)!.title
																	}
																	:
																</Typography>
																<Typography fontWeight={500}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(annualFeeToPay)}
																</Typography>
															</Box>
														)}

														{lcaFeeToPay > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	{
																		subscriptions.find(
																			(s) => s.subscription_type === "LCA_FEE"
																		)!.title
																	}
																	:
																</Typography>
																<Typography
																	fontWeight={500}
																	textAlign={"right"}
																>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(
																		lcaFeeToPay / lcaFeeToPayMultiplier
																	)}{" "}
																	x{" "}
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 0,
																		minimumFractionDigits: 0,
																	}).format(lcaFeeToPayMultiplier)}
																	{" = "}
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(lcaFeeToPay)}
																</Typography>
															</Box>
														)}
														{revisedLCAFeeToPay > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	{
																		subscriptions.find(
																			(s) =>
																				s.subscription_type ===
																				"UPDATED_LCA_FEE"
																		)!.title
																	}
																	:
																</Typography>
																<Typography
																	fontWeight={500}
																	textAlign={"right"}
																>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(revisedLCAFeeToPay)}
																</Typography>
															</Box>
														)}
														{rejectionsLCAFeeToPay > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	LCA rejection fee:
																</Typography>
																<Typography
																	fontWeight={500}
																	textAlign={"right"}
																>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(rejectionsLCAFeeToPay)}
																</Typography>
															</Box>
														)}
														{false && balance > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	Current Balance:
																</Typography>
																<Typography
																	fontWeight={500}
																	textAlign={"right"}
																>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(-balance)}
																</Typography>
															</Box>
														)}
														<Box
															sx={{
																display: "flex",
																marginTop: 4,
																justifyContent: "space-between",
															}}
														>
															<Typography sx={{ fontWeight: 500 }}>
																Total Price:
															</Typography>
															<Typography sx={{ fontWeight: 500 }}>
																{Intl.NumberFormat("en", {
																	maximumFractionDigits: 2,
																	minimumFractionDigits: 2,
																	style: "currency",
																	currency: "usd",
																}).format(
																	Math.ceil(
																		certification?.estimations.reduce(
																			(prev, current) => {
																				return (
																					prev +
																					Math.ceil(
																						(current
																							.product_lca_version_emission
																							?.emissions ?? 0) *
																							(!isNaN(current.amount ?? 0) &&
																							current.amount
																								? current.amount
																								: 0) *
																							0.001
																					)
																				);
																			},
																			0
																		) ?? 0
																	) *
																		12.5 +
																		annualFeeToPay +
																		lcaFeeToPay +
																		revisedLCAFeeToPay /*-
																		(balance > 0 ? balance : 0)*/ <
																		5000 &&
																		(!certification.company
																			.first_time_fee_paid ||
																			(certification.company
																				.first_time_fee_paid &&
																				new Date(
																					certification.company.first_time_fee_paid
																				).getTime() <
																					new Date().getTime() -
																						365 * 24 * 60 * 60 * 1000))
																		? 5000 + rejectionsLCAFeeToPay
																		: Math.ceil(
																				certification?.estimations.reduce(
																					(prev, current) => {
																						return (
																							prev +
																							Math.ceil(
																								(current
																									.product_lca_version_emission
																									?.emissions ?? 0) *
																									(!isNaN(
																										current.amount ?? 0
																									) && current.amount
																										? current.amount
																										: 0) *
																									0.001
																							)
																						);
																					},
																					0
																				) ?? 0
																		  ) *
																				12.5 +
																				annualFeeToPay +
																				lcaFeeToPay +
																				revisedLCAFeeToPay +
																				rejectionsLCAFeeToPay /*-
																				(balance > 0 ? balance : 0)*/
																)}
															</Typography>
														</Box>
													</Box>
												</Grid>
												{user && user.is_superuser && (
													<Grid
														item
														xs={12}
														sm={12}
														md={12}
														lg={6}
														sx={{ height: { md: "auto", lg: "100%" } }}
													>
														<Typography
															sx={{
																fontSize: 12,
																marginBottom: 1,
																marginTop: 4,
															}}
														>
															COMPANY SUMMARY
														</Typography>
														<Box
															sx={{
																backgroundColor: "#fafafa",
																padding: 4,
																minHeight: "256px",
																flex: 1,
																display: "flex",
																flexDirection: "column",
															}}
														>
															<Box
																sx={{
																	display: "flex",
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	Company Name:
																</Typography>
																<Typography fontWeight={500}>
																	{certification.company.name}
																</Typography>
															</Box>

															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	Client's Name:
																</Typography>
																<Typography fontWeight={500}>
																	{certification.company.contact_name ?? ""}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	Company Offset Remaining:
																</Typography>
																<Typography fontWeight={500}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 0,
																		minimumFractionDigits: 0,
																	}).format(balance)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	Offsets To Be Purchased:
																</Typography>
																<Typography fontWeight={500}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 0,
																		minimumFractionDigits: 0,
																	}).format(
																		Math.ceil(
																			certification?.estimations.reduce(
																				(prev, current) => {
																					return (
																						prev +
																						Math.ceil(
																							(current
																								.product_lca_version_emission
																								?.emissions ?? 0) *
																								(!isNaN(current.amount ?? 0) &&
																								current.amount
																									? current.amount
																									: 0) *
																								0.001
																						)
																					);
																				},
																				0
																			) ?? 0
																		) *
																			12.5 +
																			annualFeeToPay +
																			lcaFeeToPay +
																			revisedLCAFeeToPay /*-
																			(balance > 0 ? balance : 0)*/ <
																			5000 &&
																			(!certification.company
																				.first_time_fee_paid ||
																				(certification.company
																					.first_time_fee_paid &&
																					new Date(
																						certification.company.first_time_fee_paid
																					).getTime() <
																						new Date().getTime() -
																							365 * 24 * 60 * 60 * 1000))
																			? Math.ceil(
																					certification?.estimations.reduce(
																						(prev, current) => {
																							return (
																								prev +
																								Math.ceil(
																									(current
																										.product_lca_version_emission
																										?.emissions ?? 0) *
																										(!isNaN(
																											current.amount ?? 0
																										) && current.amount
																											? current.amount
																											: 0) *
																										0.001
																								)
																							);
																						},
																						0
																					) ?? 0
																			  ) +
																					(5000 -
																						(Math.ceil(
																							certification?.estimations.reduce(
																								(prev, current) => {
																									return (
																										prev +
																										Math.ceil(
																											(current
																												.product_lca_version_emission
																												?.emissions ?? 0) *
																												(!isNaN(
																													current.amount ?? 0
																												) && current.amount
																													? current.amount
																													: 0) *
																												0.001
																										)
																									);
																								},
																								0
																							) ?? 0
																						) *
																							12.5 +
																							annualFeeToPay +
																							lcaFeeToPay +
																							revisedLCAFeeToPay)) /*+
																							rejectionsLCAFeeToPay*/ /*-
																					(balance > 0 ? balance : 0)*/ /
																						12.5
																			: Math.ceil(
																					certification?.estimations.reduce(
																						(prev, current) => {
																							return (
																								prev +
																								Math.ceil(
																									(current
																										.product_lca_version_emission
																										?.emissions ?? 0) *
																										(!isNaN(
																											current.amount ?? 0
																										) && current.amount
																											? current.amount
																											: 0) *
																										0.001
																								)
																							);
																						},
																						0
																					) ?? 0
																			  )
																	)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	Future Offset Inventory:
																</Typography>
																<Typography fontWeight={500}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 0,
																		minimumFractionDigits: 0,
																	}).format(
																		Math.ceil(
																			certification?.estimations.reduce(
																				(prev, current) => {
																					return (
																						prev +
																						Math.ceil(
																							(current
																								.product_lca_version_emission
																								?.emissions ?? 0) *
																								(!isNaN(current.amount ?? 0) &&
																								current.amount
																									? current.amount
																									: 0) *
																								0.001
																						)
																					);
																				},
																				0
																			) ?? 0
																		) *
																			12.5 +
																			annualFeeToPay +
																			lcaFeeToPay +
																			revisedLCAFeeToPay /*-
																			(balance > 0 ? balance : 0)*/ <
																			5000 &&
																			(!certification.company
																				.first_time_fee_paid ||
																				(certification.company
																					.first_time_fee_paid &&
																					new Date(
																						certification.company.first_time_fee_paid
																					).getTime() <
																						new Date().getTime() -
																							365 * 24 * 60 * 60 * 1000))
																			? Math.ceil(
																					certification?.estimations.reduce(
																						(prev, current) => {
																							return (
																								prev +
																								Math.ceil(
																									(current
																										.product_lca_version_emission
																										?.emissions ?? 0) *
																										(!isNaN(
																											current.amount ?? 0
																										) && current.amount
																											? current.amount
																											: 0) *
																										0.001
																								)
																							);
																						},
																						0
																					) ?? 0
																			  ) +
																					(5000 -
																						(Math.ceil(
																							certification?.estimations.reduce(
																								(prev, current) => {
																									return (
																										prev +
																										Math.ceil(
																											(current
																												.product_lca_version_emission
																												?.emissions ?? 0) *
																												(!isNaN(
																													current.amount ?? 0
																												) && current.amount
																													? current.amount
																													: 0) *
																												0.001
																										)
																									);
																								},
																								0
																							) ?? 0
																						) *
																							12.5 +
																							annualFeeToPay +
																							lcaFeeToPay +
																							revisedLCAFeeToPay)) /*+
																							rejectionsLCAFeeToPay*/ /*-
																				(balance > 0 ? balance : 0)*/ /
																						12.5
																			: Math.ceil(
																					certification?.estimations.reduce(
																						(prev, current) => {
																							return (
																								prev +
																								Math.ceil(
																									(current
																										.product_lca_version_emission
																										?.emissions ?? 0) *
																										(!isNaN(
																											current.amount ?? 0
																										) && current.amount
																											? current.amount
																											: 0) *
																										0.001
																								)
																							);
																						},
																						0
																					) ?? 0
																			  ) /*+ balance*/
																	)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography fontWeight={500}>
																	Active Products:
																</Typography>
																<Typography fontWeight={500}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 0,
																		minimumFractionDigits: 0,
																	}).format(productsCount)}
																</Typography>
															</Box>
														</Box>
													</Grid>
												)}
											</Grid>
										)}

										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												.step.step_type === "CERTIFICATION_SUMMARY" && (
												<Box sx={{ marginTop: 4 }}>
													{certification.certification_process_steps[
														currentStep
													].reject_reason && (
														<Alert severity="error" sx={{ marginBottom: 4 }}>
															Your certification has been rejected:{" "}
															{
																certification.certification_process_steps[
																	currentStep
																].reject_reason
															}
														</Alert>
													)}
													{certification?.estimations && (
														<ProductSummarys
															certification={certification}
															setCertification={setCertification}
															refetchCertification={refetchCertification}
															setShowConfirmation={setShowConfirmation}
															setConfirmationData={setConfirmationData}
															currentStep={currentStep}
														/>
													)}
												</Box>
											)}

										{!loadingDocuSignResult &&
										certification?.certification_process_steps[currentStep].step
											.step_type === "PURCHASE_OFFSETS" &&
										(!certification?.manual_approval || !user?.is_superuser) ? (
											certification?.certification_process_steps[currentStep]
												.payment_status === "NOT_STARTED" ? (
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
													}}
												>
													<Button
														variant="contained"
														onClick={() => {
															setShowPurchaseOffsets(true);
														}}
														sx={{ marginRight: 2 }}
													>
														SEE INVOICE
													</Button>
												</Box>
											) : certification?.certification_process_steps[
													currentStep
											  ].payment_status === "PENDING" ? (
												user?.is_superuser ? (
													<Box>
														<Alert severity="info">
															This payment has been executed with a "pay-later"
															method.
															<br />
															<b>
																If you want to mark it as paid, please, click
																the button below.
															</b>
														</Alert>
														<Button
															variant="contained"
															onClick={() => {
																setShowMarkItAsPaidModal(true);
															}}
															sx={{ marginTop: 2 }}
														>
															MARK IT AS PAID
														</Button>
													</Box>
												) : (
													<Box>
														<Alert severity="info">
															Your payment order has been submitted and is now
															waiting for approval and verification from a
															ClimeCo representative.
															<br />
															<b>
																You will receive an email when completed (1-2
																business days).
															</b>
														</Alert>
														<Button
															variant="contained"
															onClick={() => {
																setShowPurchaseOffsets(true);
															}}
															sx={{ marginRight: 2, marginTop: 2 }}
														>
															SEE INVOICE
														</Button>
													</Box>
												)
											) : null
										) : null}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												.step.step_type === "PURCHASE_OFFSETS" &&
											certification?.manual_approval &&
											user?.is_superuser && (
												<>
													<Divider sx={{ marginBottom: 3 }} />
													<Typography variant="h6">
														Manually add offsets for this certification
													</Typography>
													<span className={classes.inputLabel}>OFFSETS</span>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															gap: 2,
														}}
													>
														<NumericFormat
															value={manualOffsets}
															style={{
																border: "1px solid rgb(3, 176, 147)",
																borderRadius: 4,
																maxWidth: 200,
															}}
															name={"amount"}
															placeholder="Offsets"
															customInput={TextField}
															type="text"
															allowNegative={false}
															decimalScale={0}
															autoFocus={true}
															thousandSeparator={","}
															onValueChange={({ floatValue }) => {
																setManualOffsets(floatValue);
															}}
														/>
													</Box>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															flex: 1,
														}}
													>
														<span className={classes.inputLabel}>
															Price per Offset
														</span>
														<NumericFormat
															value={manualOffsetsPrice}
															placeholder="Price per Offset"
															customInput={TextField}
															onValueChange={({ value }) => {
																setManualOffsetsPrice(parseFloat(value));
															}}
															style={{
																border: "1px solid rgb(3, 176, 147)",
																borderRadius: 4,
																maxWidth: 200,
															}}
															allowNegative={false}
															decimalScale={2}
															thousandSeparator={","}
														/>
													</Box>
													<Button
														variant="contained"
														onClick={() => {
															mutateAddOffsets();
														}}
														sx={{
															marginTop: 4,
															alignSelf: "center",
															minHeight: 34,
														}}
														disabled={
															isLoading ||
															isLoadingMutateAddOffsets ||
															manualOffsets === undefined ||
															manualOffsets < 0
														}
													>
														{isLoading || isLoadingMutateAddOffsets ? (
															<CircularProgress
																size={12}
																sx={{ color: "white" }}
															/>
														) : (
															"Save manual offsets"
														)}
													</Button>
												</>
											)}

										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												.step.step_type === "REDIRECTION" &&
											!certification?.manual_approval &&
											!user?.is_superuser &&
											certification?.climeco_contract_url_customer && (
												<Box sx={{ marginTop: 4, marginBottom: 4 }}>
													<Typography sx={{ marginBottom: 2 }}>
														Click on the link or copy it if you want to share
														it. Have in mind <b>it expires</b>, so come back
														here to generate a new one if needed.
													</Typography>
													<br />
													<a
														href={certification?.climeco_contract_url_customer}
													>
														Contract link
													</a>{" "}
													<IconButton
														onClick={async () => {
															try {
																await navigator.clipboard.writeText(
																	certification!.climeco_contract_url_customer!
																);
																enqueueSnackbar("Link has been copied!", {
																	variant: "success",
																});
															} catch (err) {
																console.error("Failed to copy: ", err);
																enqueueSnackbar("Link can't be copied!", {
																	variant: "success",
																});
															}
														}}
													>
														<ContentCopyIcon></ContentCopyIcon>
													</IconButton>
												</Box>
											)}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												.step.step_type === "REDIRECTION" &&
											certification?.manual_approval &&
											user?.is_superuser && (
												<>
													<Divider sx={{ marginBottom: 3 }} />
													<Typography variant="h6">
														Add all contract documents for this certification
													</Typography>
													<span className={classes.inputLabel}>
														ADD CONTRACT DOCUMENTS
													</span>
													<UploadContracts
														id={certification.id}
														companyId={certification.company.id}
														certificationProcessId={
															certification?.certification_process_steps[
																currentStep
															].id
														}
														callback={refetchCertification}
													/>
												</>
											)}
										<Box
											sx={{
												display: "flex",
												justifyContent: ["REDIRECTION", "WAITING"].find(
													(step) =>
														step ===
														certification?.certification_process_steps[
															currentStep
														].step.step_type
												)
													? "center"
													: "flex-end",
												marginTop: 6,
											}}
										>
											{certification?.certification_process_steps[currentStep]
												.step.step_type === "CERTIFICATION_SUMMARY" &&
												certification.certification_process_steps[currentStep]
													.reject_reason && (
													<Button
														variant="outlined"
														onClick={() => {
															setCurrentStep(currentStep - 1);
															setOriginalEstimations(certification.estimations);
															setCurrentProducts(
																certification.estimations.map(
																	(estimation) => estimation.product
																)
															);
														}}
														sx={{ marginRight: 2 }}
													>
														RESUBMIT
													</Button>
												)}
										</Box>
									</Box>
								</>
							)}
					</Box>
				</>
			)}
			<BasicModal
				width={800}
				showModal={showAgreeModal}
				handleClose={() => {
					setShowAgreeModal(false);
				}}
				children={
					certification && (
						<CertificationAgree
							text="By clicking 'AGREE' you are agreeing to the costs outlined in the below 'Purchase Summary' and advancing to the payment step."
							button1="Cancel"
							button1Callback={() => {
								setShowAgreeModal(false);
							}}
							button2="Agree"
							button2Callback={() => {
								mutateApproval();
							}}
							isLoading={isLoadingApproval}
							certification={certification}
							subscriptions={subscriptions}
							annualFeeToPay={annualFeeToPay}
							lcaFeeToPay={lcaFeeToPay}
							lcaFeeToPayMultiplier={lcaFeeToPayMultiplier}
							revisedLCAFeeToPay={revisedLCAFeeToPay}
							revisedLCAFeeToPayMultiplier={revisedLCAFeeToPayMultiplier}
							rejectionsLCAFeeToPay={rejectionsLCAFeeToPay}
							balance={balance}
						/>
					)
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showConfirmation}
				handleClose={() => {
					setShowConfirmation(false);
				}}
				children={
					<CertificationConfirmation
						confirmationData={confirmationData}
						handleModalClose={() => {
							setShowConfirmation(false);
						}}
						isLoading={
							isLoadingCreateEstimation ||
							isLoadingUpdateEstimation ||
							isLoading ||
							isLoadingDeleteEstimation
						}
					/>
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showMarkItAsPaidModal}
				handleClose={() => {
					setShowMarkItAsPaidModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							Are you sure you want to mark this certification as paid?
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowMarkItAsPaidModal(false);
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={isLoadingMarkItAsPaid}
								onClick={() => {
									mutateMarkItAsPaid();
								}}
							>
								{isLoadingMarkItAsPaid ? (
									<CircularProgress size={12} style={{ color: "white" }} />
								) : (
									"Mark it as paid"
								)}
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={800}
				showModal={showCreateProductModal}
				handleClose={() => {
					setShowCreateProductModal(false);
				}}
				children={
					<BulkProducts
						inModal
						inModalCallback={(products) => {
							setShowCreateProductModal(false);
							refetchActiveProducts();
							setCurrentProducts((elements) => [...elements, ...products]);
						}}
					/>
				}
			/>
			<BasicModal
				width={800}
				showModal={showTitleModal}
				handleClose={() => {
					setShowTitleModal(false);
				}}
				children={
					<Box>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Products List
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							{certification &&
								certification.estimations.map((est, index) => (
									<p key={est.id}>
										{est.product?.name}
										{(est.product?.asin || est.product?.upc) && (
											<>
												{" - "}
												{est.product?.asin || est.product?.upc || "--"}
											</>
										)}
									</p>
								))}
						</Typography>
					</Box>
				}
			/>
			<BasicModal
				width={800}
				showModal={showAmazonModal}
				handleClose={() => {
					setShowAmazonModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h6"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							Congratulations on getting your products ClimeCo Certified! If you
							are an Amazon seller, you are now eligible to receive the Climate
							Pledge Friendly badge. Would you like to certify your products
							with Amazon?
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowAmazonModal(false);
								}}
							>
								No
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={false}
								onClick={() => {
									navigate("/certifications/create", {
										state: {
											products: certification?.estimations.map(
												(estimation) => estimation.product
											) as IProduct[],
										},
									});
								}}
							>
								Yes
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={1200}
				showModal={showPurchaseOffsets}
				handleClose={() => {
					setShowPurchaseOffsets(false);
				}}
				hideClose={false}
				children={
					certification?.estimations && (
						<PurchaseOffsets
							quantity={
								(5000 -
									annualFeeToPay -
									lcaFeeToPay -
									revisedLCAFeeToPay /*-
									+(balance > 0 ? balance : 0)*/ -
									Math.ceil(
										certification?.estimations.reduce((prev, current) => {
											return (
												prev +
												Math.ceil(
													(current.product_lca_version_emission?.emissions ??
														0) *
														(!isNaN(current.amount ?? 0) && current.amount
															? current.amount
															: 0) *
														0.001
												)
											);
										}, 0) ?? 0
									) *
										12.5) /
									12.5 >
									0 &&
								(!certification.company.first_time_fee_paid ||
									(certification.company.first_time_fee_paid &&
										new Date(
											certification.company.first_time_fee_paid
										).getTime() <
											new Date().getTime() - 365 * 24 * 60 * 60 * 1000))
									? (5000 -
											(annualFeeToPay + lcaFeeToPay + revisedLCAFeeToPay)) /*-
											(balance > 0 ? balance : 0)*/ /
									  12.5
									: Math.ceil(
											certification.estimations.reduce(
												(prev, current) =>
													prev +
													Math.ceil(
														(current.product_lca_version_emission?.emissions ??
															0) *
															(current.amount ?? 0) *
															0.001
													),
												0
											) /*- (balance > 0 ? balance : 0)*/
									  )
							}
							productsCount={certification.estimations.length}
							needsFirstTimeFee={
								certification.company.first_time_fee_paid === null
							}
							certificationProcessId={certification.id}
							callback={() => {
								/*enqueueSnackbar("Successful payment! Moving to next step...", {
									variant: "success",
								});*/

								setTimeout(() => {
									refetchCertification();
								}, 1000);
							}}
							height={"700px"}
							inModal={true}
							closeModal={() => {
								setShowPurchaseOffsets(false);
							}}
							offsetsLoadingMessages={offsetsLoadingMessages}
							messagesIndex={messagesIndex}
							setLoadingOffsetsResult={setLoadingOffsetsResult}
							lcaIds={(certification.lca_versions ?? [])
								.filter((l) => l.lca_id)
								.map((l) => l.lca_id!)}
							lcaVersionIds={(certification.lca_versions ?? []).map(
								(l) => l.id
							)}
						/>
					)
				}
			/>
			<BasicModal
				width={800}
				showModal={showNewLCAVersionModal}
				handleClose={() => {
					setShowNewLCAVersionModal(false);
				}}
				hideClose={false}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h6"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							There is a newer version of this certification and LCA version.
							Would you like to see that instead?
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowNewLCAVersionModal(false);
								}}
							>
								No
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={false}
								onClick={() => {
									window.location.href =
										window.location.origin +
										"/climeco-certificationss/" +
										LCA!.lca_versions![0].certification_process!.id;
									/*navigate(
										"/climeco-certificationss/" +
											LCA!.lca_versions![0].certification_processes[0].id
									);*/
								}}
							>
								Yes
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
