import { useContext, useEffect, useState } from "react";
import { PropTypes } from "./BulkProductsTable.types";
import { ICompany, IProduct, IProductLCA, IUser } from "../../models";
import {
	Box,
	TextField,
	Button,
	IconButton,
	CircularProgress,
	Table,
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	Typography,
	Autocomplete,
	Select,
	MenuItem,
	SelectChangeEvent,
	Tooltip,
} from "@mui/material";
import { UploadFile } from "../UploadFile";
import { NumericFormat } from "react-number-format";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import {
	product as productActions,
	certification as certificationActions,
	estimation as estimationsActions,
	company as companyActions,
} from "../../controllers";
import { ICertification, IEstimation } from "../../models";
import { BasicModal } from "../BasicModal";
import { LCARecommendations } from "../LCARecommendations";
import InfoIcon from "@mui/icons-material/Info";

export function BulkProductsTable({
	inModal,
	inModalCallback,
	newIntegration,
	initialProducts,
	setInitialProducts,
	lcaVersion,
}: PropTypes) {
	const [rows, setAuxRows] = useState<Array<IProduct>>(
		initialProducts ?? [
			{
				id: new Date().getTime(),
				status: "PENDING" as "PENDING",
				name: "",
				description: "",
				asin: "",
				upc: "",
				lca_documents: [
					{
						id: new Date().getTime(),
						lca_document: undefined,
						certification_date: "",
						lca_provider: "",
						emissions: null,
					},
				],
				product_lca_version_emission: {
					id: -new Date().getTime(),
					lca_version: lcaVersion,
				},
			},
		]
	);
	const setRows = (products: Array<IProduct>) => {
		if (setInitialProducts) {
			setInitialProducts(products);
		}
		setAuxRows(products);
	};
	const { enqueueSnackbar } = useSnackbar();
	const { user, setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const [dateType, setDateType] = useState(["text"]);
	const [amazonDateType, setAmazonDateType] = useState(["text"]);
	const [certifying, setCertifying] = useState(false);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [loading, setLoading] = useState(false);

	const [showLCARecommendations, setLCARecommendations] = useState(false);
	const [hasCompletedHistorialCertification] = useState<boolean | null>(null);

	const ProductStatus = {
		ACTIVE: "ACTIVE",
		PENDING: "PENDING",
		OUTDATED: "OUTDATED",
	} as const;

	useEffect(() => {
		setRows([...rows]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAddInput = () => {
		setRows([
			...rows,
			{
				id: new Date().getTime(),
				status: "PENDING",
				name: "",
				description: "",
				asin: "",
				upc: "",
				lca_documents: [
					{
						id: new Date().getTime(),
						lca_document: undefined,
						certification_date: "",
						lca_provider: "",
						emissions: null,
					},
				],
				brand: "",
				product_lca_version_emission: {
					id: new Date().getTime(),
					emissions: 0,
					lca_version: lcaVersion,
				},
			},
		]);
		setDateType([...dateType, "text"]);
		setAmazonDateType([...amazonDateType, "text"]);
	};

	const handleRemoveInput = (index: number) => {
		if (rows.length > 1) {
			const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
			setRows(newRows);

			const newDateTypes = dateType.filter((_, dtIndex) => dtIndex !== index);
			setDateType(newDateTypes);

			const newAmazonDateTypes = amazonDateType.filter(
				(_, adtIndex) => adtIndex !== index
			);
			setAmazonDateType(newAmazonDateTypes);
		}
	};

	const handleDuplicateInput = (index: number) => {
		const productToDuplicate = rows[index];

		const newSelectedQuarters = [...selectedQuarters];
		newSelectedQuarters.push(selectedQuarters[index]);
		setSelectedQuarters(newSelectedQuarters);

		const duplicatedProduct = {
			id: new Date().getTime(),
			status: ProductStatus.PENDING,
			name: productToDuplicate.name,
			description: productToDuplicate.description,
			asin: "",
			upc: "",
			product_lca_version_emission: {
				id: new Date().getTime(),
				emissions: 0,
				lca_version: lcaVersion,
			},
			lca_documents: [
				{
					id: new Date().getTime(),
					lca_document:
						productToDuplicate.lca_documents &&
						productToDuplicate.lca_documents.length > 0
							? productToDuplicate.lca_documents[0].lca_document
							: undefined,
					certification_date:
						productToDuplicate.lca_documents &&
						productToDuplicate.lca_documents.length > 0
							? productToDuplicate.lca_documents[0].certification_date
							: "",
					lca_provider:
						productToDuplicate.lca_documents &&
						productToDuplicate.lca_documents.length > 0
							? productToDuplicate.lca_documents[0].lca_provider
							: "",
					emissions: null,
				},
			],
			company_id: productToDuplicate.company_id,
			estimations: [
				{
					id: new Date().getTime(),
					product: productToDuplicate,
					overwrite_certification_date:
						productToDuplicate &&
						productToDuplicate.estimations &&
						productToDuplicate.estimations[0].overwrite_certification_date,
				},
			],
			brand: productToDuplicate.brand,
		};

		const updatedRows = [...rows];
		updatedRows.splice(index + 1, 0, duplicatedProduct);
		setRows(updatedRows);

		if (
			updatedRows![updatedRows.length - 1].lca_documents![0]
				.certification_date !== ""
		) {
			setDateType((prev) => [...prev, "date"]);
		}
	};

	const { isLoading, mutate } = useMutation(
		"bulk-products",
		({ product }: { product: IProduct }) =>
			productActions.create({
				name: product?.name,
				description: product?.description,
				companyId: user?.is_superuser ? product.company_id : user?.company.id,
				external_id: product?.external_id,
				sku: product?.sku,
				asin: product?.asin,
				ean: product?.ean,
				isbn: product?.isbn,
				upc: product?.upc,
				gtin: product?.gtin,
				websites: product?.websites ?? [""],
				status: user?.is_superuser ? product?.status : undefined,
				brand: product?.brand,
			}),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingDocuments, mutate: mutateDocuments } =
		useMutation(
			"products-lca-update",
			({
				productLCA,
				productId,
				companyId,
			}: {
				productLCA: IProductLCA;
				productId: number;
				companyId?: number;
			}) =>
				productActions.createLCA({
					companyId: !user?.is_superuser ? user?.company.id : companyId,
					productId: productId,
					emissions: productLCA.emissions!,
					lca_document: productLCA.lca_document!,
					lca_provider: productLCA.lca_provider,
					certification_date: productLCA.certification_date,
				})
		);

	const { mutate: createEstimationMutate, isLoading: isLoadingEstimation } =
		useMutation(
			"start-estimations",
			({
				estimation,
				certification,
			}: {
				estimation: IEstimation;
				certification: ICertification;
			}) =>
				estimationsActions.create({
					amount: estimation.amount,
					//emissions: estimation.emissions,
					product: estimation.product.id,
					certificationProcess: certification!.id,
					companyId: user?.is_superuser
						? estimation.product.company_id
						: user!.company.id,
					overwrite_certification_date: estimation.overwrite_certification_date,
				}),
			{
				retry: false,
			}
		);

	const { mutate: createCertification } = useMutation(
		"certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: true,
				name: "New",
				companyId: user?.is_superuser
					? filteredRows[0].company_id
					: user!.company.id,
				isHistorical:
					!user?.is_superuser && !user?.company.historically_approved,
			}),
		{
			retry: false,
		}
	);

	const filteredRows = rows.filter(
		(r) =>
			r.name &&
			(!user?.company.historically_amazon_certified ||
				(user?.company.historically_amazon_certified && r.asin)) &&
			r.lca_documents &&
			r.lca_documents.length > 0 &&
			r.lca_documents &&
			r.lca_documents.length > 0 &&
			r.lca_documents![0].lca_document &&
			r.lca_documents![0].certification_date &&
			r.lca_documents![0].lca_provider &&
			r.lca_documents![0].emissions
	);

	const bulkProducts = (certify: boolean) => {
		setLoading(true);
		let index = 0;
		enqueueSnackbar(
			"The process might take some minutes if you are uploading +10 products. Please, wait.",
			{
				variant: "info",
			}
		);
		const createProduct = (product: IProduct, index: number) => {
			mutate(
				{
					product: product,
				},
				{
					onSuccess: async (res) => {
						const auxRows = [...filteredRows];
						auxRows[index].id = res.id;
						setRows(auxRows);
						index += 1;
						await new Promise((resolve, reject) => {
							mutateDocuments(
								{
									productLCA: product.lca_documents![0],
									productId: res.id,
									companyId: user?.is_superuser
										? product.company_id
										: user!.company.id,
								},
								{
									onSuccess: (res) => {
										resolve(true);
									},
									onError: (error: any) => {
										setLoading(false);
										enqueueSnackbar(
											`Something went wrong while updating the documents for the product named ${
												product.name +
												(product.asin
													? " - " + product.asin
													: product.upc
													? " - " + product.upc
													: "")
											}. Please retry it or contact support`,
											{
												variant: "error",
											}
										);
										reject();
									},
								}
							);
						});
						if (index < filteredRows.length) {
							createProduct(filteredRows[index], index);
						} else {
							if (certify) {
								if (
									filteredRows.find(
										(row) =>
											((row as IProduct).certification_processes?.length ?? 0) >
											0
									)
								) {
									setLoading(false);
									enqueueSnackbar(
										"Some of the selected products have already started a certification process.",
										{
											variant: "error",
										}
									);
								} else {
									// create certification
									createCertification(null, {
										onSuccess: (res) => {
											// create estimations
											let index = 0;
											const createEstimation = (
												id: number,
												amount: number,
												//emissions: number,
												product: IProduct,
												index: number
											) => {
												createEstimationMutate(
													{
														estimation: {
															id: id,
															amount: amount,
															//emissions: emissions,
															product: product,
															overwrite_certification_date:
																product?.estimations &&
																product?.estimations.length > 0
																	? product.estimations[0]
																			.overwrite_certification_date
																	: undefined,
														},
														certification: res,
													},
													{
														onSuccess: () => {
															index += 1;
															if (index < filteredRows.length) {
																createEstimation(
																	index,
																	0,
																	//0,
																	filteredRows[index] as IProduct,
																	index
																);
															} else {
																setLoading(false);
																if (
																	!user?.is_superuser &&
																	!user?.company.historically_approved
																) {
																	navigate("/historical-data/" + res.id);
																} else {
																	navigate(
																		"/climeco-certificationss/" + res.id
																	);
																}
															}
														},
														onError: (error) => {
															setLoading(false);
															console.log(error);
															enqueueSnackbar("Something went wrong.", {
																variant: "error",
															});
														},
													}
												);
											};

											createEstimation(
												index,
												0,
												//0,
												filteredRows[index] as IProduct,
												index
											);
										},
										onError: (error) => {
											setLoading(false);
											console.log(error);
											enqueueSnackbar("Something went wrong.", {
												variant: "error",
											});
										},
									});
								}
							} else {
								setLoading(false);
								enqueueSnackbar("Products created successfully.", {
									variant: "success",
								});
								if (inModal) {
									setTimeout(() => {
										inModalCallback && inModalCallback(rows);
									}, 500);
								} else {
									navigate("/products");
								}
							}
						}
					},
					onError: (error: any) => {
						console.log(error);
						if (
							error.response.data.name.length > 0 &&
							error.response.data.name[0] ===
								"product with this name already exists."
						) {
							enqueueSnackbar(
								`Something went wrong. Recheck the product named: ${filteredRows[index].name}`,
								{
									variant: "error",
								}
							);
						} else {
							/*enqueueSnackbar("Something went wrong.", {
								variant: "error",
							});*/
						}

						index += 1;
						if (index < filteredRows.length) {
							createProduct(filteredRows[index], index);
						} else {
							setCertifying(false);
							setLoading(false);
							enqueueSnackbar("Products created successfully.", {
								variant: "success",
							});
							navigate("/products");
						}
					},
				}
			);
		};

		createProduct(filteredRows[index], index);
	};

	const { refetch: refetchCompanies } = useQuery(
		"companies",
		() => companyActions.list(undefined, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.is_superuser) {
			refetchCompanies();
		}
	}, [refetchCompanies, user?.is_superuser]);

	const generateQuarters = (): string[] => {
		const quarters: string[] = [];
		const currentDate = new Date();
		const currentYear = currentDate.getUTCFullYear();
		const currentQuarter = Math.floor(currentDate.getUTCMonth() / 3) + 1;

		let startYear = 2022;
		let startQuarter = 4;

		for (let year = startYear; year <= currentYear; year++) {
			for (let quarter = startQuarter; quarter <= 4; quarter++) {
				if (year === currentYear && quarter > currentQuarter) {
					break;
				}
				quarters.push(`Q${quarter}-${year}`);
			}
			startQuarter = 1;
		}
		return quarters;
	};

	const quarterToDate = (quarterString: string): string => {
		const [q, year] = quarterString.split("-");
		const month = (parseInt(q[1], 10) - 1) * 3 + 1;
		return `${year}-${month.toString().padStart(2, "0")}-01`;
	};

	const [selectedQuarters, setSelectedQuarters] = useState<string[]>([]);

	const handleSelectChange = (event: SelectChangeEvent, index: number) => {
		const newSelectedQuarters = [...selectedQuarters];
		newSelectedQuarters[index] = event.target.value as string;
		setSelectedQuarters(newSelectedQuarters);
		const date = quarterToDate(event.target.value);
		const auxRows = [...rows];

		auxRows[index].estimations = auxRows[index].estimations ?? [];
		if (auxRows[index].estimations!.length === 0) {
			auxRows[index].estimations!.push({
				id: new Date().getTime(),
				product: auxRows[index],
				overwrite_certification_date: date,
			});
		} else {
			auxRows[index].estimations![0].overwrite_certification_date = date;
		}
		setRows(auxRows);
	};

	const { isLoading: isLoadingToggleCompany, mutate: mutateToggleCompany } =
		useMutation(
			"toggle-company",
			() =>
				companyActions.update({
					id: user?.company!.id!,
					requested_historically_approved: new Date(),
				}),
			{
				retry: false,
				onSuccess: () => {
					const auxUser = { ...user };
					auxUser.company!.requested_historically_approved = new Date();
					setUser(auxUser as IUser);
					enqueueSnackbar(
						"Admins have been informed and will proceed to check your company.",
						{ variant: "success" }
					);
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong", { variant: "error" });
				},
			}
		);

	const isValidASIN = (asin: string): boolean => {
		const asinRegex = /^(B[\dA-Z]{9}|\d{9}[\dX])$/;
		return asinRegex.test(asin);
	};

	return (
		<div
			style={{
				height: "auto",
				maxHeight: "100%",
				width: "100%",
				gap: 2,
			}}
		>
			{!newIntegration && (
				<Box
					sx={{
						marginBottom: 2,
						minHeight: 34,
						minWidth: 120,
						display: "flex",
						justifyContent: "flex-end",
						gap: 2,
					}}
				>
					{(user?.is_superuser ||
						(!user?.is_superuser && user?.company.historically_approved) ||
						inModal) && (
						<Button
							variant="contained"
							onClick={() => {
								bulkProducts(false);
							}}
							disabled={
								rows.find((r, i) => {
									return (
										(!r.name ||
											/*!r.upc ||*/
											(r.upc && r.upc.length !== 12) ||
											!r.brand ||
											!r.lca_documents![0].lca_document ||
											!r.lca_documents![0].certification_date ||
											(!!r.lca_documents![0].certification_date &&
												(new Date(
													r.lca_documents![0].certification_date
												).getFullYear() < 2021 ||
													new Date(
														r.lca_documents![0].certification_date
													).getFullYear() > new Date().getFullYear())) ||
											!r.lca_documents![0].lca_provider ||
											!r.lca_documents![0].emissions) &&
										!(
											!r.name &&
											/*(!r.upc ||*/ r.upc &&
											r.upc.length !== 12 &&
											!r.brand &&
											(!r.lca_documents![0].lca_document ||
												(!!r.lca_documents![0].certification_date &&
													(new Date(
														r.lca_documents![0].certification_date
													).getFullYear() < 2021 ||
														new Date(
															r.lca_documents![0].certification_date
														).getFullYear() > new Date().getFullYear()))) &&
											!r.lca_documents![0].certification_date &&
											!r.lca_documents![0].lca_provider &&
											!r.lca_documents![0].emissions &&
											i > 0
										)
									);
								}) ||
								rows.filter((r) => r.asin).map((r) => r.asin).length !==
									new Set(rows.filter((r) => r.asin).map((r) => r.asin)).size ||
								rows.filter((r) => r.asin && !isValidASIN(r.asin)).length > 0 ||
								isLoading ||
								isLoadingDocuments ||
								isLoadingEstimation ||
								loading
									? true
									: false
							}
						>
							{!certifying &&
							(isLoading ||
								isLoadingDocuments ||
								isLoadingEstimation ||
								loading) ? (
								<CircularProgress size={12} sx={{ color: "white" }} />
							) : (
								"Save"
							)}
						</Button>
					)}
					{!inModal && (
						<>
							{!user?.is_superuser &&
								!user?.company?.historically_approved &&
								hasCompletedHistorialCertification &&
								!user?.company?.requested_historically_approved && (
									<Button
										variant="outlined"
										sx={{
											maxWidth: 300,
											alignSelf: "end",
											minHeight: 34,
										}}
										onClick={() => {
											mutateToggleCompany();
										}}
									>
										{isLoadingToggleCompany ? (
											<CircularProgress size={12} />
										) : (
											"All historical data has been entered"
										)}
									</Button>
								)}
							<Button
								variant="contained"
								onClick={() => {
									setCertifying(true);
									bulkProducts(true);
								}}
								disabled={
									rows.find((r, i) => {
										return (
											(!r.name ||
												!r.brand ||
												/*!r.upc ||*/
												!r.lca_documents![0].lca_document ||
												!r.lca_documents![0].certification_date ||
												(!!r.lca_documents![0].certification_date &&
													(new Date(
														r.lca_documents![0].certification_date
													).getFullYear() < 2021 ||
														new Date(
															r.lca_documents![0].certification_date
														).getFullYear() > new Date().getFullYear())) ||
												!r.lca_documents![0].lca_provider ||
												!r.lca_documents![0].emissions ||
												(!r.estimations &&
													!user?.company.historically_approved) ||
												(!r.company_id && user?.is_superuser)) &&
											!(
												!r.name &&
												!r.brand &&
												/*!r.upc &&*/
												!r.lca_documents![0].lca_document &&
												(!r.lca_documents![0].lca_document ||
													(!!r.lca_documents![0].certification_date &&
														(new Date(
															r.lca_documents![0].certification_date
														).getFullYear() < 2021 ||
															new Date(
																r.lca_documents![0].certification_date
															).getFullYear() > new Date().getFullYear()))) &&
												!r.lca_documents![0].lca_provider &&
												!r.lca_documents![0].emissions &&
												(user?.company.historically_approved ||
													(!r.estimations &&
														!user?.company.historically_approved)) &&
												(!user?.is_superuser ||
													(!r.company_id && user?.is_superuser)) &&
												i > 0
											)
										);
									}) ||
									rows.filter((r) => r.asin).map((r) => r.asin).length !==
										new Set(rows.filter((r) => r.asin).map((r) => r.asin))
											.size ||
									rows.find((r, i) => {
										return rows.find((r2) => r2.company_id !== r.company_id);
									}) ||
									rows
										.filter((r) => r.asin)
										.find((r, i) => {
											return rows
												.filter((r) => r.asin)
												.find((r2, j) => i !== j && r2.asin === r.asin);
										}) ||
									isLoading ||
									isLoadingDocuments ||
									isLoadingEstimation ||
									loading
										? true
										: false
								}
							>
								{certifying &&
								(isLoading ||
									isLoadingDocuments ||
									isLoadingEstimation ||
									loading) ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : !user?.is_superuser &&
								  !user?.company.historically_approved ? (
									"Save & Add Historical Data"
								) : (
									"Save & Certify"
								)}
							</Button>
						</>
					)}
				</Box>
			)}
			<Box
				sx={{
					maxHeight: lcaVersion ? undefined : "calc(100vh - 300px)",
					overflow: "auto",
				}}
			>
				<Table
					sx={{
						border: "1px solid #ececec",
						borderRadius: 1,
						borderCollapse: "unset",
						boxShadow: "none",
						"& .MuiTableRow-root": {
							verticalAlign: "top!important",
						},
					}}
				>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>
								{newIntegration ? (
									<>Brand</>
								) : (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											gap: 2,
										}}
									>
										Life Cycle Assessment (LCA)
										<Box
											sx={{
												border: "1px solid #efefef",
												padding: 1,
												maxWidth: 400,
												textAlign: "center",
											}}
										>
											<Typography variant="body2" sx={{ fontSize: 12 }}>
												Don't have a LCA of your product?{" "}
												<span
													style={{
														color: "#25406D",
														fontSize: 12,
														fontWeight: 700,
														cursor: "pointer",
													}}
													onClick={() => {
														setLCARecommendations(true);
													}}
												>
													Click Here
												</span>
											</Typography>
										</Box>
									</Box>
								)}
							</TableCell>
							<TableCell>
								{newIntegration ? (
									<>
										ASIN
										<Tooltip
											sx={{ padding: 0 }}
											title={"Amazon Standard Identification Number"}
										>
											<IconButton sx={{ height: 12 }}>
												<InfoIcon sx={{ fontSize: 14 }} />
											</IconButton>
										</Tooltip>
									</>
								) : (
									<>
										{user?.company.historically_amazon_certified
											? "Amazon Data"
											: "Product Data"}
										{!user?.company.historically_approved &&
											user?.company.historically_amazon_certified && (
												<Tooltip
													title={
														"If you were certified before Q4 2022, please select Q4 2022"
													}
												>
													<IconButton>
														<InfoIcon sx={{ fontSize: 14 }} />
													</IconButton>
												</Tooltip>
											)}
									</>
								)}
							</TableCell>
							{newIntegration && <TableCell>UPC</TableCell>}
							{newIntegration && (
								<TableCell sx={{ minWidth: 275 }}>
									Product Carbon Footprint (kgCO2eq)
								</TableCell>
							)}
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRow key={row.id}>
								<TableCell
									sx={{
										paddingLeft: 4,
									}}
								>
									<TextField
										autoFocus
										placeholder="Product name"
										sx={{
											display: "flex",
											alignSelf: "start",
											marginTop: 2,
											minWidth: 200,
										}}
										value={row.name}
										onChange={(event) => {
											const auxRows = [...rows];
											auxRows[index].name = event.target.value;
											setRows(auxRows);
										}}
									/>
									{!newIntegration && (
										<>
											<TextField
												placeholder="Brand"
												sx={{
													display: "flex",
													alignSelf: "start",
													marginTop: 2,
													minWidth: 200,
												}}
												value={row.brand}
												onChange={(event) => {
													const auxRows = [...rows];
													auxRows[index].brand = event.target.value;
													setRows(auxRows);
												}}
											/>
											{user?.is_superuser && (
												<Autocomplete
													disablePortal
													id="companies"
													options={companies}
													value={
														companies.find(
															(company) => company.id === row.company_id
														) || null
													}
													getOptionLabel={(option) => option.name}
													renderOption={(props, option) => {
														return (
															<li
																{...props}
																key={option.id}
																style={{ minWidth: 200 }}
															>
																{option.name}
															</li>
														);
													}}
													onChange={(_, value) => {
														if (value) {
															const companyId = value.id;
															const auxRows = [...rows];
															auxRows[index].company_id = companyId;
															setRows(auxRows);
														}
													}}
													renderInput={(params) => (
														<TextField
															sx={{
																display: "flex",
																alignSelf: "start",
																marginTop: 2,
															}}
															placeholder="Company"
															{...params}
															variant="outlined"
														/>
													)}
												/>
											)}
										</>
									)}
								</TableCell>
								<TableCell>
									{newIntegration ? (
										<TextField
											placeholder="Brand"
											sx={{
												display: "flex",
												alignSelf: "start",
												marginTop: 2,
												minWidth: 200,
											}}
											value={row.brand}
											onChange={(event) => {
												const auxRows = [...rows];
												auxRows[index].brand = event.target.value;
												setRows(auxRows);
											}}
										/>
									) : (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												gap: 2,
												marginTop: 2,
												marginBottom: 2,
												alignSelf: "start",
												flex: 1,
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													gap: 2,
													flex: 1,
												}}
											>
												<UploadFile
													padding={-1}
													flex={1}
													accept=".pdf,.docx,.doc"
													value={row.lca_documents![0].lca_document}
													onFileChange={(file) => {
														if (file) {
															const auxRows = [...rows];
															auxRows[index].lca_documents![0].lca_document =
																file;
															setRows(auxRows);
														}
													}}
												/>
												<TextField
													placeholder={"LCA Completion Date"}
													type={dateType[index]}
													variant="outlined"
													InputProps={{
														inputProps: {
															max: `${new Date().toISOString().split("T")[0]}`,
															style: { minWidth: "150px" },
														},
													}}
													sx={{
														flex: 1,
														minWidth: "150px",
													}}
													value={row.lca_documents![0].certification_date}
													onChange={(event) => {
														const auxRows = [...rows];
														auxRows[
															index
														].lca_documents![0].certification_date =
															event.target.value;
														setRows(auxRows);
													}}
													onFocus={() => {
														const auxDateType = [...dateType];
														auxDateType[index] = "date";
														setDateType(auxDateType);
													}}
													onBlur={() => {
														if (!row.lca_documents![0].certification_date) {
															const auxDateType = [...dateType];
															auxDateType[index] = "text";
															setDateType(auxDateType);
														}
													}}
													error={
														!!row.lca_documents![0].certification_date &&
														(new Date(
															row.lca_documents![0].certification_date
														).getFullYear() < 2021 ||
															new Date(
																row.lca_documents![0].certification_date
															).getFullYear() > new Date().getFullYear())
													}
													helperText={
														!!row.lca_documents![0].certification_date &&
														(new Date(
															row.lca_documents![0].certification_date
														).getFullYear() < 2021 ||
															new Date(
																row.lca_documents![0].certification_date
															).getFullYear() > new Date().getFullYear())
															? "Date must be between 2021 and the current year"
															: ""
													}
												/>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													gap: 2,
													flex: 1,
												}}
											>
												<Autocomplete
													freeSolo
													options={[
														"Planet FWD",
														"WAP Sustainability",
														"Carbon Pass",
														"Sustainabase",
														"Aspire Sustainability",
														"Zero Carbon Industry Operations Center (Shenzhen) Co., Ltd",
													]}
													value={row.lca_documents![0].lca_provider || ""}
													onChange={(event, newValue) => {
														let providerValue;
														if (typeof newValue === "string") {
															providerValue = newValue;
														} else {
															providerValue = newValue ? newValue : "";
														}

														const auxRows = [...rows];
														auxRows[index].lca_documents![0].lca_provider =
															providerValue;
														setRows(auxRows);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label=""
															placeholder="LCA Provider"
															onChange={(event) => {
																const auxRows = [...rows];
																auxRows[index].lca_documents![0].lca_provider =
																	event.target.value;
																setRows(auxRows);
															}}
														/>
													)}
													sx={{ flex: 1 }}
												/>
												<NumericFormat
													name={"amount"}
													placeholder="Product Carbon Footprint Value"
													customInput={TextField}
													type="text"
													thousandSeparator={","}
													suffix={" kg of CO2e"}
													allowNegative={false}
													onFocus={(e) => {}}
													onBlur={() => {}}
													value={row.lca_documents![0].emissions}
													onValueChange={({ value }) => {
														const auxRows = [...rows];
														auxRows[index].lca_documents![0].emissions =
															parseFloat(value);
														setRows(auxRows);
													}}
													style={{
														flex: 1,
														minWidth: "140px",
													}}
												/>
											</Box>
										</Box>
									)}
								</TableCell>
								<TableCell>
									{newIntegration ? (
										<Box
											sx={{
												display: "flex",
												flex: 1,
											}}
										>
											<TextField
												placeholder="ASIN (Optional)"
												sx={{
													display: "flex",
													alignSelf: "start",
													marginTop: 2,
													minWidth: 100,
													width: "100%",
													border:
														row.asin && !isValidASIN(row.asin)
															? "1px solid #eb4343"
															: undefined,
												}}
												value={row.asin}
												onChange={(event) => {
													const auxRows = [...rows];
													if (
														auxRows.find(
															(r) =>
																r.asin === event.target.value &&
																event.target.value
														)
															? true
															: false
													) {
														enqueueSnackbar(
															"ASIN should be unique and this ASIN has already been used",
															{
																variant: "error",
															}
														);
													}
													auxRows[index].asin = event.target.value;
													setRows(auxRows);
												}}
											/>
										</Box>
									) : (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												gap: 2,
												marginBottom: 2,
												alignSelf: "start",
												flex: 1,
											}}
										>
											<Box
												sx={{
													display: "flex",
													flex: 1,
												}}
											>
												{user?.company.historically_amazon_certified ? (
													<TextField
														placeholder="ASIN"
														sx={{
															display: "flex",
															alignSelf: "start",
															marginTop: 2,
															minWidth: 100,
															width: "100%",
															border:
																row.asin && isValidASIN(row.asin)
																	? "1px solid #eb4343"
																	: undefined,
														}}
														value={row.asin}
														onChange={(event) => {
															const auxRows = [...rows];
															if (
																auxRows.find(
																	(r) =>
																		r.asin === event.target.value &&
																		event.target.value
																)
																	? true
																	: false
															) {
																enqueueSnackbar(
																	"ASIN should be unique and this ASIN has already been used",
																	{
																		variant: "error",
																	}
																);
															}
															auxRows[index].asin = event.target.value;
															setRows(auxRows);
														}}
													/>
												) : (
													<TextField
														placeholder="UPC"
														sx={{
															display: "flex",
															alignSelf: "start",
															marginTop: 2,
															minWidth: 100,
															width: "100%",
															border:
																row.upc && row.upc.length < 12
																	? "1px solid #eb4343"
																	: undefined,
														}}
														type="text"
														inputProps={{
															inputMode: "numeric",
															pattern: "/^-?d+(?:.d+)?$/g",
														}}
														value={row.upc}
														onChange={(event) => {
															const value = event.target.value;
															// Regular expression to allow only numbers
															const isNumeric = /^[0-9]*$/.test(value);
															// Check if the input is numeric and does not exceed 12 digits
															if (isNumeric && value.length <= 12) {
																const auxRows = [...rows];

																auxRows[index].upc = event.target.value;
																setRows(auxRows);
															}
														}}
													/>
												)}
											</Box>
											{!user?.company.historically_approved && (
												<Box
													sx={{
														display: "flex",
														flex: 1,
													}}
												>
													<Select
														variant="outlined"
														placeholder={
															user?.company.historically_amazon_certified
																? "Amazon Certification Date"
																: "Certification Date"
														}
														value={selectedQuarters[index] || ""}
														onChange={(event) =>
															handleSelectChange(event, index)
														}
														displayEmpty
														inputProps={{ "aria-label": "Without label" }}
														sx={{
															flex: 1,
															backgroundColor: "#fafafa",
															"& .MuiSelect-select": {
																color: selectedQuarters[index]
																	? "rgba(0, 0, 0, 0.87)"
																	: "rgba(0, 0, 0, 0.54)",
															},
															"& fieldset": {
																border: "none",
															},
														}}
													>
														<MenuItem
															value=""
															disabled
															style={{ color: "rgba(0, 0, 0, 0.54)" }}
														>
															{user?.company.historically_amazon_certified
																? "Amazon Certification Date"
																: "Certification Date"}
														</MenuItem>
														{generateQuarters().map((quarter) => (
															<MenuItem key={quarter} value={quarter}>
																{quarter}
															</MenuItem>
														))}
													</Select>
												</Box>
											)}
										</Box>
									)}
								</TableCell>
								{newIntegration && (
									<TableCell>
										<TextField
											placeholder="UPC"
											sx={{
												display: "flex",
												alignSelf: "start",
												marginTop: 2,
												minWidth: 100,
												width: "100%",
												border:
													row.upc && row.upc.length < 12
														? "1px solid #eb4343"
														: undefined,
											}}
											type="text"
											inputProps={{
												inputMode: "numeric",
												pattern: "/^-?d+(?:.d+)?$/g",
											}}
											value={row.upc}
											onChange={(event) => {
												const value = event.target.value;
												// Regular expression to allow only numbers
												const isNumeric = /^[0-9]*$/.test(value);
												// Check if the input is numeric and does not exceed 12 digits
												if (isNumeric && value.length <= 12) {
													const auxRows = [...rows];

													auxRows[index].upc = event.target.value;
													setRows(auxRows);
												}
											}}
										/>
									</TableCell>
								)}
								{newIntegration && (
									<TableCell>
										<NumericFormat
											name={"amount"}
											placeholder="Product Carbon Footprint Value"
											customInput={TextField}
											type="text"
											thousandSeparator={","}
											suffix={" kgCO2 eq"}
											allowNegative={false}
											onFocus={(e) => {}}
											onBlur={() => {}}
											value={row.product_lca_version_emission?.emissions}
											onValueChange={({ value }) => {
												const auxRows = [...rows];
												let productLcaVersionEmission = auxRows[index]
													.product_lca_version_emission ?? {
													id: -new Date().getTime(),
													emissions: 0,
												};
												productLcaVersionEmission.emissions = parseFloat(value);
												auxRows[index].product_lca_version_emission =
													productLcaVersionEmission;

												setRows(auxRows);
											}}
											style={{
												flex: 1,
												minWidth: "140px",
												width: "100%",
												marginTop: 16,
											}}
										/>
									</TableCell>
								)}
								<TableCell>
									<Box
										key={row.id}
										display="flex"
										alignItems="center"
										marginBottom={2}
										gap={2}
									>
										<Tooltip sx={{ padding: 0 }} title={"Remove product"}>
											<IconButton
												color="primary"
												onClick={() => handleRemoveInput(index)}
												disabled={rows.length === 1}
											>
												<RemoveIcon sx={{ fontSize: 20 }} />
											</IconButton>
										</Tooltip>
										<Tooltip sx={{ padding: 0 }} title={"Duplicate product"}>
											<IconButton
												color="primary"
												onClick={() => handleDuplicateInput(index)}
											>
												<ContentCopyIcon sx={{ fontSize: 20 }} />
											</IconButton>
										</Tooltip>
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
			<Box
				sx={{
					minHeight: 34,
					width: "100%",
					display: "flex",
					marginTop: 2,
					flexDirection: "column",
					alignItems: "flex-end",
				}}
			>
				<Button
					variant="outlined"
					disabled={rows.length >= 20}
					sx={{
						"&.Mui-disabled": {
							backgroundColor: "#fff",
							border: "1px solid rgba(37, 64, 109, 0.5)",
							color: "#25406D",
							opacity: 0.4,
						},
						maxWidth: 200,
					}}
					onClick={() => {
						handleAddInput();
					}}
				>
					Add product
				</Button>
				{rows.length >= 20 && (
					<Typography
						variant="body2"
						sx={{
							fontSize: 12,
							textAlign: "center",
							marginTop: 1,
						}}
					>
						Max. 20 products at the same time
					</Typography>
				)}
			</Box>

			<BasicModal
				width={800}
				showModal={showLCARecommendations}
				handleClose={() => {
					setLCARecommendations(false);
				}}
				children={
					<LCARecommendations setLCARecommendations={setLCARecommendations} />
				}
				hideClose
			/>
		</div>
	);
}
