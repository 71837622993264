import React, { useContext } from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import {
	Certifications,
	CompanyProfile,
	ProductDetail,
	Products,
	SalesReport,
	UserProfile,
	CertificationProcess,
	ClimecoCertifications,
	ClimecoCertificationProcess,
	ClimecoCertificationProcesss,
	Companies,
	CompanyDetail,
	Documents,
	Dashboard,
	NotFoundPage,
	ExternalCertificationProcess,
	HistoricalCertificationProcess,
	LCAs,
} from "../components";
import { Users } from "../components/Users";
import { UserDetail } from "../components/UserDetail";
import { Notifications } from "../components/Notifications";
import { SalesReportDetail } from "../components/SalesReportDetail/SalesReportDetail";
import { UserContext } from "../contexts/user";
import { IUser } from "../models/user-model";
import { LCA } from "../components/LCA";

const ProtectedRoute = ({
	user,
	children,
}: {
	user: IUser | null;
	children: JSX.Element;
}): JSX.Element => {
	if (
		!user ||
		!user.company ||
		(user?.company?.requested_historically_approved &&
			!user?.company?.historically_approved)
	) {
		return <Navigate to="/" replace />;
	}

	return children;
};

const AdminProtectedRoute = ({
	user,
	children,
}: {
	user: IUser | null;
	children: JSX.Element;
}): JSX.Element => {
	if (!user || !user.is_superuser) {
		return <Navigate to="/" replace />;
	}

	return children;
};

export default function Routes() {
	const { user } = useContext(UserContext);

	return (
		<Switch>
			<Route path="/" element={<Dashboard />} />
			<Route
				path="/products"
				element={
					<ProtectedRoute user={user}>
						<Products />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/lcas"
				element={
					<ProtectedRoute user={user}>
						<LCAs />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/lca"
				element={
					<ProtectedRoute user={user}>
						<LCA />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/lcas/:id"
				element={
					<ProtectedRoute user={user}>
						<LCA />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/certifications"
				element={
					<ProtectedRoute user={user}>
						<Certifications />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/certifications/:id"
				element={
					<ProtectedRoute user={user}>
						<ExternalCertificationProcess />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/climeco-certifications"
				element={
					<ProtectedRoute user={user}>
						<ClimecoCertifications />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/certification"
				element={
					<ProtectedRoute user={user}>
						<CertificationProcess />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/climeco-certifications/:id"
				element={
					<ProtectedRoute user={user}>
						<ClimecoCertificationProcess />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/climeco-certificationss/:id"
				element={
					<ProtectedRoute user={user}>
						<ClimecoCertificationProcesss />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/historical-data/"
				element={
					<ProtectedRoute user={user}>
						<ClimecoCertifications />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/historical-data/:id"
				element={
					<ProtectedRoute user={user}>
						<HistoricalCertificationProcess />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/product/:id"
				element={
					<ProtectedRoute user={user}>
						<ProductDetail />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/company/:id"
				element={
					<AdminProtectedRoute user={user}>
						<CompanyDetail />
					</AdminProtectedRoute>
				}
			/>
			<Route
				path="/company"
				element={
					<AdminProtectedRoute user={user}>
						<CompanyDetail />
					</AdminProtectedRoute>
				}
			/>
			<Route
				path="/users"
				element={
					<ProtectedRoute user={user}>
						<Users />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/user"
				element={
					<ProtectedRoute user={user}>
						<UserDetail />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/user/:id"
				element={
					<ProtectedRoute user={user}>
						<UserDetail />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/sales"
				element={
					<ProtectedRoute user={user}>
						<SalesReport />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/sale"
				element={
					<ProtectedRoute user={user}>
						<SalesReportDetail />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/sales/:id"
				element={
					<ProtectedRoute user={user}>
						<SalesReportDetail />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/profile"
				element={
					<ProtectedRoute user={user}>
						<UserProfile />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/company-profile"
				element={
					<ProtectedRoute user={user}>
						<CompanyProfile />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/notifications"
				element={
					<ProtectedRoute user={user}>
						<Notifications />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/companies"
				element={
					<AdminProtectedRoute user={user}>
						<Companies />
					</AdminProtectedRoute>
				}
			/>
			<Route
				path="/documents"
				element={
					<AdminProtectedRoute user={user}>
						<Documents />
					</AdminProtectedRoute>
				}
			/>
			<Route path="*" element={<NotFoundPage />} />
			<Route path="/404" element={<NotFoundPage />} />
		</Switch>
	);
}
