import { useState, useEffect, useContext } from "react";
import { PropTypes } from "./SaleActionBar.types";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { ICompany } from "../../models";
import { company as companyActions } from "../../controllers";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";

const debounce = <F extends (...args: any[]) => void>(
	func: F,
	delay: number
): ((...args: Parameters<F>) => void) => {
	let timer: NodeJS.Timeout | null = null;
	return (...args: Parameters<F>): void => {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

export function SaleActionBar({
	selectedRows,
	refetch,
	filters,
	setFilters,
}: PropTypes) {
	const { user } = useContext(UserContext);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [companiesSearch, setCompaniesSearch] = useState("");

	const handleSearchChange = (value: string) => {
		setCompaniesSearch(value);
	};

	const debouncedSearchChange = debounce(handleSearchChange, 500);

	const { refetch: refetchCompanies, isLoading: isLoadingCompanies } = useQuery(
		"companies",
		() => companyActions.list(companiesSearch, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetchCompanies();
	}, [refetchCompanies, companiesSearch]);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flex: 1,
					alignItems: "center",
					justifyContent: "space-between",
					flexDirection: {
						xs: "column",
						sm: "column",
						md: "column",
						lg: "column-reverse",
					},
					gap: {
						xs: 2,
						sm: 2,
						md: 2,
						lg: 0,
					},
					marginTop: {
						xs: 2,
						sm: 2,
						md: 2,
						lg: 0,
					},
					width: "100%",
				}}
			>
				{user && user.is_superuser && (
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "column",
								md: "column",
								lg: "row",
							},
							flex: 1,
							alignItems: "center",
							justifyContent: "flex-start",
							marginTop: "9px",
							alignSelf: "flex-start",
							gap: {
								xs: 2,
								sm: 2,
								md: 2,
								lg: 0,
							},
						}}
					>
						<Autocomplete
							disablePortal
							id="company-box"
							options={companies}
							sx={{
								width: {
									xs: 240,
									sm: 240,
									md: 240,
									lg: 240,
									xl: 300,
								},
								"& .MuiInputBase-root": {
									padding: "0px 0px 0px 8px",
									overflow: "auto",
									maxHeight: "38px",
								},
							}}
							multiple
							getOptionLabel={(company) => company?.name ?? ""}
							/*value={filters.companies!.map((company) =>
								companies.find((auxCompany) => auxCompany.id === company)
							)}*/
							onChange={(_, value) => {
								setFilters((prev) => ({
									...prev,
									companies: value.map((company) => company!.id),
								}));
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									placeholder="Company"
									sx={{
										maxHeight: 56,
										overflow: "auto!important",
										border: "1px solid #ececec",
										borderRadius: 2,
										backgroundColor: "#fff",
										"& input": {
											fontSize: 14,
										},
									}}
									onChange={(ev) => {
										// dont fire API if the user delete or not entered anything
										if (ev.target.value !== "" || ev.target.value !== null) {
											debouncedSearchChange(ev.target.value);
										}
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{isLoadingCompanies ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</Box>
				)}
				<Box
					sx={{
						display: "flex",
						flex: 1,
						alignItems: "center",
						justifyContent: "flex-end",
						flexDirection: {
							xs: "row",
							sm: "row",
							md: "row",
							lg: "row",
						},
						gap: {
							xs: 0.5,
							sm: 0.5,
							md: 0.5,
							lg: 0.5,
						},
						marginTop: {
							xs: 0,
							sm: 0,
							md: 0,
							lg: 0,
						},
						width: "100%",
					}}
				></Box>
			</Box>
		</>
	);
}
