import { Box, Divider, TextField, Typography } from "@mui/material";
import { PropTypes } from "./HistoricalProductList.types";
import { NumericFormat } from "react-number-format";
import { ISale } from "../../models";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { DataContext } from "../../contexts/data";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
		color: "#888",
	},
});

export function HistoricalProductList({
	certification,
	salesByYearAndQuarter,
	setCertification,
}: PropTypes) {
	const classes = useStyles();
	const { setIsEditing } = useContext(DataContext);

	return (
		<Box>
			{certification?.estimations &&
				certification?.estimations
					.sort((a, b) => {
						const dateA = new Date(a.created_at ?? "").getTime();
						const dateB = new Date(b.created_at ?? "").getTime();

						return dateA - dateB;
					})
					.map((estimation, index) => {
						return (
							<Box
								key={estimation.id}
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "flex-start",
										flexDirection: {
											xs: "column",
											sm: "column",
											md: "row",
										},
										gap: 2,
										flex: 1,
									}}
								>
									<Typography
										flex={1}
										sx={{
											marginTop: "20px",
											flex: 1,
											maxWidth: 400,
										}}
									>
										{estimation.product.asin
											? estimation.product.name +
											  " - " +
											  estimation.product.asin
											: estimation.product.name}
									</Typography>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											width: "100%",
											flex: 1,
										}}
									>
										<span className={classes.inputLabel}>Emissions</span>
										<NumericFormat
											value={
												certification.estimations[index].lca_document
													?.emissions ?? 0
											}
											name={"emissions"}
											placeholder="kg of CO2e"
											customInput={TextField}
											style={{ background: "white" }}
											suffix={" Kg"}
											type="text"
											allowNegative={false}
											disabled={true}
											thousandSeparator={","}
											onValueChange={({ value: v }) => {}}
										/>
									</Box>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											gap: 1,
										}}
									>
										{Object.entries(salesByYearAndQuarter(estimation)).map(
											([year, quarters]) => (
												<Box
													key={year}
													sx={{
														display: "flex",
														flexDirection: "row",
														gap: 1,
													}}
												>
													{Object.entries(quarters)
														.sort((a, b) => {
															const quarterANumber = parseInt(
																a[0].substring(1, 2),
																10
															);
															const quarterBNumber = parseInt(
																b[0].substring(1, 2),
																10
															);
															return quarterANumber - quarterBNumber;
														})
														.map((quarter) => (
															<Box
																key={quarter[0]}
																sx={{
																	display: "flex",
																	flexDirection: "column",
																	gap: 1,
																}}
															>
																{(quarter[1] as ISale[]).map((sale, sIndex) => (
																	<Box
																		key={`${quarter[0]}-${sIndex}`}
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																		}}
																	>
																		<span className={classes.inputLabel}>
																			{`Q${Math.ceil(
																				(new Date(
																					sale.start_date
																				).getUTCMonth() +
																					1) /
																					3
																			)}-${new Date(
																				sale.start_date
																			).getUTCFullYear()}`}
																		</span>
																		<NumericFormat
																			value={sale.amount}
																			displayType={"input"}
																			thousandSeparator={true}
																			allowNegative={false}
																			decimalScale={0}
																			onValueChange={({
																				value: initialValue,
																			}) => {
																				setIsEditing(true);
																				const v =
																					!initialValue ||
																					isNaN(parseFloat(initialValue))
																						? 0
																						: parseFloat(initialValue);
																				let prevCertification = certification
																					? { ...certification }
																					: null;
																				if (!prevCertification) {
																					setCertification(null);
																				} else {
																					let updatedEstimations =
																						prevCertification.estimations.map(
																							(estimation, eIndex) => {
																								if (eIndex === index) {
																									const updatedSales =
																										estimation.product?.sales?.map(
																											(s) => {
																												if (s.id === sale.id) {
																													return {
																														...s,
																														amount: v,
																													};
																												}
																												return s;
																											}
																										) ?? [];
																									return {
																										...estimation,
																										product: {
																											...estimation.product,
																											sales: updatedSales,
																										},
																									};
																								}
																								return estimation;
																							}
																						);
																					setCertification({
																						...prevCertification,
																						estimations: updatedEstimations,
																					});
																				}
																			}}
																			customInput={TextField}
																			inputProps={{
																				style: {
																					border: "1px solid rgb(3, 176, 147)",
																					borderRadius: 4,
																					maxWidth: 50,
																				},
																			}}
																		/>
																	</Box>
																))}
															</Box>
														))}
												</Box>
											)
										)}
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												width: "100%",
												flex: 1,
											}}
										>
											<span className={classes.inputLabel}>
												Estimated Total Units Sold for 12 Months
											</span>
											<NumericFormat
												value={certification.estimations[index].amount}
												style={{
													border: "1px solid rgb(3, 176, 147)",
													borderRadius: 4,
												}}
												name={"amount"}
												placeholder="EEstimated Total Units Sold for 12 Months"
												customInput={TextField}
												type="text"
												allowNegative={false}
												decimalScale={0}
												autoFocus={index === 0}
												thousandSeparator={","}
												onValueChange={({ value: v }) => {
													setIsEditing(true);
													const auxCertification = {
														...certification,
													};
													auxCertification.estimations[index].amount = v
														? parseInt(v)
														: undefined;
													setCertification(auxCertification);
												}}
											/>
										</Box>
									</Box>

									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											textAlign: "right",
											paddingRight: "20px",
											"& input": {
												width: "60px",
												textAlign: "right",
											},
										}}
									>
										<span className={classes.inputLabel}>Offsets</span>
										<NumericFormat
											value={Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
												minimumFractionDigits: 0,
											}).format(
												Math.ceil(
													(certification.estimations[index].lca_document
														?.emissions ?? 0) *
														((certification.estimations[
															index
														].product?.sales?.reduce(
															(prev, current) => prev + current.amount,
															0
														) ?? 0) +
															(certification.estimations[index].amount ?? 0)) *
														0.001 ?? 0
												)
											)}
											name={"offsets"}
											placeholder="0"
											customInput={TextField}
											style={{
												background: "white",
											}}
											type="text"
											disabled={true}
											thousandSeparator={","}
											onValueChange={({ value: v }) => {}}
										/>
									</Box>
								</Box>
								<Divider
									sx={{
										marginTop: 2,
										marginBottom: 2,
										opacity: 0.5,
									}}
								/>
							</Box>
						);
					})}
		</Box>
	);
}
