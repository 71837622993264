import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PropTypes } from "./LCACollapsible.types";

const LCACollapsible = ({ title, defaultExpanded, children }: PropTypes) => {
	return (
		<Accordion
			sx={{
				boxShadow: "none",
				borderRadius: "8px",
				border: "1px solid #ececec",
			}}
			defaultExpanded={defaultExpanded}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel-content"
				id="panel-header"
				sx={{
					backgroundColor: "#fafafa",
					padding: "8px 16px",
					"&.Mui-expanded": {
						margin: "0px",
						minHeight: 0,
					},
					"& .MuiAccordionSummary-content.Mui-expanded": {
						margin: "0px 0",
					},
				}}
			>
				<Box sx={{ width: "100%" }}>{title}</Box>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default LCACollapsible;
