import { IToken } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"

export type LoginParameters = {
    email: string
    password: string
}

export type ResetParameters = {
    token: string
    password: string
}

export class Token {

    login = async ({ email, password }: LoginParameters): Promise<IToken> => {
        const response = await connectionsClient.client.post("token", {
            email: email.toLowerCase(),
            password
        });
        connectionsClient.updateTokens({ access: response.data.access, refresh: response.data.refresh, userId: null });

        return response.data;
    }

    startReset = async ({ email }: LoginParameters): Promise<boolean> => {
        const response = await connectionsClient.client.post("password_reset", {
            email
        });

        return response.data;
    }

    reset = async ({ token, password }: ResetParameters): Promise<boolean> => {
        const response = await connectionsClient.client.post("password_reset/confirm", {
            token,
            password
        });

        return response.data;
    }
}

export const token = new Token();
