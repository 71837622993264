import { useContext, useState, useEffect } from "react";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	TextField,
	Typography,
} from "@mui/material";
import {
	BasicModal,
	BulkProducts,
	CertificationAgree,
	CertificationConfirmation,
	CertificationConfirmationStep,
	HistoricalCertificationInfo,
	HistoricalProductList,
	MultiItemSelector,
	ProductSummary,
} from "..";
import {
	ICertification,
	IEstimation,
	IProduct,
	ISale,
	IUser,
} from "../../models";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	certification as certificationActions,
	estimation as estimationsActions,
	sale as saleActions,
	product as productActions,
	company as companyActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactHtmlParser from "react-html-parser";
import { PropTypes } from "./HistoricalCertificationSteps.types";
import { ConfirmationDataPropTypes } from "../CertificationConfirmation/CertificationConfirmation.types";
import { DataContext } from "../../contexts/data";

export function HistoricalCertificationSteps({
	currentStep,
	setCurrentStep,
	certification,
	setCertification,
	reloadCertification,
	setReloadCertification,
	refetchCertification,
}: PropTypes) {
	const location = useLocation();
	const [currentProducts, setCurrentProducts] = useState(
		location.state?.products
			? (location.state.products as Array<IProduct>)
			: Array<IProduct>()
	);
	const [originalEstimations, setOriginalEstimations] = useState(
		Array<IEstimation>()
	);
	const [productsFilter, setProductsFilter] = useState("");
	const [products, setProducts] = useState<Array<IProduct>>([]);
	const [loadingApproval, setLoadingApproval] = useState(false);
	const { user, setUser } = useContext(UserContext);
	const { setIsEditing } = useContext(DataContext);

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));
	const { enqueueSnackbar } = useSnackbar();
	const { mutate: deleteSalesMutate } = useMutation(
		"delete-sales",
		({ saleId }: { saleId: number }) =>
			saleActions.delete(saleId, certification?.company.id),
		{
			retry: false,
		}
	);

	const [showAgreeModal, setShowAgreeModal] = useState(false);

	const [showConfirmation, setShowConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] =
		useState<ConfirmationDataPropTypes>({
			title: "",
			description: "",
			textfield: null,
			callback: () => {},
		});

	const [showHistoricalInfo, setShowHistoricalInfo] = useState(false);
	const [userInformation, setUserInformation] = useState(
		certification?.user_information ?? ""
	);
	const [showUserInformation, setShowUserInformation] = useState(false);
	const [showFinishHistorical, setShowFinishHistorical] = useState(false);
	const [showCreateProductModal, setShowCreateProductModal] = useState(false);
	const [showBackModal, setShowBackModal] = useState(false);

	const navigate = useNavigate();

	const { refetch: refetchProducts } = useQuery(
		"products",
		() =>
			productActions.list(
				user?.is_superuser ? certification?.company.id : user?.company.id,
				productsFilter
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProducts(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);
	useEffect(() => {
		if (
			certification?.certification_process_steps[currentStep].step.step_type ===
				"CERTIFICATION_SUMMARY" &&
			!user?.is_superuser &&
			certification?.certification_process_steps[currentStep].status !==
				"REJECTED" &&
			!certification?.estimations.find((estimation) => estimation.rejected_date)
		) {
			const timer = setTimeout(() => {
				if (!certification.user_information) {
					setShowUserInformation(true);
				} else {
					setShowFinishHistorical(true);
				}
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [
		certification?.certification_process_steps,
		certification?.estimations,
		certification?.user_information,
		currentStep,
		setShowUserInformation,
		user,
	]);

	useEffect(() => {
		if (certification) {
			refetchProducts();
		}
	}, [certification, refetchProducts, productsFilter]);

	useEffect(() => {
		if (
			reloadCertification &&
			certification &&
			certification.certification_process_steps
		) {
			const rejectedStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "REJECTED"
			);

			const pendingStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "PENDING"
			);

			setCurrentStep(
				rejectedStep > -1
					? rejectedStep
					: pendingStep > -1
					? pendingStep
					: certification.certification_process_steps.length - 1
			);
			setReloadCertification(false);
		}
	}, [
		certification,
		reloadCertification,
		setCurrentStep,
		setReloadCertification,
	]);

	const { isLoading: isLoadingCreateSale, mutate: createSaleMutate } =
		useMutation(
			"historical-sales-create",
			({
				amount,
				emissions,
				startDate,
				endDate,
				productId,
				lcaDocument,
			}: {
				amount: number;
				emissions: number;
				startDate: string;
				endDate: string;
				productId: number;
				lcaDocument: number | undefined;
			}) =>
				saleActions.create(
					amount,
					emissions,
					startDate,
					endDate,
					productId,
					lcaDocument,
					user?.is_superuser ? certification?.company.id : user?.company.id
				),
			{
				retry: false,
			}
		);

	const getQuarter = (date: Date) => {
		return `Q${Math.floor(date.getUTCMonth() / 3) + 1}`;
	};

	const salesByYearAndQuarter = (
		estimation: IEstimation
	): Record<string, Record<string, ISale[]>> => {
		return (estimation.product?.sales ?? [])
			.filter((sale) => new Date(sale.end_date) <= new Date())
			.reduce((acc: any, sale) => {
				const year = new Date(sale.start_date).getUTCFullYear();
				const quarter = getQuarter(new Date(sale.start_date));

				if (!acc[year]) {
					acc[year] = {};
				}
				if (!acc[year][quarter]) {
					acc[year][quarter] = [];
				}
				acc[year][quarter].push(sale);

				return acc;
			}, {} as Record<string, Record<string, ISale[]>>);
	};

	const { isLoading: isLoadingUserInformation, mutate: mutateUserInformation } =
		useMutation(
			"patch-user-information",
			({ userInformation }: { userInformation: string }) =>
				certificationActions.updateUserInformation({
					isClimeCo: true,
					id: certification!.id,
					companyId: certification?.company!.id!,
					userInformation,
				}),
			{
				retry: false,
			}
		);

	const { isLoading: isLoadingToggleCompany, mutate: mutateToggleCompany } =
		useMutation(
			"toggle-company",
			() =>
				companyActions.update({
					id: user?.company!.id!,
					requested_historically_approved: new Date(),
				}),
			{
				retry: false,
				onSuccess: () => {
					const auxUser = { ...user };
					auxUser.company!.requested_historically_approved = new Date();
					setUser(auxUser as IUser);
					enqueueSnackbar(
						"Admins have been informed and will proceed to check your company.",
						{ variant: "success" }
					);
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong", { variant: "error" });
				},
			}
		);

	const {
		isLoading: isLoadingDeleteEstimation,
		mutate: deleteEstimationMutate,
	} = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser ? undefined : user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingCreateEstimation,
		mutate: createEstimationMutate,
	} = useMutation(
		"estimations",
		(estimation: IEstimation) =>
			estimationsActions.create({
				amount: estimation.amount,
				//emissions: estimation.emissions,
				product: estimation.product.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingApproval, mutate: mutateApproval } = useMutation(
		"certification-detail-approval",
		() =>
			certificationActions.toggleApproval({
				isClimeCo: true,
				id: certification?.id ?? -1,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
				setLoadingApproval(false);
				setShowAgreeModal(false);
				enqueueSnackbar(
					"Review requested. Now, waiting for Admin's approval.",
					{ variant: "success" }
				);
			},
			onError: (error: any) => {
				setLoadingApproval(false);
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const {
		isLoading: isLoadingUpdateEstimation,
		mutate: updateEstimationMutate,
	} = useMutation(
		"estimations-update",
		(estimation: IEstimation) =>
			estimationsActions.update({
				id: estimation.id,
				amount: estimation.amount,
				//emissions: estimation.emissions,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const confirmHistoricalData = (certification: ICertification) => {
		let index = 0;
		const updateEstimation = (
			id: number,
			amount: number,
			//emissions: number,
			product: IProduct,
			index: number
		) => {
			setLoadingApproval(true);
			updateEstimationMutate(
				{
					id: id,
					amount: amount,
					//emissions: emissions,
					product: product,
				},
				{
					onSuccess: () => {
						if (index < certification.estimations.length) {
							let saleIndex = 0;

							for (const sale of product.sales ?? []) {
								if (sale.id > 0) {
									deleteSalesMutate({
										saleId: sale.id,
									});
								}
							}

							const createSale = () => {
								createSaleMutate(
									{
										amount:
											certification?.estimations[index].product.sales![
												saleIndex
											].amount,
										emissions:
											certification?.estimations[index].lca_document
												?.emissions ?? 0,
										startDate:
											certification?.estimations[index].product.sales![
												saleIndex
											].start_date,
										endDate:
											certification?.estimations[index].product.sales![
												saleIndex
											].end_date,
										productId: product.id,
										lcaDocument:
											certification?.estimations[index].lca_document?.id,
									},
									{
										onSuccess: () => {
											saleIndex += 1;
											if (
												saleIndex <
													certification?.estimations[index]?.product?.sales!
														.length ??
												0
											) {
												createSale();
											} else {
												index += 1;
												if (index < certification.estimations.length) {
													updateEstimation(
														certification.estimations[index].id,
														certification.estimations[index].amount!,

														certification.estimations[index].product,
														index
													);
												} else {
													mutateApproval();
													setIsEditing(false);
													setShowConfirmation(false);
												}
											}
										},
										onError: (error) => {
											setLoadingApproval(false);
											console.log(error);
											enqueueSnackbar("Something went wrong.", {
												variant: "error",
											});
											setShowConfirmation(false);
										},
									}
								);
							};
							if (
								index - 1 < certification.estimations.length &&
								(certification?.estimations[index].product.sales ?? []).length >
									0
							) {
								createSale();
							} else {
								index += 1;
								if (index < certification.estimations.length) {
									updateEstimation(
										certification.estimations[index].id,
										certification.estimations[index].amount!,

										certification.estimations[index].product,
										index
									);
								} else {
									mutateApproval();
									setIsEditing(false);
									setShowConfirmation(false);
								}
							}
						} else {
							//refetchCertification();
						}
						setIsEditing(false);
						setShowConfirmation(false);
					},
					onError: (error) => {
						console.log(error);
						setLoadingApproval(false);
						enqueueSnackbar("Something went wrong.", {
							variant: "error",
						});
						setShowConfirmation(false);
					},
				}
			);
		};

		updateEstimation(
			certification.estimations[index].id,
			certification.estimations[index].amount!,
			//certification.estimations[index].emissions!,
			certification.estimations[index].product,
			index
		);
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: {
						xs: "calc(100% - 82px)",
						sm: "100%",
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box>
						<Typography
							variant="h5"
							sx={{
								fontWeight: 700,
								marginTop: !matches ? 0 : 4,
							}}
						>
							{
								certification?.certification_process_steps[currentStep].step
									.name
							}
						</Typography>
						<Typography
							sx={{ marginTop: 1, marginBottom: 4 }}
							variant="subtitle1"
						>
							{ReactHtmlParser(
								certification?.certification_process_steps[currentStep].step
									.description ?? ""
							)}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
						{certification?.certification_process_steps[currentStep].step
							.step_type === "CERTIFICATION_SUMMARY" &&
							certification.certification_process_steps[currentStep]
								.reject_reason && (
								<Button
									variant="outlined"
									onClick={() => {
										setCurrentStep(currentStep - 1);
										setOriginalEstimations(certification.estimations);
										setCurrentProducts(
											certification.estimations.map(
												(estimation) => estimation.product
											)
										);
									}}
									sx={{ marginRight: 2 }}
								>
									RESUBMIT
								</Button>
							)}

						{certification?.certification_process_steps[currentStep].step
							.step_type === "PRODUCT_LIST" && (
							<Button
								variant="outlined"
								onClick={() => {
									setShowBackModal(true);
								}}
								sx={{ marginRight: 0 }}
							>
								BACK
							</Button>
						)}
						{certification?.certification_process_steps[currentStep].step
							.button_title && (
							<Button
								variant="contained"
								sx={{
									minHeight: 34,
									display: "block",
								}}
								disabled={(() => {
									switch (
										certification?.certification_process_steps[currentStep].step
											.step_type
									) {
										case "CHOOSE_PRODUCTS": {
											return currentProducts.length === 0;
										}
										case "PRODUCT_LIST": {
											return certification.estimations.find(
												(estimation) =>
													estimation.product?.sales?.find(
														(sale) =>
															sale.amount === null || sale.amount === undefined
													) || estimation.amount === undefined
											)
												? true
												: false;
										}
										case "CERTIFICATION_SUMMARY": {
											return false;
										}
										default: {
											return false;
										}
									}
								})()}
								onClick={async () => {
									switch (
										certification?.certification_process_steps[currentStep].step
											.step_type
									) {
										case "CHOOSE_PRODUCTS": {
											let index = 0;

											for (const estimation of originalEstimations) {
												deleteEstimationMutate(estimation);
											}
											const createEstimation = (
												id: number,
												amount: number,
												//emissions: number,
												product: IProduct,
												index: number
											) => {
												createEstimationMutate(
													{
														id: id,
														amount: amount,
														//emissions: emissions,
														product: product,
													},
													{
														onSuccess: () => {
															index += 1;
															if (index < currentProducts.length) {
																createEstimation(
																	index,
																	0,
																	//0,
																	currentProducts[index],
																	index
																);
															} else {
																setIsEditing(false);
																refetchCertification();
															}
														},
														onError: (error) => {
															console.log(error);
															enqueueSnackbar("Something went wrong.", {
																variant: "error",
															});
														},
													}
												);
											};

											createEstimation(
												index,
												0,
												//0,
												currentProducts[index],
												index
											);
											break;
										}
										case "PRODUCT_LIST": {
											setShowAgreeModal(true);

											break;
										}
										case "OTHER": {
											navigate("/products");
											break;
										}
										default: {
											if (
												currentStep + 1 <
												certification.certification_process_steps.length
											) {
												setCurrentStep(currentStep + 1);
											}
										}
									}
								}}
							>
								{isLoadingCreateEstimation ||
								isLoadingUpdateEstimation ||
								isLoadingCreateSale ||
								isLoadingDeleteEstimation ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									certification?.certification_process_steps[currentStep].step
										.button_title
								)}
							</Button>
						)}
					</Box>
				</Box>

				{certification?.certification_process_steps[currentStep].step
					.step_type === "CHOOSE_PRODUCTS" && (
					<MultiItemSelector
						title="Select Product(s)"
						placeholderAutocomplete="Select Product(s)"
						buttonTitle="Add product(s)"
						listTitle="Product(s) Selected"
						emptyState="No products applied yet"
						setFilter={setProductsFilter}
						elements={products}
						selectedElements={currentProducts}
						setSelectedElements={(elements) =>
							setCurrentProducts(elements as Array<IProduct>)
						}
						setShowCreateProductModal={setShowCreateProductModal}
					/>
				)}

				{certification?.certification_process_steps[currentStep].step
					.step_type === "PRODUCT_LIST" && (
					<HistoricalProductList
						certification={certification}
						salesByYearAndQuarter={salesByYearAndQuarter}
						setCertification={setCertification}
					/>
				)}

				{certification?.certification_process_steps[currentStep].step
					.step_type === "CERTIFICATION_SUMMARY" && !user?.is_superuser ? (
					<CertificationConfirmationStep
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						setOriginalEstimations={setOriginalEstimations}
						certification={certification}
						setCurrentProducts={setCurrentProducts}
						setShowAgreeModal={setShowAgreeModal}
						text="By clicking the 'Agree' button, you are approving a ClimeCo admin to start a review of the provided data."
						infoAlertText="Review requested. Now, waiting for Admin's approval."
					/>
				) : null}
				{certification?.certification_process_steps[currentStep].step
					.step_type === "CERTIFICATION_SUMMARY" && (
					<Box>
						{certification.certification_process_steps[currentStep]
							.reject_reason && (
							<Alert severity="error" sx={{ marginBottom: 4 }}>
								Your certification has been rejected:{" "}
								{
									certification.certification_process_steps[currentStep]
										.reject_reason
								}
							</Alert>
						)}
						{certification.user_information && user?.is_superuser && (
							<Alert severity="warning" sx={{ marginBottom: 4 }}>
								Additional information from user:{" "}
								<b>{certification.user_information}</b>
							</Alert>
						)}
						{certification?.estimations && (
							<ProductSummary
								certification={certification}
								setCertification={setCertification}
								refetchCertification={refetchCertification}
								setShowConfirmation={setShowConfirmation}
								setConfirmationData={setConfirmationData}
								currentStep={currentStep}
								showSales={true}
								setShowHistoricalInfo={setShowHistoricalInfo}
								isHistorical={true}
								salesByYearAndQuarter={salesByYearAndQuarter}
							/>
						)}
					</Box>
				)}
			</Box>
			<BasicModal
				width={800}
				showModal={showBackModal}
				handleClose={() => {
					setShowBackModal(false);
				}}
				children={
					<Box>
						<Typography
							id="welcome-modal"
							variant="h6"
							component="h2"
							sx={{ textAlign: "center" }}
						>
							Need to edit your products?
						</Typography>
						<Typography id="welcome-modal" variant="body2" component="h2">
							<p>
								To add more products, finish this current process and add more
								once this process is complete.
							</p>
							<p>
								If you made errors when entering your product information, you
								can let us know at the end of the this process or email us at
								productcertification@climeco.com
							</p>
						</Typography>
					</Box>
				}
			/>

			<BasicModal
				width={800}
				showModal={showAgreeModal}
				handleClose={() => {
					setShowAgreeModal(false);
				}}
				children={
					certification && (
						<CertificationAgree
							text="By clicking 'AGREE' you are allowing a ClimeCo admin to start the review process."
							button1="Cancel"
							button1Callback={() => setShowAgreeModal(false)}
							button2="Agree"
							button2Callback={() => confirmHistoricalData(certification)}
							isLoading={isLoadingApproval || loadingApproval}
							historicalCertification={certification}
						/>
					)
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showConfirmation}
				handleClose={() => {
					setShowConfirmation(false);
				}}
				children={
					<CertificationConfirmation
						confirmationData={confirmationData}
						handleModalClose={() => {
							setShowConfirmation(false);
						}}
						isLoading={
							isLoadingCreateEstimation ||
							isLoadingUpdateEstimation ||
							isLoadingDeleteEstimation
						}
					/>
				}
				hideClose
			/>

			<BasicModal
				width={600}
				showModal={showHistoricalInfo}
				handleClose={() => {
					setShowHistoricalInfo(false);
				}}
				children={
					<HistoricalCertificationInfo
						title="Complete the company setup"
						subtitle={
							<>
								Remember to add the documents and turn off historical data
								toggle on their company page
							</>
						}
						buttonTitle="Ok"
						setShowHistoricalModal={setShowHistoricalInfo}
					/>
				}
				hideClose
			/>

			<BasicModal
				width={800}
				showModal={showCreateProductModal}
				handleClose={() => {
					setShowCreateProductModal(false);
				}}
				children={
					<BulkProducts
						inModal
						inModalCallback={(products) => {
							setShowCreateProductModal(false);
							refetchProducts();
							setCurrentProducts((elements) => [...elements, ...products]);
						}}
					/>
				}
			/>

			<BasicModal
				width={600}
				showModal={showFinishHistorical}
				handleClose={() => {
					setShowFinishHistorical(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							Have you added all your historical data?
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									navigate("/bulk-products", {
										state: {
											hideInformationModal: true,
										},
									});
								}}
							>
								Add more historical data
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
									minHeight: 34,
								}}
								onClick={() => {
									mutateToggleCompany();
								}}
							>
								{isLoadingToggleCompany ? (
									<CircularProgress size={12} />
								) : (
									"All historical data has been entered"
								)}
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={600}
				showModal={showUserInformation}
				handleClose={() => {
					setShowUserInformation(false);
					setShowFinishHistorical(true);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							If you entered any information incorrectly, please let us know
							here
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							<TextField
								placeholder="Enter any incorrect information here"
								sx={{
									display: "flex",
									marginTop: 2,
									minWidth: 200,
									flex: 1,
									width: "100%",
								}}
								value={userInformation}
								onChange={(event) => {
									setUserInformation(event.target.value);
								}}
								multiline
								rows={3}
							/>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									gap: 1,
									marginTop: 4,
								}}
							>
								<Button
									variant="outlined"
									sx={{
										maxWidth: 300,
									}}
									disabled={isLoadingUserInformation}
									onClick={() => {
										setShowUserInformation(false);
										setShowFinishHistorical(true);
									}}
								>
									Nothing so far
								</Button>
								<Button
									variant="contained"
									sx={{
										maxWidth: 300,
									}}
									disabled={isLoadingUserInformation}
									onClick={() => {
										mutateUserInformation(
											{ userInformation },
											{
												onSuccess: () => {
													setShowUserInformation(false);
													setShowFinishHistorical(true);
													enqueueSnackbar(
														"Thank you for your additional information.",
														{
															variant: "success",
														}
													);
												},
												onError: (error: any) => {
													console.log(error);
													setShowUserInformation(false);
													setShowFinishHistorical(true);
													enqueueSnackbar("Something went wrong", {
														variant: "error",
													});
												},
											}
										);
									}}
								>
									{isLoadingUserInformation ? (
										<CircularProgress size={12} />
									) : (
										"Done"
									)}
								</Button>
							</Box>
						</Box>
					</Box>
				}
			/>
		</>
	);
}
