import React, { useContext, useState } from "react";
import {
	Alert,
	Box,
	CircularProgress,
	Divider,
	Typography,
	Button,
	useMediaQuery,
	Select,
	MenuItem,
	SelectChangeEvent,
	TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PropTypes } from "./ProductSummary.types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { UserContext } from "../../contexts/user";
import { UploadFile } from "../UploadFile";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { IEstimation, IProductLCA, ISale } from "../../models";
import { useSnackbar } from "notistack";
import {
	estimation as estimationsActions,
	certificationProcessStep as certificationProcessStepActions,
	sale as saleActions,
	product as productActions,
} from "../../controllers";
import theme from "../../Theme";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
		color: "#888",
	},
});

export function ProductSummary({
	certification,
	setCertification,
	refetchCertification,
	setShowConfirmation,
	setConfirmationData,
	currentStep,
	showSales,
	setShowHistoricalInfo,
	isHistorical,
	salesByYearAndQuarter,
}: PropTypes) {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [documentLoading, setDocumentLoading] = useState(-1);
	const [approveLoading, setApproveLoading] = useState(-1);
	const [rejectLoading, setRejectLoading] = useState(-1);
	const [manualApproving, setManualApproving] = useState(false);
	const onlyHighestScreen = useMediaQuery(theme.breakpoints.up(1900));
	const onlyLargestScreen = useMediaQuery(theme.breakpoints.up(1450));
	const onlyLargeScreen = useMediaQuery(theme.breakpoints.up(1300));
	const onlyMediumScreen = useMediaQuery(theme.breakpoints.down(1200));
	const onlySmallScreen = useMediaQuery(theme.breakpoints.down(600));
	const classes = useStyles();

	const {
		isLoading: isLoadingDeleteEstimation,
		mutate: deleteEstimationMutate,
	} = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
			onSuccess: () => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	function generateQuarters() {
		const quarters = [];
		const currentYear = new Date().getUTCFullYear();
		const currentQuarter = Math.ceil((new Date().getUTCMonth() + 1) / 3);

		for (let year = currentYear - 2; year <= currentYear; year++) {
			for (let quarter = 1; quarter <= 4; quarter++) {
				if (year === currentYear && quarter > currentQuarter) {
					break;
				}
				quarters.push(`Q${quarter}-${year}`);
			}
		}

		return quarters;
	}

	const selectedQuarters = generateQuarters();

	function dateToQuarter(dateString: string): string {
		if (!dateString) return "";

		const date = new Date(dateString);
		const year = date.getUTCFullYear();
		const month = date.getUTCMonth() + 1;
		let quarter = Math.ceil(month / 3);

		return `Q${quarter}-${year}`;
	}

	const handleSelectChange = (event: SelectChangeEvent, index: number) => {
		const quarterValue = event.target.value;
		let dateValue = "";

		if (quarterValue) {
			const [q, year] = quarterValue.split("-");
			const quarterNumber = parseInt(q[1], 10);
			const month = ((quarterNumber - 1) * 3 + 1).toString().padStart(2, "0");
			dateValue = `${year}-${month}-01`;
		}

		const newCertification = { ...certification };
		if (!newCertification.estimations) {
			newCertification.estimations = [];
		}
		newCertification.estimations[index].overwrite_certification_date =
			dateValue;
		setCertification(newCertification);
	};

	const {
		isLoading: isLoadingUpdateCertificationStep,
		mutate: updateCertificationStepMutate,
	} = useMutation(
		"certification-detail",
		({
			status,
			rejectReason,
			manualApprove,
		}: {
			status: string;
			rejectReason?: string | undefined;
			manualApprove?: boolean;
		}) =>
			certificationProcessStepActions.update({
				id: certification!.certification_process_steps[currentStep].id,
				status: status,
				rejectReason: rejectReason,
				manualApprove: manualApprove,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingToggleEstimation, mutate: updateToggleMutate } =
		useMutation(
			"estimations-toggle",
			(estimation: IEstimation) =>
				estimationsActions.toggle({
					id: estimation.id,
					certificationProcessId: certification!.id,
					companyId: certification!.company.id,
					toggle: estimation.approved_date
						? "approve"
						: estimation.rejected_date
						? "reject"
						: "reset",
					reject_reason: estimation.reject_reason ?? null,
					overwrite_certification_date: showSales
						? undefined
						: estimation.overwrite_certification_date
						? estimation.overwrite_certification_date.split("T")[0]
						: undefined,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					refetchCertification();
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const {
		isLoading: isLoadingChecklistDocument,
		mutate: updateChecklistDocument,
	} = useMutation(
		"estimations-document",
		(estimation: IEstimation) =>
			estimationsActions.updateChecklist({
				id: estimation.id,
				certificationProcessId: certification!.id,
				companyId: certification!.company.id,
				checklistDocument: estimation.checklist_document as File,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				const auxCertification = { ...certification };
				let estimationIndex = auxCertification.estimations.findIndex(
					(estimation) => res.id === estimation.id
				);
				auxCertification.estimations[estimationIndex].checklist_document =
					res.checklist_document;
				setCertification(auxCertification);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingUpdateSale, mutate: updateSaleMutate } =
		useMutation(
			"historical-sales-update",
			({ id, amount }: { id: number; amount: number }) =>
				saleActions.update(id, amount),
			{
				retry: false,
			}
		);

	const {
		isLoading: isLoadingUpdateEstimation,
		mutate: updateEstimationMutate,
	} = useMutation(
		"historical-estimation-update",
		({ id, amount }: { id: number; amount: number }) =>
			estimationsActions.update({
				id,
				companyId: certification.company.id,
				amount,
				certificationProcess: certification.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingDocuments, mutate: mutateDocuments } =
		useMutation(
			"products-lca-update",
			({
				productLCAId,
				productLCA,
				productId,
			}: {
				productLCAId: number;
				productLCA: IProductLCA;
				productId: number;
			}) =>
				productActions.updateLCA({
					id: productLCAId,
					companyId: !user?.is_superuser ? user?.company.id : undefined,
					productId: productId,
					emissions: productLCA.emissions!,
					certification_date: productLCA.certification_date,
					lca_provider: "",
					lca_document: undefined,
				}),
			{
				retry: false,
			}
		);

	return (
		<>
			<Typography flex={1} sx={{ fontSize: 12, marginBottom: 1, marginTop: 4 }}>
				PRODUCT(S) SUMMARY
			</Typography>
			<Box>
				{certification?.estimations
					.sort(
						(a, b) =>
							new Date(a.created_at!).getTime() -
							new Date(b.created_at!).getTime()
					)
					.map((estimation, index) => {
						return (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
								key={estimation.id}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: isHistorical
											? onlyLargeScreen
												? "center"
												: "flex-start"
											: onlyLargestScreen
											? "center"
											: "flex-start",
										flexDirection: isHistorical
											? onlyLargeScreen
												? "row"
												: "column"
											: onlyLargestScreen
											? "row"
											: "column",
										justifyContent: "space-between",
										gap: 2,
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: onlyHighestScreen ? "row" : "column",
											flex: 1,
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												gap: 2,
												alignItems: "flex-start",
												marginBottom: onlyLargestScreen ? 0 : 2,
											}}
										>
											<Box>
												{estimation.approved_date ? (
													<CheckCircleIcon
														sx={{
															border: "1px solid #25406D",
															borderRadius: "50%",
															color: "#25406D",
														}}
													/>
												) : estimation.rejected_date ? (
													<CancelIcon
														sx={{
															border: "1px solid #EB4B4B",
															borderRadius: "50%",
															color: "#EB4B4B",
														}}
													/>
												) : (
													<WatchLaterIcon
														sx={{
															border: "1px solid #f1c40f",
															borderRadius: "50%",
															color: "#f1c40f",
														}}
													/>
												)}
											</Box>
											<Typography
												sx={{
													flex: 1,
													width: 300,
													wordWrap: "break-word",
												}}
											>
												{estimation.product.asin
													? estimation.product.name +
													  " - " +
													  estimation.product.asin
													: estimation.product.name}
											</Typography>
										</Box>
										<Box
											sx={{
												display: "flex",
												gap: { xs: 2, sm: 2, md: 0 },
												overflow: "hidden",
												justifyContent: "flex-end",
												width: "100%",
												flexDirection: onlyMediumScreen ? "column" : "row",
											}}
										>
											<Typography
												sx={{
													marginLeft: 2,
													textOverflow: "ellipsis",
													overflow: "hidden",
													width: 150,
													flex: { xs: 1, sm: 1, md: "unset" },
													textAlign: onlyLargeScreen ? "right" : "left",
													fontSize: 14,
													whiteSpace: "nowrap",
													marginBottom: onlyLargestScreen ? 0 : 1,
												}}
											>
												<a
													href={
														certification.estimations[
															index
														].lca_document?.lca_document?.toString() ?? "#"
													}
													style={{ whiteSpace: "nowrap" }}
													target="_blank"
													rel="noreferrer"
													download
												>
													...
													{certification.estimations[
														index
													].lca_document?.lca_document?.toString()
														? "..." +
														  certification.estimations[index]
																.lca_document!.lca_document!.toString()
																.split("/")
																[
																	certification.estimations[index]
																		.lca_document!.lca_document!.toString()
																		.split("/").length - 1
																].slice(-15)
														: "--"}
												</a>
											</Typography>
											<Box
												sx={{
													display: "flex",
												}}
											>
												{isHistorical && user?.is_superuser ? (
													<Box
														sx={{
															alignItems: onlyLargeScreen
																? "flex-end"
																: "flex-start",
															marginBottom: onlyLargeScreen ? 0 : 1,
															textAlign: onlyLargeScreen ? "right" : "left",
															marginRight: 0,
															marginLeft: 2,
															display: "flex",
															flexDirection: "column",
															maxHeight: 77,
														}}
													>
														<span className={classes.inputLabel}>
															Product Carbon Footprint Value
														</span>
														<NumericFormat
															name={"amount"}
															placeholder="Product Carbon Footprint Value"
															customInput={TextField}
															type="text"
															thousandSeparator={","}
															suffix={" kg of CO2e"}
															allowNegative={false}
															onFocus={(e) => {}}
															onBlur={() => {}}
															value={
																certification.estimations[index].lca_document
																	?.emissions ?? 0
															}
															onValueChange={({ floatValue }) => {
																const auxCertification = {
																	...certification,
																};
																const auxEstimation =
																	auxCertification.estimations.find(
																		(e) => e.id === estimation.id
																	);
																if (
																	auxEstimation &&
																	auxEstimation.lca_document
																) {
																	auxEstimation.lca_document.emissions =
																		floatValue ?? 0;
																}

																setCertification(auxCertification);
															}}
															style={{
																flex: 1,
																minWidth: "140px",
															}}
														/>
													</Box>
												) : (
													<Typography
														sx={{
															marginLeft: 2,
															textOverflow: "ellipsis",
															overflow: "hidden",
															width: 150,
															flex: { xs: 1, sm: 1, md: "unset" },
															textAlign: onlyLargeScreen ? "right" : "left",
														}}
													>
														{Intl.NumberFormat("en", {
															maximumFractionDigits: 4,
														}).format(
															certification.estimations[index].lca_document
																?.emissions ?? 0
														)}{" "}
														kg of CO2e
													</Typography>
												)}
												{certification.estimations[index]?.lca_document
													?.certification_date && (
													<Box
														sx={{
															alignItems: onlyLargeScreen
																? "flex-end"
																: "flex-start",
															marginBottom: onlyLargeScreen ? 0 : 1,
															textAlign: onlyLargeScreen ? "right" : "left",
															marginRight: 0,
															marginLeft: 2,
															display: "flex",
															flexDirection: "column",
															maxHeight: 77,
														}}
													>
														<span className={classes.inputLabel}>
															LCA Completion Date
														</span>
														{isHistorical && user?.is_superuser ? (
															<TextField
																placeholder={"LCA Completion Date"}
																type="date"
																variant="outlined"
																InputProps={{
																	inputProps: {
																		max: `${
																			new Date().toISOString().split("T")[0]
																		}`,
																		style: { minWidth: "150px" },
																	},
																}}
																sx={{
																	flex: 1,
																	minWidth: "150px",
																}}
																value={
																	estimation.lca_document?.certification_date.split(
																		"T"
																	)[0]
																}
																onChange={(event) => {
																	const isValidDate = (
																		dateString: string
																	): boolean => {
																		return !isNaN(Date.parse(dateString));
																	};

																	const auxCertification = {
																		...certification,
																	};
																	const auxEstimation =
																		auxCertification.estimations.find(
																			(e) => e.id === estimation.id
																		);
																	if (
																		auxEstimation &&
																		auxEstimation.lca_document &&
																		isValidDate(event.target.value)
																	) {
																		auxEstimation.lca_document.certification_date =
																			event.target.value;
																	}

																	setCertification(auxCertification);
																}}
															/>
														) : (
															<Typography
																sx={{
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																	width: 150,
																	textAlign: onlyLargeScreen ? "right" : "left",
																}}
															>
																{new Intl.DateTimeFormat("en", {
																	day: "2-digit",
																	month: "2-digit",
																	year: "numeric",
																}).format(
																	new Date(
																		certification.estimations[index]
																			?.lca_document?.certification_date ?? ""
																	)
																)}
															</Typography>
														)}
													</Box>
												)}
											</Box>
											<Box sx={{ display: "flex" }}>
												{isHistorical && salesByYearAndQuarter && (
													<Box
														sx={{
															marginRight: 3,
															marginLeft: onlyLargeScreen ? 4 : 2,
															marginTop: onlyLargeScreen ? 1 : 1,
															marginBottom: onlyLargeScreen ? 0 : 2,
														}}
													>
														{Object.entries(
															salesByYearAndQuarter(estimation)
														).map(([year, quarters]) => (
															<Box
																key={year}
																sx={{
																	display: "flex",
																	flexDirection: "row",
																	gap: 1,
																}}
															>
																{Object.entries(quarters)
																	.sort((a, b) => {
																		const quarterANumber = parseInt(
																			a[0].substring(1, 2),
																			10
																		);
																		const quarterBNumber = parseInt(
																			b[0].substring(1, 2),
																			10
																		);
																		return quarterANumber - quarterBNumber;
																	})
																	.map((quarter) => (
																		<Box
																			key={quarter[0]}
																			sx={{
																				display: "flex",
																				flexDirection: "column",
																				gap: 1,
																			}}
																		>
																			{(quarter[1] as ISale[]).map(
																				(sale, sIndex) => (
																					<Box
																						key={`${quarter[0]}-${sIndex}`}
																						sx={{
																							display: "flex",
																							flexDirection: "column",
																						}}
																					>
																						<span
																							style={{
																								marginTop: 0,
																							}}
																							className={classes.inputLabel}
																						>
																							{`Q${Math.ceil(
																								(new Date(
																									sale.start_date
																								).getUTCMonth() +
																									1) /
																									3
																							)}-${new Date(
																								sale.start_date
																							).getUTCFullYear()}`}
																						</span>
																						<NumericFormat
																							value={sale.amount}
																							displayType={"input"}
																							thousandSeparator={true}
																							allowNegative={false}
																							decimalScale={0}
																							customInput={TextField}
																							inputProps={{
																								style: { maxWidth: 50 },
																							}}
																							onValueChange={(values) => {
																								const auxCertification = {
																									...certification,
																								};
																								const auxEstimation =
																									auxCertification.estimations.find(
																										(e) =>
																											e.id === estimation.id
																									);
																								if (
																									auxEstimation &&
																									auxEstimation.product?.sales
																								) {
																									const auxSale =
																										auxEstimation.product.sales.find(
																											(s) => s.id === sale.id
																										);
																									if (auxSale) {
																										auxSale.amount =
																											values.floatValue ?? 0;
																									}
																								}

																								setCertification(
																									auxCertification
																								);
																							}}
																							disabled={!user?.is_superuser}
																						/>
																					</Box>
																				)
																			)}
																		</Box>
																	))}
															</Box>
														))}
													</Box>
												)}
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
													}}
												>
													{showSales &&
														(user?.is_superuser ? (
															<>
																<span className={classes.inputLabel}>
																	Historical Sales
																</span>
																<Box sx={{ maxWidth: 100 }}>
																	<NumericFormat
																		value={(
																			estimation.product.sales ??
																			([] as ISale[])
																		).reduce(
																			(prev: number, current: ISale) =>
																				prev + current.amount,
																			0
																		)}
																		displayType={"input"}
																		thousandSeparator={true}
																		allowNegative={false}
																		decimalScale={0}
																		customInput={TextField}
																		inputProps={{
																			style: { maxWidth: 70 },
																		}}
																		disabled
																	/>
																</Box>
															</>
														) : (
															<Typography
																sx={{
																	marginLeft: 2,
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																	width: 150,
																	flex: { xs: 1, sm: 1, md: "unset" },
																	textAlign: onlyLargeScreen ? "right" : "left",
																}}
															>
																{Intl.NumberFormat("en", {
																	maximumFractionDigits: 4,
																}).format(
																	(
																		estimation.product.sales ?? ([] as ISale[])
																	).reduce(
																		(prev: number, current: ISale) =>
																			prev + current.amount,
																		0
																	)
																)}{" "}
																<small>historical sales</small>
															</Typography>
														))}
													{user?.is_superuser && isHistorical ? (
														<>
															<span className={classes.inputLabel}>
																Estimated Sales
															</span>
															<Box sx={{ maxWidth: 100 }}>
																<NumericFormat
																	value={estimation.amount}
																	displayType={"input"}
																	thousandSeparator={true}
																	allowNegative={false}
																	decimalScale={0}
																	customInput={TextField}
																	inputProps={{
																		style: { maxWidth: 70 },
																	}}
																	onValueChange={(values) => {
																		const auxCertification = {
																			...certification,
																		};
																		const auxEstimation =
																			auxCertification.estimations.find(
																				(e) => e.id === estimation.id
																			);
																		if (auxEstimation) {
																			auxEstimation.amount = values.floatValue;
																		}

																		setCertification(auxCertification);
																	}}
																/>
															</Box>
														</>
													) : (
														<Typography
															sx={{
																marginLeft: 2,
																textOverflow: "ellipsis",
																overflow: "hidden",
																width: 150,
																flex: { xs: 1, sm: 1, md: "unset" },
																textAlign: onlyLargeScreen ? "right" : "left",
															}}
														>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 4,
															}).format(
																estimation.amount && !isNaN(estimation.amount)
																	? estimation.amount
																	: 0
															)}{" "}
															<small>estimated sales</small>
														</Typography>
													)}
												</Box>
											</Box>
										</Box>
									</Box>

									<Box
										sx={{
											display: "flex",
											flexDirection: onlySmallScreen ? "column" : "row",
											alignItems: "center",
										}}
									>
										{user?.is_superuser && !showSales && (
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													marginLeft: 2,
												}}
											>
												<Typography
													sx={{
														textTransform: "uppercase",
														fontSize: 10,
														fontWeight: 700,
														color: "#888",
													}}
												>
													CHECKLIST DOCUMENT
												</Typography>
												<UploadFile
													padding={1}
													accept=".pdf,.csv,.xlsx,.xls,.doc,.docx,.numbers,.txt"
													value={estimation.checklist_document ?? null}
													onFileChange={(file) => {
														if (file) {
															setDocumentLoading(index);
															updateChecklistDocument({
																...estimation,
																checklist_document: file,
															});
														} else {
															enqueueSnackbar(
																"File could not be uploaded. Please, try it again or reload the page",
																{
																	variant: "error",
																}
															);
														}
													}}
													loading={
														isLoadingChecklistDocument &&
														documentLoading === index
													}
												/>
												<Typography
													sx={{
														textTransform: "uppercase",
														fontSize: 10,
														fontWeight: 700,
														color: "#888",
														marginTop: 2,
													}}
												>
													COMPLETION DATE (OPTIONAL)
												</Typography>
												<Box sx={{ display: "flex", flex: 1 }}>
													<Select
														variant="outlined"
														value={
															dateToQuarter(
																estimation.overwrite_certification_date || ""
															) || ""
														}
														onChange={(event) =>
															handleSelectChange(event, index)
														}
														displayEmpty
														sx={{
															flex: 1,
															backgroundColor: "#fafafa",
															"& .MuiSelect-select": {
																color: estimation.overwrite_certification_date
																	? "rgba(0, 0, 0, 0.87)"
																	: "rgba(0, 0, 0, 0.54)",
															},
															"& fieldset": {
																border: "none",
															},
														}}
														renderValue={(selected) => {
															if (selected === "") {
																return (
																	<p style={{ padding: 0, margin: 0 }}>
																		Select Certification Quarter
																	</p>
																);
															}
															return selected;
														}}
														disabled={!estimation.checklist_document}
													>
														<MenuItem value="" disabled>
															Select Certification Quarter
														</MenuItem>
														{selectedQuarters.map((quarter) => (
															<MenuItem key={quarter} value={quarter}>
																{quarter}
															</MenuItem>
														))}
													</Select>
												</Box>
											</Box>
										)}
										{/*buttons*/}
										{user?.is_superuser && (
											<Box
												sx={{
													display: "flex",
													flexDirection: isHistorical
														? onlyLargeScreen
															? "column"
															: "row"
														: onlyMediumScreen
														? "row"
														: "column",
													gap: 2,
													overflow: "hidden",
													marginTop: onlySmallScreen ? 2 : 0,
													marginLeft: 2,
												}}
											>
												<Button
													variant="contained"
													size="small"
													disabled={
														(!showSales && !estimation.checklist_document) ||
														isLoadingToggleEstimation ||
														(estimation.approved_date ? true : false)
													}
													sx={{ minHeight: "29px" }}
													onClick={() => {
														setShowConfirmation(true);
														setConfirmationData({
															title: "Do you want to approve this product?",
															description:
																"Once you confirm, the product will be approved",
															textfield: null,
															callback: async () => {
																setApproveLoading(index);
																setShowConfirmation(false);

																if (certification.is_historical) {
																	for (const auxEstimation of certification.estimations) {
																		if (auxEstimation) {
																			await new Promise((resolve, reject) => {
																				mutateDocuments(
																					{
																						productId: auxEstimation.product.id,
																						productLCA:
																							auxEstimation.lca_document!,
																						productLCAId:
																							auxEstimation.lca_document!.id,
																					},
																					{
																						onSuccess: () => {
																							resolve(true);
																						},
																						onError: (error) => {
																							console.log(error);
																							enqueueSnackbar(
																								"Something went wrong.",
																								{
																									variant: "error",
																								}
																							);
																							reject();
																						},
																					}
																				);
																			});

																			await new Promise((resolve, reject) => {
																				updateEstimationMutate(
																					{
																						id: auxEstimation.id,
																						amount: auxEstimation.amount ?? 0,
																					},
																					{
																						onSuccess: () => {
																							resolve(true);
																						},
																						onError: (error: any) => {
																							console.log(error);
																							enqueueSnackbar(
																								"Something went wrong.",
																								{
																									variant: "error",
																								}
																							);
																							reject();
																						},
																					}
																				);
																			});
																		}

																		for (const auxSale of auxEstimation.product
																			?.sales ?? []) {
																			await new Promise((resolve, reject) => {
																				updateSaleMutate(
																					{
																						id: auxSale.id,
																						amount: auxSale.amount,
																					},
																					{
																						onSuccess: () => {
																							resolve(true);
																						},
																						onError: (error: any) => {
																							console.log(error);
																							enqueueSnackbar(
																								"Something went wrong.",
																								{
																									variant: "error",
																								}
																							);
																							reject();
																						},
																					}
																				);
																			});
																		}
																	}
																}

																updateToggleMutate({
																	...estimation,
																	approved_date: new Date().toISOString(),
																	rejected_date: null,
																	reject_reason: null,
																	overwrite_certification_date:
																		estimation.overwrite_certification_date,
																});
															},
														});
													}}
												>
													{(isLoadingToggleEstimation ||
														isLoadingUpdateEstimation ||
														isLoadingUpdateSale ||
														isLoadingDocuments) &&
													approveLoading === index ? (
														<CircularProgress
															size={12}
															sx={{ color: "white" }}
														/>
													) : (
														"Approve"
													)}
												</Button>
												<Button
													variant="outlined"
													size="small"
													disabled={
														(!showSales && !estimation.checklist_document) ||
														isLoadingToggleEstimation ||
														(estimation.rejected_date ? true : false)
													}
													sx={{
														minHeight: "29px",
														"&.Mui-disabled": {
															color: "#25406D",
															background: "white",
														},
													}}
													onClick={() => {
														setShowConfirmation(true);
														setConfirmationData({
															title: "Do you want to reject this product?",
															description: "The product will be rejected",
															textfield: "Rejected reason",
															callback: (rejectReason) => {
																setRejectLoading(index);
																updateToggleMutate({
																	...estimation,
																	approved_date: null,
																	rejected_date: new Date().toISOString(),
																	reject_reason: rejectReason,
																});
																setShowConfirmation(false);
															},
														});
													}}
												>
													{isLoadingToggleEstimation &&
													rejectLoading === index ? (
														<CircularProgress
															size={12}
															sx={{ color: "white" }}
														/>
													) : (
														"Reject"
													)}
												</Button>
												{user?.is_superuser && (
													<>
														<Button
															variant="outlined"
															size="small"
															sx={{
																minHeight: "29px",
																"&.Mui-disabled": {
																	color: "#25406D",
																	background: "white",
																},
															}}
															onClick={() => {
																setShowConfirmation(true);

																setConfirmationData({
																	title:
																		"Do you want to remove this product from this certification process?",
																	description:
																		"The product will be remove from this certification",
																	textfield: "",
																	callback: () => {
																		deleteEstimationMutate(estimation);
																		setShowConfirmation(false);
																	},
																});
															}}
														>
															{isLoadingDeleteEstimation ? (
																<CircularProgress
																	size={12}
																	sx={{ color: "white" }}
																/>
															) : (
																"Remove"
															)}
														</Button>
													</>
												)}
											</Box>
										)}
									</Box>
								</Box>
								{estimation.reject_reason && (
									<Alert
										severity="error"
										sx={{ marginTop: 2, marginBottom: 2 }}
									>
										{estimation.reject_reason}
										{" -> "}
										<span
											style={{
												textDecoration: "underline",
												cursor: "pointer",
											}}
											onClick={() =>
												navigate(`/product/${estimation.product.id}`)
											}
										>
											Go to product
										</span>
									</Alert>
								)}
								<Divider
									sx={{
										marginTop: 2,
										marginBottom: 2,
										opacity: 0.5,
									}}
								/>
							</Box>
						);
					})}
			</Box>

			{user &&
				user.is_superuser &&
				!certification.certification_process_steps[currentStep]
					.reject_reason && (
					<Box
						sx={{
							display: "flex",
							marginTop: 4,
							justifyContent: "flex-end",
						}}
					>
						{certification?.certification_process_steps[currentStep].step
							.step_type === "CERTIFICATION_SUMMARY" &&
							user?.is_superuser &&
							!certification.company.first_time_fee_paid &&
							!certification?.requested_approval &&
							Math.ceil(
								certification?.estimations.reduce((prev, current) => {
									return (
										prev +
										Math.ceil(
											(current.lca_document?.emissions ?? 0) *
												(!isNaN(current.amount ?? 0) && current.amount
													? current.amount
													: 0) *
												0.001
										)
									);
								}, 0) ?? 0
							) *
								12.5 +
								certification?.estimations.length * 250 +
								1000 <
								5000 && (
								<Typography sx={{ marginRight: 2 }}>
									<small
										style={{
											color: "#EB4B4B",
											fontWeight: "bold",
										}}
									>
										User hasn't agreed on requesting approval yet
									</small>
								</Typography>
							)}
						{!showSales && (
							<Button
								variant="contained"
								sx={{ minHeight: 34 }}
								disabled={
									isLoadingUpdateCertificationStep ||
									certification.estimations.length === 0 ||
									certification.estimations.find(
										(estimation) => !estimation.approved_date
									)
										? true
										: false
								}
								onClick={() => {
									setShowConfirmation(true);
									setManualApproving(false);
									setConfirmationData({
										title: "Do you want to approve this certification?",
										description:
											"Once you confirm, the certification will be approved",
										textfield: null,
										callback: () => {
											updateCertificationStepMutate({
												status: "COMPLETED",
											});
											setShowConfirmation(false);
										},
									});
								}}
							>
								{(isLoadingUpdateCertificationStep ||
									isLoadingUpdateSale ||
									isLoadingUpdateEstimation) &&
								!manualApproving ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									"Approve"
								)}
							</Button>
						)}
						<Button
							variant="outlined"
							sx={{
								minHeight: 34,
								minWidth: 141,
								marginLeft: 2,
								"&.Mui-disabled": {
									color: "#25406D",
									background: "white",
								},
							}}
							disabled={
								isLoadingUpdateCertificationStep ||
								certification.estimations.length === 0 ||
								certification.estimations.find(
									(estimation) => !estimation.approved_date
								)
									? true
									: false
							}
							onClick={() => {
								setShowConfirmation(true);
								setManualApproving(true);
								setConfirmationData({
									title: "Do you want to manually approve this certification?",
									description:
										"Once you confirm, the certification will be approved",
									textfield: null,
									callback: () => {
										updateCertificationStepMutate({
											status: "COMPLETED",
											rejectReason: undefined,
											manualApprove: true,
										});
										setShowConfirmation(false);

										if (!user.is_superuser && setShowHistoricalInfo) {
											setShowHistoricalInfo(true);
										}
									},
								});
							}}
						>
							{isLoadingUpdateCertificationStep && manualApproving ? (
								<CircularProgress size={12} sx={{ color: "#25406D" }} />
							) : (
								"Manual Approve"
							)}
						</Button>
					</Box>
				)}
		</>
	);
}
