import { useState, useMemo } from "react";
import {
	Card,
	CardContent,
	Box,
	CircularProgress,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	TableSortLabel,
} from "@mui/material";
import { PropTypes } from "./CompanySteps.types";
import { DashboardTypes } from "../../controllers/dashboard/dashboard.types";

type CompanyStepKeys = keyof DashboardTypes.CompanySteps;

const headers: CompanyStepKeys[] = [
	"SIGN_UP",
	"COMPANY_CREATION",
	"FIRST_LCA_CREATED",
	"FIRST_PRODUCT_CREATED",
	"FIRST_CERTIFICATION_REQUESTED_APPROVAL",
	"FIRST_CERTIFICATION_FINISHED",
	/*"NUMBER_OF_HISTORICAL_CERTIFICATIONS_STARTED",
	"FIRST_HISTORICAL_CERTIFICATION_STARTED",
	"FIRST_HISTORICAL_CERTIFICATION_STEP",
	"CURRENT_HISTORICAL_CERTIFICATION_STARTED",
	"CURRENT_HISTORICAL_CERTIFICATION_STEP",
	"FIRST_HISTORICAL_CERTIFICATION_COMPLETED",
	"CURRENT_HISTORICAL_CERTIFICATION_COMPLETED",
	"REQUESTED_HISTORICAL_APPROVAL",
	"HISTORICALLY_APPROVED",*/
];

const getValue = (
	companySteps: DashboardTypes.CompanySteps,
	header: CompanyStepKeys,
	companyId: number
) => {
	const stepData = companySteps[header].find((item) => item.id === companyId);
	if (!stepData) return null;
	if (stepData.date) return new Date(stepData.date).getTime();
	if (stepData.count !== undefined) return stepData.count;
	if (stepData.step) return stepData.step;
	return null;
};

const compareValues = (valueA: any, valueB: any, order: "asc" | "desc") => {
	if (valueA === null && valueB !== null) return 1;
	if (valueA !== null && valueB === null) return -1;
	if (valueA === null && valueB === null) return 0;

	if (typeof valueA === "number" && typeof valueB === "number") {
		return order === "asc" ? valueA - valueB : valueB - valueA;
	}

	if (typeof valueA === "string" && typeof valueB === "string") {
		return order === "asc"
			? valueA.localeCompare(valueB)
			: valueB.localeCompare(valueA);
	}

	return 0;
};

export function CompanySteps({ companySteps }: PropTypes) {
	const [order, setOrder] = useState<"asc" | "desc">("asc");
	const [orderBy, setOrderBy] = useState<CompanyStepKeys | null>(null);

	const handleSortRequest = (property: CompanyStepKeys) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const sortedCompanies = useMemo(() => {
		if (!companySteps || !orderBy)
			return companySteps ? companySteps.SIGN_UP : [];

		return [...companySteps.SIGN_UP].sort((a, b) => {
			const valueA = getValue(companySteps, orderBy, a.id);
			const valueB = getValue(companySteps, orderBy, b.id);
			return compareValues(valueA, valueB, order);
		});
	}, [companySteps, order, orderBy]);

	if (!companySteps) {
		return <CircularProgress size={24} />;
	}

	return (
		<Card
			variant="outlined"
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				cursor: "pointer",
				overflow: "auto",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flexGrow: 1,
				}}
			>
				<CardContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						{!companySteps ? (
							<CircularProgress size={24} />
						) : (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Company Name</TableCell>
										{headers.map((header) => (
											<TableCell
												key={header}
												sx={{ textTransform: "capitalize" }}
											>
												<TableSortLabel
													active={orderBy === header}
													direction={orderBy === header ? order : "asc"}
													onClick={() => handleSortRequest(header)}
												>
													{header.replaceAll("_", " ").toLowerCase()}
												</TableSortLabel>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{sortedCompanies.map((company) => (
										<TableRow key={company.id}>
											<TableCell>
												<Typography>{company.name}</Typography>
											</TableCell>
											{headers.map((header) => {
												const stepData = companySteps[header].find(
													(item) => item.id === company.id
												);
												return (
													<TableCell key={header}>
														{stepData?.count !== undefined && (
															<Typography>{stepData.count}</Typography>
														)}
														{stepData?.step && (
															<Typography>{stepData.step}</Typography>
														)}
														{stepData?.date && (
															<Typography>
																{Intl.DateTimeFormat("en", {
																	day: "numeric",
																	month: "numeric",
																	year: "numeric",
																	hour: "2-digit",
																	minute: "2-digit",
																	second: "2-digit",
																}).format(new Date(stepData.date))}
															</Typography>
														)}
													</TableCell>
												);
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						)}
					</Box>
				</CardContent>
			</Box>
		</Card>
	);
}
