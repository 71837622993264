import { useState, useEffect, useContext } from "react";
import { Typography, Box, Grid, CircularProgress, Button } from "@mui/material";
import { PropTypes } from "./Dashboard.types";
import { Heading } from "../Heading";
import { Updates } from "../Updates";
import { ServiceCard } from "../ServiceCard";
import { useMutation, useQuery } from "react-query";
import {
	service as serviceActions,
	notification as notificationActions,
	product as productActions,
	certification as certificationActions,
	company as companyActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { INotification, IService, IUser } from "../../models";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

//import { CompanyAnalytics } from "../CompanyAnalytics";
import { AdminAnalytics } from "../AdminAnalytics";
import { BasicModal } from "../BasicModal";
import ConfettiExplosion from "react-confetti-explosion";

export function Dashboard(props: PropTypes) {
	const { user, setUser } = useContext(UserContext);
	const [services, setServices] = useState<IService[] | null>(null);
	const [notifications, setNotifications] = useState<INotification[] | null>(
		null
	);
	const [productsLength, setProductsLength] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [
		hasCompletedClimecoCertification,
		setHasCompletedClimecoCertification,
	] = useState<boolean | null>(null);
	const [hasAmazonCertificationTodo, setHasAmazonCertificationTodo] = useState<
		boolean | null
	>(null);
	const [showHistoricallyApprovedModal, setShowHistoricallyApprovedModal] =
		useState(false);
	/*const [showWaitingConfirmationModal, setShowWaitingConfirmationModal] =
		useState(false);*/
	const [showIframe, setShowIframe] = useState(false);
	const { refetch: refetchServices } = useQuery(
		"services",
		() => serviceActions.list(),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setServices(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { isLoading, refetch: refetchNotifications } = useQuery(
		"notifications",
		() => notificationActions.list(5, user?.company.id, undefined, true),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setNotifications(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingProducts, refetch: refetchProducts } = useQuery(
		"products-dashboard",
		() =>
			productActions.list(
				user?.company.id,
				undefined,
				undefined,
				undefined,
				undefined,
				"-updated_at",
				50
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProductsLength(res.count);
				setHasAmazonCertificationTodo(
					res.results.find(
						(product) =>
							(product.certification_processes ?? []).filter(
								(certificationProcess) =>
									certificationProcess.certification?.certification_type ===
										"CLIMECO_INTERNAL_CERTIFICATION" &&
									certificationProcess.status === "COMPLETED"
							).length >
							(product.certification_processes ?? []).filter(
								(certificationProcess) =>
									certificationProcess.certification?.certification_type ===
									"DEFAULT_EXTERNAL_CERTIFICATION"
							).length
					)
						? true
						: false
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const {
		refetch: refetchClimecoCertifications,
		isLoading: isLoadingClimecoCertifications,
	} = useQuery(
		"certifications-climeco-dashboard",
		() =>
			certificationActions.list(
				true,
				user?.company.id,
				undefined,
				undefined,
				undefined,
				undefined,
				10,
				false
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				const foundCompletedCertification = res.results.find(
					(c) => c.status === "COMPLETED" && !c.is_historical
				)
					? true
					: false;

				setHasCompletedClimecoCertification(foundCompletedCertification);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { mutate: mutateSeenHistoricalCompany } = useMutation(
		"seen-historical-company",
		() =>
			companyActions.update({
				id: user?.company!.id!,
				seen_historically_approved_message: new Date(),
			}),
		{
			retry: false,
			onSuccess: () => {
				const auxUser = { ...user };
				auxUser.company!.seen_historically_approved_message = new Date();
				setUser(auxUser as IUser);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.company?.id) {
			refetchNotifications();
			refetchProducts();
			refetchClimecoCertifications();
		}
	}, [
		user,
		refetchNotifications,
		refetchProducts,
		refetchClimecoCertifications,
	]);

	useEffect(() => {
		refetchServices();
	}, [refetchServices]);

	/*useEffect(() => {
		if (
			user?.company?.requested_historically_approved &&
			!user?.company?.historically_approved
		) {
			setShowWaitingConfirmationModal(true);
		} else if (
			!user?.company?.seen_historically_approved_message &&
			user?.company?.historically_approved
		) {
			setShowHistoricallyApprovedModal(true);
		}
	}, [user]);*/

	return (
		<Box>
			{/*!user?.is_superuser &&
			!user?.company?.historically_approved &&
			hasCompletedHistorialCertification ? (
				!user?.company?.requested_historically_approved ? (
					<Alert
						sx={{ marginBottom: 4, maxWidth: 1000 }}
						severity="info"
						action={
							<Button
								color="inherit"
								size="small"
								disabled={isLoadingToggleCompany}
								onClick={() => mutateToggleCompany()}
							>
								{isLoadingToggleCompany ? (
									<CircularProgress size={12} />
								) : (
									"YES"
								)}
							</Button>
						}
					>
						Have you added all your historical data?
					</Alert>
				) : (
					<Alert sx={{ marginBottom: 4, maxWidth: 1000 }} severity="info">
						The final approval process for your historical data is in progress.
						Once it is done you will get notified.
					</Alert>
				)
			) : null*/}
			<Heading
				title="My Tasks"
				subtitle="This is where you can see tasks you need to complete and maintain certification."
				showSearch={false}
				noMarginBottom
			/>
			<Box
				sx={{
					backgroundColor: "#fff",
					marginBottom: 8,
					maxWidth: "1000px",
					width: "100%",
				}}
			>
				{isLoading || isLoadingClimecoCertifications || isLoadingProducts ? (
					<CircularProgress size={24} />
				) : notifications && notifications.length > 0 ? (
					<>
						{notifications.slice(0, 3).map((notification) => {
							return (
								<Updates
									key={notification.id}
									notification={notification}
									callback={() => {
										setNotifications((prev) => {
											const auxNotifications = [...prev!];
											const index = auxNotifications.findIndex(
												(n) => n.id === notification.id
											);
											if (index > -1) {
												auxNotifications.splice(index, 1);
											}
											return auxNotifications;
										});
										refetchNotifications();
									}}
									productsLength={productsLength}
								/>
							);
						})}
						{notifications.length > 3 && (
							<Typography
								variant="body2"
								onClick={() => {
									navigate("/notifications/");
								}}
								sx={{
									textAlign: "right",
									marginBottom: 4,
									marginTop: 1,
									fontSize: 12,
									cursor: "pointer",
									"&:hover": { textDecoration: "underline" },
								}}
							>
								see more
							</Typography>
						)}
					</>
				) : hasAmazonCertificationTodo ? (
					<Updates
						key={-1}
						notification={{
							id: -1,
							title: "Apply for Amazon CPF certification",
							message: "",
							notification_type: "START_EXTERNAL_CERTIFICATION",
							status: "",
							created_at: new Date().toISOString(),
							users: [],
							product_id: null,
							certification_process_id: null,
							lca_version: null,
							sale_id: null,
							button_title: "Apply",
						}}
						callback={() => {}}
						hideCheckbox={true}
						productsLength={productsLength}
					/>
				) : (
					<Updates
						key={-1}
						notification={{
							id: -1,
							title:
								"Click start to begin the process of getting your products certified",
							message: "",
							notification_type: "ADD_LCA",
							status: "",
							created_at: new Date().toISOString(),
							users: [],
							product_id: null,
							certification_process_id: null,
							lca_version: null,
							sale_id: null,
							button_title: "Start",
						}}
						callback={() => {}}
						hideCheckbox={true}
						productsLength={productsLength}
					/>
				)}
			</Box>

			{user && user.is_superuser ? (
				<Box sx={{ marginTop: 2, marginBottom: 6 }}>
					<AdminAnalytics title="Platform management" />
				</Box> /*: productsLength > 0 ? (
				<Box sx={{ marginTop: 2, marginBottom: 6 }}>
					<CompanyAnalytics title={"Account management"} />
				</Box>
			)*/
			) : null}

			{hasCompletedClimecoCertification &&
				services &&
				services?.findIndex(
					(service) => user?.is_superuser || service.visible
				) > -1 && (
					<>
						<Heading
							title="More from ClimeCo"
							showSearch={false}
							noMarginBottom
						/>
						<Grid container spacing={2}>
							{services &&
								services
									.filter((service) => user?.is_superuser || service.visible)
									.map((service) => {
										return (
											<Grid key={service.id} item xs={12} sm={6} md={6} lg={4}>
												<ServiceCard service={service} />
											</Grid>
										);
									})}
						</Grid>
					</>
				)}

			{/*<BasicModal
				width={600}
				showModal={showWaitingConfirmationModal}
				handleClose={() => {}}
				hideClose
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							Hold tight...
						</Typography>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}
						>
							A ClimeCo representative will now review your historical product
							data. Please expect an email from us in 2-3 business days. Please
							stay tuned for updates on when you can upload new products via
							this portal.. Once your historical data is verified, you can start
							adding and certifying new products along with updating your sales
							numbers and managing your offset inventory in our ClimeCo product
							portal.
						</Typography>
						<Divider sx={{ marginTop: "40px", marginBottom: "40px" }} />
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Typography
								variant="body2"
								sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}
							>
								<small>
									While waiting, click{" "}
									<a
										href="#logout"
										onClick={(e) => {
											e.preventDefault();
											connectionsClient.updateTokens({
												access: null,
												refresh: null,
												userId: null,
											});
											setUser(null);
											navigate("/");
											window.location.href = window.location.origin;
										}}
										style={{ color: "#25406d" }}
									>
										here if you need to logout
									</a>
								</small>
							</Typography>
						</Box>
					</Box>
				}
			/>*/}

			<BasicModal
				width={600}
				showModal={showHistoricallyApprovedModal}
				handleClose={() => {
					setShowHistoricallyApprovedModal(false);
				}}
				hideClose
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{
								fontWeight: 700,
								textAlign: "center",
								marginBottom: 2,
							}}
						>
							Welcome!
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<ConfettiExplosion zIndex={1400} />
						</Box>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}
						>
							Thank you for adding your historical information. Welcome to
							ClimeCo's new product portal where you can add products, certify
							those products, report sales and manage offset inventory. The goal
							is to make certifying and decarbonizing products easy for you, so
							please provide feedback or ideas on how we can do that.
							<br />
							<br />
							If you would like to have a ClimeCo team member walk you through
							the portal or answer any questions about this program or ClimeCo
							in general, here is a {""}
							<a
								style={{ color: "#25406D", cursor: "pointer", fontWeight: 700 }}
								href={
									"https://outlook.office365.com/owa/calendar/ProductCertCustomers@climeco.com/bookings/"
								}
								onClick={(e) => {
									e.preventDefault();
									setShowIframe(true);
								}}
							>
								link
							</a>{" "}
							where you can schedule time.
							<br />
							<br />
							We are here to help you in your decarbonization journey!
						</Typography>
						{showIframe && (
							// eslint-disable-next-line jsx-a11y/iframe-has-title
							<iframe
								src="https://outlook.office365.com/owa/calendar/ProductCertCustomers@climeco.com/bookings/"
								width="100%"
								height="500px"
								scrolling="yes"
								style={{ border: "0" }}
							></iframe>
						)}
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									minHeight: 34,
								}}
								onClick={() => {
									setShowHistoricallyApprovedModal(false);
									mutateSeenHistoricalCompany();
								}}
							>
								OK
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
