import { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "./CompanyActionBar.types";
import { BasicModal } from "../BasicModal";
import { company as companyActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";

export function CompanyActionBar({
	selectedRows,
	refetch,
	filters,
	setFilters,
}: PropTypes) {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const { isLoading: isLoadingDeleteCompany, mutate: mutateDeleteCompany } =
		useMutation(
			"delete-company",
			() => companyActions.delete(selectedRows.map((row) => row.id)),
			{
				retry: false,
				onSuccess: () => {
					enqueueSnackbar("Companies have been successfully deleted.", {
						variant: "success",
					});
					setShowDeleteModal(false);
					refetch(); // Refetch data after deletion
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong", { variant: "error" });
				},
			}
		);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					flex: 1,
				}}
			>
				{selectedRows && selectedRows.length ? (
					<Button
						variant="outlined"
						sx={{ marginRight: 2, minHeight: 34, minWidth: 120 }}
						onClick={() => {
							setShowDeleteModal(true);
						}}
					>
						Delete
					</Button>
				) : null}
				<Button
					variant="outlined"
					sx={{ marginRight: 2, minHeight: 34, minWidth: 120 }}
					onClick={() => {
						navigate("/company");
					}}
				>
					Create
				</Button>
			</Box>
			<BasicModal
				width={600}
				showModal={showDeleteModal}
				handleClose={() => {
					setShowDeleteModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{selectedRows.length > 1
								? "Are you sure you want to delete these companies?"
								: "Are you sure you want to delete this company?"}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowDeleteModal(false);
								}}
								disabled={isLoadingDeleteCompany}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									mutateDeleteCompany();
								}}
								disabled={isLoadingDeleteCompany}
							>
								{isLoadingDeleteCompany ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									"Delete"
								)}
							</Button>
						</Box>
					</Box>
				}
			/>
		</>
	);
}
