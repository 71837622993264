import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import { UserProvider } from "./contexts/user";
import { DataProvider } from "./contexts/data";
import { MainLayout } from "./components";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import { MaterialDesignContent } from "notistack";
import { UserSnapProvider } from "./contexts/usersnap";
import styled from "styled-components";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback } from "./components/Fallback";
import Logger, { levels } from "./utils/LoggingClients";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	"&.notistack-MuiContent-success": {
		backgroundColor: "#2D7738",
		boxShadow: "none",
	},
	"&.notistack-MuiContent-error": {
		backgroundColor: "#EB4B4B",
		boxShadow: "none",
	},
}));

const queryClient = new QueryClient();

function App() {
	return (
		<ErrorBoundary
			FallbackComponent={Fallback}
			onReset={(details) => {
				// Reset the state of your app so the error doesn't happen again
			}}
			onError={(error: Error, info: { componentStack: string }) => {
				Logger(error.message + " - " + info.componentStack, levels.FATAL);
			}}
		>
			<UserSnapProvider>
				<ThemeProvider theme={theme}>
					<SnackbarProvider
						maxSnack={3}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						autoHideDuration={3000}
						Components={{
							success: StyledMaterialDesignContent,
							error: StyledMaterialDesignContent,
						}}
					>
						<QueryClientProvider client={queryClient}>
							<UserProvider>
								<DataProvider>
									<MainLayout />
								</DataProvider>
							</UserProvider>
						</QueryClientProvider>
					</SnackbarProvider>
				</ThemeProvider>
			</UserSnapProvider>
		</ErrorBoundary>
	);
}

export default App;
