import { PropTypes } from "./Fallback.types";
import { Box, Button, Typography } from "@mui/material";
import Logo from "../../assets/logo.png";

export function Fallback({ error, resetErrorBoundary }: PropTypes) {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				height: "100vh",
				padding: 2,
				"& *": {
					fontFamily: "Raleway",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
					padding: 4,
					maxWidth: 800,
					width: "100%",
					margin: "auto",
					background: "#fafafa",
					borderRadius: 2,
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				<img
					src={Logo}
					alt="climeco"
					style={{ width: 150, marginBottom: 20 }}
				/>
				<Typography variant="h6" sx={{ fontFamily: "Raleway" }}>
					SOMETHING WENT WRONG
				</Typography>
				<Typography variant="body1" sx={{ fontFamily: "Raleway" }}>
					Please, share the error below to the following email{" "}
					<a style={{ color: "#1976d2" }} href="mailto:cbates@climeco.com">
						cbates@climeco.com
					</a>{" "}
					or retry with one of the buttons below.
				</Typography>
				<pre
					style={{
						background: "#24262b",
						borderRadius: "8px",
						width: "100%",
						textAlign: "left",
						maxHeight: 150,
						overflow: "auto",
						padding: "20px",
					}}
				>
					<code
						style={{
							fontFamily: "monospace",
							color: "#e2e2e9",
							whiteSpace: "break-spaces",
						}}
					>
						{error.message}
					</code>
				</pre>
				<Box sx={{ display: "flex", gap: 2 }}>
					<Button
						sx={{
							width: 150,
							fontFamily: "Raleway",
							borderColor: "#25406D",
							color: "#25406D",
							boxShadow: "none",
							fontSize: "12px",
							borderRadius: "80px",
							height: 33,
							padding: "6px 16px",
							"&:hover": {
								width: 150,
								fontFamily: "Raleway",
								borderColor: "#25406D",
								color: "#25406D",
								boxShadow: "none",
								fontSize: "12px",
								borderRadius: "80px",
								height: 33,
								padding: "6px 16px",
							},
						}}
						variant="outlined"
						onClick={() => {
							resetErrorBoundary();
						}}
					>
						RETRY
					</Button>
					<Button
						sx={{
							width: 150,
							fontFamily: "Raleway",
							background: "#25406D",
							color: "white",
							boxShadow: "none",
							fontSize: "12px",
							borderRadius: "80px",
							height: 33,
							padding: "6px 16px",
							"&:hover": {
								width: 150,
								fontFamily: "Raleway",
								background: "#25406D",
								color: "white",
								boxShadow: "none",
								fontSize: "12px",
								borderRadius: "80px",
								height: 33,
								padding: "6px 16px",
							},
						}}
						variant="contained"
						onClick={() => {
							window.location.href = window.location.origin;
						}}
					>
						BACK TO HOME
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
