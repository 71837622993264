import { useState, useEffect } from "react";
import {
	Typography,
	Box,
	Autocomplete,
	TextField,
	Button,
	Divider,
	Checkbox,
	FormControlLabel,
	CircularProgress,
	Tooltip,
} from "@mui/material";

import { PropTypes } from "./MultiItemSelector.types";
import { HiOutlineTrash as Trash } from "react-icons/hi2";
import IconButton from "@mui/material/IconButton";
import { IProduct } from "../../models";
import { ProductStatusChip } from "../ProductDetail/ProductStatusChip";
import MarketplaceSelector from "../MarketplaceSelector/MarketplaceSelector";
import InfoIcon from "@mui/icons-material/Info";

export function MultiItemSelector({
	title,
	placeholderAutocomplete,
	buttonTitle,
	listTitle,
	emptyState,
	elements,
	selectedElements,
	setSelectedElements,
	setFilter,
	setShowCreateProductModal,
	external,
	externalLoading,
}: PropTypes) {
	const [availableElements, setAvailableElements] = useState(elements);
	const [selectedElement, setSelectedElement] = useState<IProduct | null>(null);

	const [selectAll, setSelectAll] = useState(false);

	const removeElement = (indexToRemove: number) => {
		const updatedElements = [...selectedElements];
		updatedElements.splice(indexToRemove, 1);
		setSelectedElements(updatedElements);
	};

	useEffect(() => {
		const updatedAvailableElements = elements.filter(
			(element) =>
				!selectedElements.some(
					(selectedElement) => selectedElement?.id === element.id
				)
		);
		setAvailableElements(updatedAvailableElements);
	}, [selectedElements, elements]);

	const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectAll(e.target.checked);
		if (e.target.checked) {
			setSelectedElements(elements);
		} else {
			setSelectedElements([]);
		}
	};

	const isValidASIN = (asin: string): boolean => {
		const asinRegex = /^(B[\dA-Z]{9}|\d{9}[\dX])$/;
		return asinRegex.test(asin);
	};

	return (
		<Box sx={{ width: "100%" }}>
			{availableElements && availableElements.length > 0 && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: {
							xs: "column",
							sm: "row",
							md: "row",
						},
						marginBottom: 4,
					}}
				>
					<Autocomplete
						disablePortal
						id="source-autocomplete"
						options={
							external
								? availableElements
								: availableElements.filter((e, i) => i < 5)
						}
						sx={{
							width: {
								xs: 200,
								sm: 300,
								md: 300,
							},
							maxHeight: "200px",
							overflowY: "auto",
							"& input": {
								fontSize: 14,
							},
						}}
						onChange={(_, value) => {
							setSelectedElements([...selectedElements, value!]);
							setSelectedElement(null);
						}}
						getOptionDisabled={
							(option) =>
								option.status === "OUTDATED" ||
								!option.lca_documents ||
								option.lca_documents.length === 0 ||
								option.lca_documents.findIndex(
									(doc) =>
										new Date(doc.certification_date) <
										new Date(new Date().getTime() - 1095 * 24 * 60 * 60 * 1000)
								) > -1 // to be read from settings
						}
						value={selectedElement}
						blurOnSelect={true}
						clearOnBlur={true}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id}>
									{option.name +
										(option.asin
											? " - " + option.asin
											: option.upc
											? " - " + option.upc
											: "")}{" "}
									<Box sx={{ marginLeft: 2 }}>
										<ProductStatusChip status={option.status} />
									</Box>
								</li>
							);
						}}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								placeholder={placeholderAutocomplete}
								onChange={(e) => {
									setFilter(e.target.value);
								}}
							/>
						)}
					/>
					{setShowCreateProductModal && !external && (
						<>
							<Typography
								variant="body2"
								sx={{ marginLeft: 2, marginRight: 2 }}
							>
								or
							</Typography>
							<Button
								variant="outlined"
								onClick={() => {
									setShowCreateProductModal(true);
								}}
							>
								Create Product
							</Button>
						</>
					)}

					{external && (
						<FormControlLabel
							value="end"
							control={
								<Checkbox
									size="small"
									checked={selectAll}
									onChange={handleSelectAllChange}
								/>
							}
							label="Select all products"
							labelPlacement="end"
							sx={{
								marginLeft: 2,
								marginRight: 0.5,
								span: { fontSize: 14 },
							}}
						/>
					)}
				</Box>
			)}

			<Typography variant="body2" sx={{ marginBottom: 2 }}>
				{listTitle}
			</Typography>

			<Box
				sx={{
					minHeight: 300,
					backgroundColor: "#fafafa",
					padding: 2,
					overflow: "auto",
				}}
			>
				{externalLoading ? (
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress size={20} />
					</Box>
				) : selectedElements && selectedElements.length > 0 ? (
					<Box>
						{external && (
							<Box>
								<Box
									sx={{
										display: "flex",
										justifyContent: "flex-start",
										flexDirection: {
											xs: "column",
											sm: "column",
											md: "row",
										},
										marginBottom: 2,
									}}
								>
									<Typography
										variant="body2"
										sx={{
											minWidth: 300,
											marginBottom: {
												xs: 2,
												sm: 2,
												md: 0,
											},
										}}
									>
										Product
									</Typography>
									<Typography
										variant="body2"
										sx={{
											minWidth: {
												xs: 200,
												sm: 300,
												md: 300,
											},
											marginLeft: {
												xs: 0,
												sm: 0,
												md: 2,
											},
											paddingLeft: { xs: 0, sm: 0, md: "14px" },
										}}
									>
										Marketplaces
									</Typography>
									<Typography
										variant="body2"
										sx={{
											minWidth: 300,
											marginLeft: {
												xs: 0,
												sm: 0,
												md: 2,
											},
											marginTop: {
												xs: 2,
												sm: 2,
												md: 0,
											},
										}}
									>
										ASIN
										<Tooltip
											sx={{ padding: 0 }}
											title={"Amazon Standard Identification Number"}
										>
											<IconButton sx={{ height: 12 }}>
												<InfoIcon sx={{ fontSize: 14 }} />
											</IconButton>
										</Tooltip>
									</Typography>
								</Box>
								<Divider sx={{ xs: 2, sm: 2, md: 0 }} />
							</Box>
						)}
						{selectedElements.map((element, index) => {
							return (
								<Box key={element.id}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: {
													xs: "flex-start",
													sm: "center",
													md: "center",
												},
												flexDirection: {
													xs: "column",
													sm: "column",
													md: "row",
												},
											}}
										>
											<Typography
												variant="body2"
												key={element?.id}
												sx={{
													width: external ? 300 : "auto",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
													marginBottom: {
														xs: 2,
														sm: 2,
														md: 0,
													},
												}}
											>
												{!external && element?.asin
													? `${element?.name} - ${element?.asin}`
													: element?.name}
											</Typography>

											{external && (
												<>
													<MarketplaceSelector
														index={index}
														setSelectedElements={setSelectedElements}
														selectedElements={selectedElements}
														removeElement={removeElement}
													/>
													<TextField
														InputLabelProps={{ shrink: false }}
														fullWidth
														label=""
														id="asin"
														value={element?.asin}
														placeholder="ASIN"
														onChange={(e) => {
															const sources = [...selectedElements];
															sources[index].asin = e.target.value;
															setSelectedElements(sources);
														}}
														sx={{
															width: external ? 300 : "auto",
															overflow: "hidden",
															whiteSpace: "nowrap",
															textOverflow: "ellipsis",
															marginLeft: {
																xs: 0,
																sm: 0,
																md: 2,
															},
															marginTop: {
																xs: 2,
																sm: 2,
																md: 0,
															},
															border: "1px solid",
															borderRadius: 1,
															borderColor:
																element?.asin && isValidASIN(element?.asin)
																	? "#eee"
																	: "#eb4343",
														}}
														disabled={
															availableElements.find(
																(aElement) =>
																	aElement.id === element.id && aElement.asin
															) !== undefined
														}
													/>
												</>
											)}
										</Box>
										<IconButton
											color="primary"
											aria-label="remove source"
											size="small"
											onClick={() => {
												removeElement(index);
											}}
										>
											<Trash />
										</IconButton>
									</Box>
									<Divider sx={{ opacity: 0.3, margin: "14px 8px" }} />
								</Box>
							);
						})}
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
						}}
					>
						<Typography variant="body2" sx={{ marginBottom: 2, opacity: 0.4 }}>
							{emptyState}
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
}
