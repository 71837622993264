import React from "react";
import { Box, Button, Typography, Alert } from "@mui/material";
import { PropTypes } from "./CertificationConfirmationStep.types";

export function CertificationConfirmationStep({
	currentStep,
	setCurrentStep,
	setOriginalEstimations,
	certification,
	setCurrentProducts,
	setShowAgreeModal,
	text,
	infoAlertText,
}: PropTypes) {
	return !certification?.requested_approval ? (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				marginTop: 2,
				width: "100%",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Typography>{text}</Typography>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					justifyContent: "flex-end",
				}}
			>
				{!certification?.requested_approval && (
					<Button
						variant="outlined"
						sx={{ maxWidth: 150 }}
						onClick={() => {
							setCurrentStep(currentStep - 1);
							setOriginalEstimations(certification!.estimations);
							setCurrentProducts(
								certification!.estimations.map(
									(estimation) => estimation.product
								)
							);
						}}
					>
						Back
					</Button>
				)}
				<Button
					variant="contained"
					sx={{ maxWidth: 150 }}
					onClick={() => {
						setShowAgreeModal(true);
					}}
				>
					Agree
				</Button>
			</Box>
		</Box>
	) : !certification?.estimations.find(
			(estimation) => estimation.rejected_date
	  ) ? (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				marginTop: 2,
				width: "100%",
			}}
		>
			<Alert severity="info">{infoAlertText}</Alert>
		</Box>
	) : (
		<></>
	);
}
