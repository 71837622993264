import React, { useContext, useEffect, useState } from "react";
import {
	Alert,
	Box,
	CircularProgress,
	Divider,
	Typography,
	Button,
	useMediaQuery,
	TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PropTypes } from "./ProductSummarys.types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { UserContext } from "../../contexts/user";
import { UploadFile } from "../UploadFile";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import {
	ICertification,
	IEstimation,
	ILCAVersion,
	IProductLCA,
	IProductLCAVersionEmission,
} from "../../models";
import { useSnackbar } from "notistack";
import {
	estimation as estimationsActions,
	certificationProcessStep as certificationProcessStepActions,
	sale as saleActions,
	product as productActions,
	productLCAVersionEmission as productLCAVersionEmissionActions,
	lcaVersion as lcaVersionActions,
} from "../../controllers";
import theme from "../../Theme";
import { NumericFormat } from "react-number-format";
import LCACollapsible from "../LCACollapsible/LCACollapsible";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
		color: "#888",
	},
});

export function ProductSummarys({
	certification,
	setCertification,
	refetchCertification,
	setShowConfirmation,
	setConfirmationData,
	currentStep,
	showSales,
	setShowHistoricalInfo,
	isHistorical,
	salesByYearAndQuarter,
}: PropTypes) {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [documentLoading, setDocumentLoading] = useState(-1);
	const [approveLoading, setApproveLoading] = useState(-1);
	const [rejectLoading, setRejectLoading] = useState(-1);
	const [manualApproving, setManualApproving] = useState(false);
	const onlyHighestScreen = useMediaQuery(theme.breakpoints.up(1900));
	const onlyLargestScreen = useMediaQuery(theme.breakpoints.up(1450));
	const onlyLargeScreen = useMediaQuery(theme.breakpoints.up(1300));
	const onlyMediumScreen = useMediaQuery(theme.breakpoints.down(1200));
	const onlySmallScreen = useMediaQuery(theme.breakpoints.down(600));
	const getLCAVersionProducts = (certification: ICertification) => {
		const products = Array<IProductLCAVersionEmission>();
		(certification.lca_versions ?? []).forEach((lcaVersion) => {
			lcaVersion.products.forEach((product) => {
				if (
					product &&
					product.product_lca_version_emissions &&
					product.product_lca_version_emissions.length > 0
				) {
					products.push(product.product_lca_version_emissions[0]);
				}
			});
		});

		return products;
	};
	const [initialEmissions, setInitialEmissions] = useState(
		getLCAVersionProducts(certification)
	);
	const classes = useStyles();

	useEffect(() => {
		if (certification) {
			setInitialEmissions(getLCAVersionProducts(certification));
		}
	}, [certification]);

	const {
		isLoading: isLoadingDeleteEstimation,
		mutate: deleteEstimationMutate,
	} = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
			onSuccess: () => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const {
		isLoading: isLoadingUpdateCertificationStep,
		mutate: updateCertificationStepMutate,
	} = useMutation(
		"certification-detail",
		({
			status,
			rejectReason,
			manualApprove,
		}: {
			status: string;
			rejectReason?: string | undefined;
			manualApprove?: boolean;
		}) =>
			certificationProcessStepActions.update({
				id: certification!.certification_process_steps[currentStep].id,
				status: status,
				rejectReason: rejectReason,
				manualApprove: manualApprove,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingToggleEstimation, mutate: updateToggleMutate } =
		useMutation(
			"estimations-toggle",
			(estimation: IEstimation) =>
				estimationsActions.toggle({
					id: estimation.id,
					certificationProcessId: certification!.id,
					companyId: certification!.company.id,
					toggle: estimation.approved_date
						? "approve"
						: estimation.rejected_date
						? "reject"
						: "reset",
					reject_reason: estimation.reject_reason ?? null,
					overwrite_certification_date: showSales
						? undefined
						: estimation.overwrite_certification_date
						? estimation.overwrite_certification_date.split("T")[0]
						: undefined,
					pcf_amount:
						estimation.product_lca_version_emission?.emissions ?? undefined,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					refetchCertification();
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const { isLoading: isLoadingLCA, mutate: updateLCA } = useMutation(
		"lca-version-update",
		({
			lcaVersion,
			approvedDate,
			rejectedDate,
			rejectReason,
			status,
		}: {
			lcaVersion: ILCAVersion;
			approvedDate: string | null;
			rejectedDate: string | null;
			rejectReason: string | null;
			status: string;
		}) =>
			lcaVersionActions.update({
				lcaVersionId: lcaVersion.id!,
				lcaId: lcaVersion.lca_id!,
				approvedDate,
				rejectedDate,
				rejectReason,
				status,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				const auxCertification = { ...certification };
				let lcaVersionIndex = (auxCertification.lca_versions ?? []).findIndex(
					(l) => l.id === res.id
				);
				if (lcaVersionIndex > -1) {
					auxCertification.lca_versions![lcaVersionIndex].approved_date =
						res.approved_date;
					auxCertification.lca_versions![lcaVersionIndex].rejected_date =
						res.rejected_date;
					auxCertification.lca_versions![lcaVersionIndex].rejected_date =
						res.reject_reason;
				}
				setCertification(auxCertification);
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const {
		isLoading: isLoadingChecklistDocument,
		mutate: updateChecklistDocument,
	} = useMutation(
		"lca-version-document",
		({
			certification,
			checklistDocument,
			lcaVersion,
		}: {
			certification: ICertification;
			checklistDocument: File;
			lcaVersion: ILCAVersion;
		}) =>
			lcaVersionActions.updateChecklist({
				lcaVersionId: lcaVersion.id,
				lcaId: lcaVersion.lca_id!,
				checklistDocument: checklistDocument,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				const auxCertification = { ...certification };
				let index = (auxCertification.lca_versions ?? []).findIndex(
					(l) => l.id === res.id
				);
				if (index > -1) {
					if (!auxCertification.lca_versions) {
						auxCertification.lca_versions = [];
					}
					auxCertification.lca_versions[index].checklist_document =
						res.checklist_document;
				}
				setCertification(auxCertification);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const {
		isLoading: isLoadingUpdateProductLCAVersionEmission,
		mutate: mutateUpdateProductLCAVersionEmission,
	} = useMutation(
		"update-product-lca-version-emission",
		({
			id,
			productLcaVersionEmission,
			lcaVersionId,
			lcaId,
			companyId,
		}: {
			id: number;
			productLcaVersionEmission: number;
			lcaVersionId: number;
			lcaId: number;
			companyId?: number;
		}) =>
			productLCAVersionEmissionActions.update(
				id,
				productLcaVersionEmission,
				lcaVersionId,
				lcaId,
				companyId
			),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingUpdateSale, mutate: updateSaleMutate } =
		useMutation(
			"historical-sales-update",
			({ id, amount }: { id: number; amount: number }) =>
				saleActions.update(id, amount),
			{
				retry: false,
			}
		);

	const {
		isLoading: isLoadingUpdateEstimation,
		mutate: updateEstimationMutate,
	} = useMutation(
		"historical-estimation-update",
		({ id, amount }: { id: number; amount: number }) =>
			estimationsActions.update({
				id,
				companyId: certification.company.id,
				amount,
				certificationProcess: certification.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingDocuments, mutate: mutateDocuments } =
		useMutation(
			"products-lca-update",
			({
				productLCAId,
				productLCA,
				productId,
			}: {
				productLCAId: number;
				productLCA: IProductLCA;
				productId: number;
			}) =>
				productActions.updateLCA({
					id: productLCAId,
					companyId: !user?.is_superuser ? user?.company.id : undefined,
					productId: productId,
					emissions: productLCA.emissions!,
					certification_date: productLCA.certification_date,
					lca_provider: "",
					lca_document: undefined,
				}),
			{
				retry: false,
			}
		);

	return (
		<>
			{(certification?.lca_versions ?? []).map((lcaVersion, index) => {
				return (
					<Box sx={{ marginBottom: 4 }} key={lcaVersion.id}>
						<LCACollapsible
							title={
								<Box
									sx={{
										width: "100%",
										justifyContent: "space-between",
										display: "flex",
									}}
								>
									<Typography
										variant="h6"
										sx={{
											fontWeight: "bold",
											color: "#333",
											fontSize: 14,
										}}
									>
										LCA: {lcaVersion.title ?? lcaVersion.lca?.title ?? index}{" "}
									</Typography>
									{lcaVersion?.approved_date ? (
										<CheckCircleIcon
											sx={{
												border: "1px solid #25406D",
												borderRadius: "50%",
												color: "#25406D",
												marginLeft: 2,
												marginRight: 2,
												verticalAlign: "middle",
											}}
										/>
									) : lcaVersion?.rejected_date ? (
										<CancelIcon
											sx={{
												border: "1px solid #EB4B4B",
												borderRadius: "50%",
												color: "#EB4B4B",
												marginLeft: 2,
												marginRight: 2,
												verticalAlign: "middle",
											}}
										/>
									) : (
										<WatchLaterIcon
											sx={{
												border: "1px solid #f1c40f",
												borderRadius: "50%",
												color: "#f1c40f",
												marginLeft: 2,
												marginRight: 2,
												verticalAlign: "middle",
											}}
										/>
									)}
								</Box>
							}
							defaultExpanded={index === 0 && user?.is_superuser}
						>
							<Typography
								variant="subtitle1"
								sx={{
									fontWeight: "bold",
									color: "#444",
									marginTop: "12px",
									fontSize: 14,
								}}
							>
								LCA SUMMARY
							</Typography>

							<Divider sx={{ marginBottom: "16px" }} />
							<Box
								key={lcaVersion.id}
								sx={{
									marginBottom: "12px",
									width: "100%",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: isHistorical
												? onlyLargeScreen
													? "center"
													: "flex-start"
												: onlyLargestScreen
												? "center"
												: "flex-start",
											flexDirection: isHistorical
												? onlyLargeScreen
													? "row"
													: "column"
												: onlyLargestScreen
												? "row"
												: "column",
											justifyContent: "space-between",
											gap: 2,
											width: "100%",
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: /*onlyHighestScreen ? "row" :*/ "column",
												flex: 1,
												width: "100%",
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													gap: 2,
													alignItems: "flex-start",
													marginBottom: onlyLargestScreen ? 0 : 2,
													width: "100%",
												}}
											>
												<Box>
													{lcaVersion?.approved_date ? (
														<CheckCircleIcon
															sx={{
																border: "1px solid #25406D",
																borderRadius: "50%",
																color: "#25406D",
															}}
														/>
													) : lcaVersion?.rejected_date ? (
														<CancelIcon
															sx={{
																border: "1px solid #EB4B4B",
																borderRadius: "50%",
																color: "#EB4B4B",
															}}
														/>
													) : (
														<WatchLaterIcon
															sx={{
																border: "1px solid #f1c40f",
																borderRadius: "50%",
																color: "#f1c40f",
															}}
														/>
													)}
												</Box>
												<Typography
													sx={{
														flex: 1,
														width: 300,
														wordWrap: "break-word",
													}}
												>
													{lcaVersion.title ??
														lcaVersion.lca?.title ??
														"LCA to be certified"}{" "}
													- {lcaVersion?.lca_provider}
												</Typography>
											</Box>
											<Box
												sx={{
													display: "flex",
													marginTop: 4,
													gap: { xs: 2, sm: 2, md: 0 },
													overflow: "hidden",
													justifyContent: "space-between",
													width: "100%",
													flexDirection: onlyMediumScreen ? "column" : "row",
												}}
											>
												<Box
													sx={{
														display: "flex",
														width: "100%",
														justifyContent: "flex-end",
														gap: 4,
														flexDirection: {
															xs: "column",
															sm: "column",
															md: "column",
															lg: "row",
														},
													}}
												>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															maxHeight: 77,
														}}
													>
														<span className={classes.inputLabel}>
															LCA Document
														</span>
														<Typography
															sx={{
																marginLeft: 2,
																textOverflow: "ellipsis",
																overflow: "hidden",
																width: 150,
																flex: { xs: 1, sm: 1, md: "unset" },
																textAlign: onlyLargeScreen ? "right" : "left",
																fontSize: 14,
																whiteSpace: "nowrap",
																marginBottom: onlyLargestScreen ? 0 : 1,
															}}
														>
															<a
																href={
																	lcaVersion?.lca_document?.toString() ?? "#"
																}
																style={{ whiteSpace: "nowrap" }}
																target="_blank"
																rel="noreferrer"
																download
															>
																...
																{lcaVersion?.lca_document?.toString()
																	? "..." +
																	  lcaVersion!
																			.lca_document!.toString()
																			.split("/")
																			[
																				lcaVersion!
																					.lca_document!.toString()
																					.split("/").length - 1
																			].slice(-15)
																	: "--"}
															</a>
														</Typography>
													</Box>
													{lcaVersion?.certification_date && (
														<Box
															sx={{
																alignItems: onlyLargeScreen
																	? "flex-end"
																	: "flex-start",
																marginBottom: onlyLargeScreen ? 0 : 1,
																textAlign: onlyLargeScreen ? "right" : "left",
																marginRight: 0,
																marginLeft: 2,
																display: "flex",
																flexDirection: "column",
																maxHeight: 77,
															}}
														>
															<span className={classes.inputLabel}>
																LCA Completion Date
															</span>
															{isHistorical && user?.is_superuser ? (
																<TextField
																	placeholder={"LCA Completion Date"}
																	type="date"
																	variant="outlined"
																	InputProps={{
																		inputProps: {
																			max: `${
																				new Date().toISOString().split("T")[0]
																			}`,
																			style: { minWidth: "150px" },
																		},
																	}}
																	sx={{
																		flex: 1,
																		minWidth: "150px",
																	}}
																	value={
																		lcaVersion?.certification_date?.split(
																			"T"
																		)[0]
																	}
																	disabled
																	onChange={(event) => {
																		/*const auxCertification = {
														...certification,
													};
													const auxEstimation =
														auxCertification.estimations.find(
															(e) => e.id === estimation.id
														);
													if (
														auxEstimation &&
														auxEstimation.product_lca_version_emission &&
														auxEstimation.product_lca_version_emission
															.lca_version
													) {
														auxEstimation.product_lca_version_emission.lca_version.certification_date =
															event.target.value;
													}

													setCertification(auxCertification);*/
																	}}
																/>
															) : (
																<Typography
																	sx={{
																		textOverflow: "ellipsis",
																		overflow: "hidden",
																		width: 150,
																		textAlign: onlyLargeScreen
																			? "right"
																			: "left",
																	}}
																>
																	{new Intl.DateTimeFormat("en", {
																		day: "2-digit",
																		month: "2-digit",
																		year: "numeric",
																	}).format(
																		new Date(
																			lcaVersion?.certification_date ?? ""
																		)
																	)}
																</Typography>
															)}
														</Box>
													)}
													<Box
														sx={{
															display: "flex",
															flexDirection: onlySmallScreen ? "column" : "row",
															alignItems: "center",
														}}
													>
														{user?.is_superuser && !showSales && (
															<Box
																sx={{
																	display: "flex",
																	flexDirection: "column",
																	marginLeft: 2,
																}}
															>
																<Typography
																	sx={{
																		textTransform: "uppercase",
																		fontSize: 10,
																		fontWeight: 700,
																		color: "#888",
																	}}
																>
																	CHECKLIST DOCUMENT
																</Typography>
																<UploadFile
																	padding={1}
																	accept=".pdf,.csv,.xlsx,.xls,.doc,.docx,.numbers,.txt"
																	value={lcaVersion?.checklist_document ?? null}
																	onFileChange={(file) => {
																		if (file) {
																			setDocumentLoading(-1);
																			updateChecklistDocument({
																				certification: { ...certification },
																				checklistDocument: file,
																				lcaVersion: lcaVersion,
																			});
																		} else {
																			enqueueSnackbar(
																				"File could not be uploaded. Please, try it again or reload the page",
																				{
																					variant: "error",
																				}
																			);
																		}
																	}}
																	loading={
																		isLoadingChecklistDocument &&
																		documentLoading === -1
																	}
																/>
															</Box>
														)}
														{user?.is_superuser && (
															<Box
																sx={{
																	display: "flex",
																	flexDirection: isHistorical
																		? onlyLargeScreen
																			? "column"
																			: "row"
																		: onlyMediumScreen
																		? "row"
																		: "column",
																	gap: 2,
																	overflow: "hidden",
																	marginTop: onlySmallScreen ? 2 : 0,
																	marginLeft: 2,
																}}
															>
																<Button
																	variant="contained"
																	size="small"
																	disabled={
																		(!showSales &&
																			!lcaVersion?.checklist_document) ||
																		isLoadingLCA ||
																		(lcaVersion?.approved_date ? true : false)
																	}
																	sx={{ minHeight: "29px" }}
																	onClick={() => {
																		setShowConfirmation(true);
																		setConfirmationData({
																			title:
																				"Do you want to approve this LCA version?",
																			description:
																				"Once you confirm, the LCA version will be approved",
																			textfield: null,
																			callback: async () => {
																				setApproveLoading(-1);
																				setShowConfirmation(false);

																				updateLCA({
																					lcaVersion: { ...lcaVersion },
																					approvedDate:
																						new Date().toISOString(),
																					rejectedDate: null,
																					rejectReason: null,
																					status: "ACTIVE",
																				});
																			},
																		});
																	}}
																>
																	{(isLoadingLCA || isLoadingDocuments) &&
																	approveLoading === -1 ? (
																		<CircularProgress
																			size={12}
																			sx={{ color: "white" }}
																		/>
																	) : (
																		"Approve"
																	)}
																</Button>
																<Button
																	variant="outlined"
																	size="small"
																	disabled={
																		(!showSales &&
																			!lcaVersion?.checklist_document) ||
																		isLoadingLCA ||
																		(lcaVersion?.rejected_date ? true : false)
																	}
																	sx={{
																		minHeight: "29px",
																		"&.Mui-disabled": {
																			color: "#25406D",
																			background: "white",
																		},
																	}}
																	onClick={() => {
																		setShowConfirmation(true);
																		setConfirmationData({
																			title:
																				"Do you want to reject this LCA version?",
																			description:
																				"The LCA version will be rejected",
																			textfield: "Rejected reason",
																			callback: (rejectReason) => {
																				setRejectLoading(-1);
																				updateLCA({
																					lcaVersion: { ...lcaVersion },
																					approvedDate: null,
																					rejectedDate:
																						new Date().toISOString(),
																					rejectReason: rejectReason ?? null,
																					status: "REJECTED",
																				});
																				setShowConfirmation(false);
																			},
																		});
																	}}
																>
																	{isLoadingLCA && rejectLoading === -1 ? (
																		<CircularProgress
																			size={12}
																			sx={{ color: "white" }}
																		/>
																	) : (
																		"Reject"
																	)}
																</Button>
															</Box>
														)}
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
									{lcaVersion?.reject_reason && (
										<Alert
											severity="error"
											sx={{ marginTop: 2, marginBottom: 2 }}
										>
											{lcaVersion?.reject_reason}
											{" -> "}
											<span
												style={{
													textDecoration: "underline",
													cursor: "pointer",
												}}
												onClick={() => navigate(`/lcas/${lcaVersion?.lca_id}`)}
											>
												Go to LCA
											</span>
										</Alert>
									)}
									<Divider
										sx={{
											marginTop: 2,
											marginBottom: 2,
											opacity: 0.5,
										}}
									/>
								</Box>
								<Typography
									variant="subtitle1"
									sx={{
										fontWeight: "bold",
										color: "#444",
										marginTop: "12px",
										fontSize: 14,
									}}
								>
									PRODUCT(S) SUMMARY
								</Typography>
								<Divider sx={{ marginBottom: "16px" }} />

								<Box>
									{certification?.estimations
										.filter(
											(estimation) =>
												estimation.product_lca_version_emission?.lca_version
													?.id === lcaVersion.id
										)
										.sort(
											(a, b) =>
												new Date(a.created_at!).getTime() -
												new Date(b.created_at!).getTime()
										)
										.map((estimation, index) => {
											return (
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
													}}
													key={estimation.id + " - " + index}
												>
													<Box
														sx={{
															display: "flex",
															alignItems: isHistorical
																? onlyLargeScreen
																	? "center"
																	: "flex-start"
																: onlyLargestScreen
																? "center"
																: "flex-start",
															flexDirection: isHistorical
																? onlyLargeScreen
																	? "row"
																	: "column"
																: onlyLargestScreen
																? "row"
																: "column",
															justifyContent: "space-between",
															gap: 2,
														}}
													>
														<Box
															sx={{
																display: "flex",
																flexDirection: onlyHighestScreen
																	? "row"
																	: "column",
																flex: 1,
															}}
														>
															<Box
																sx={{
																	display: "flex",
																	flexDirection: "row",
																	gap: 2,
																	alignItems: "flex-start",
																	marginBottom: onlyLargestScreen ? 0 : 2,
																}}
															>
																<Box>
																	{estimation.approved_date ? (
																		<CheckCircleIcon
																			sx={{
																				border: "1px solid #25406D",
																				borderRadius: "50%",
																				color: "#25406D",
																			}}
																		/>
																	) : estimation.rejected_date ? (
																		<CancelIcon
																			sx={{
																				border: "1px solid #EB4B4B",
																				borderRadius: "50%",
																				color: "#EB4B4B",
																			}}
																		/>
																	) : (
																		<WatchLaterIcon
																			sx={{
																				border: "1px solid #f1c40f",
																				borderRadius: "50%",
																				color: "#f1c40f",
																			}}
																		/>
																	)}
																</Box>
																<Typography
																	sx={{
																		flex: 1,
																		width: 300,
																		wordWrap: "break-word",
																	}}
																>
																	{estimation.product.asin
																		? estimation.product.name +
																		  " - " +
																		  estimation.product.asin
																		: estimation.product.upc
																		? estimation.product.name +
																		  " - " +
																		  estimation.product.upc
																		: estimation.product.name}
																</Typography>
															</Box>
														</Box>

														<Box
															sx={{
																display: "flex",
																alignItems: isHistorical
																	? onlyLargeScreen
																		? "center"
																		: "flex-start"
																	: onlyLargestScreen
																	? "center"
																	: "flex-start",
																flexDirection: isHistorical
																	? onlyLargeScreen
																		? "row"
																		: "column"
																	: onlyLargestScreen
																	? "row"
																	: "column",
																justifyContent: "space-between",
																gap: 2,
															}}
														>
															<Box
																sx={{
																	display: "flex",
																	gap: { xs: 2, sm: 2, md: 0 },
																	overflow: "hidden",
																	justifyContent: "flex-end",
																	width: "100%",
																	flexDirection: onlyMediumScreen
																		? "column"
																		: "row",
																}}
															>
																<Box
																	sx={{
																		display: "flex",
																	}}
																>
																	<Box
																		sx={{
																			alignItems: onlyLargeScreen
																				? "flex-end"
																				: "flex-start",
																			marginBottom: onlyLargeScreen ? 0 : 1,
																			textAlign: onlyLargeScreen
																				? "right"
																				: "left",
																			marginRight: 0,
																			marginLeft: 2,
																			display: "flex",
																			flexDirection: "column",
																			maxHeight: 77,
																		}}
																	>
																		<span
																			className={classes.inputLabel}
																			style={{ minWidth: 205 }}
																		>
																			Estimated total sales for 12 months
																		</span>
																		<NumericFormat
																			name={"estimation"}
																			placeholder="Estimated total sales for 12 months"
																			customInput={TextField}
																			type="text"
																			thousandSeparator={","}
																			allowNegative={false}
																			onFocus={(e) => {}}
																			onBlur={() => {}}
																			disabled
																			value={estimation.amount ?? 0}
																			style={{
																				flex: 1,
																				minWidth: "140px",
																			}}
																		/>
																	</Box>
																</Box>
															</Box>

															<Box
																sx={{
																	display: "flex",
																	gap: { xs: 2, sm: 2, md: 0 },
																	overflow: "hidden",
																	justifyContent: "flex-end",
																	width: "100%",
																	flexDirection: onlyMediumScreen
																		? "column"
																		: "row",
																}}
															>
																<Box
																	sx={{
																		display: "flex",
																	}}
																>
																	<Box
																		sx={{
																			alignItems: onlyLargeScreen
																				? "flex-end"
																				: "flex-start",
																			marginBottom: onlyLargeScreen ? 0 : 1,
																			textAlign: onlyLargeScreen
																				? "right"
																				: "left",
																			marginRight: 0,
																			marginLeft: 2,
																			display: "flex",
																			flexDirection: "column",
																			maxHeight: 77,
																		}}
																	>
																		<span
																			className={classes.inputLabel}
																			style={{ minWidth: 205 }}
																		>
																			Product Carbon Footprint Value
																		</span>
																		<NumericFormat
																			name={"amount"}
																			placeholder="Product Carbon Footprint Value"
																			customInput={TextField}
																			type="text"
																			thousandSeparator={","}
																			suffix={" kg of CO2e"}
																			allowNegative={false}
																			onFocus={(e) => {}}
																			onBlur={() => {}}
																			disabled={!user?.is_superuser}
																			value={
																				estimation.product_lca_version_emission
																					?.emissions ?? 0
																			}
																			onValueChange={({ floatValue }) => {
																				const auxCertification = {
																					...certification,
																				};
																				const auxEstimationIndex =
																					auxCertification.estimations.findIndex(
																						(e) => e.id === estimation.id
																					);
																				if (
																					auxEstimationIndex > -1 &&
																					auxCertification.estimations[
																						auxEstimationIndex
																					]!.product_lca_version_emission
																				) {
																					auxCertification.estimations[
																						auxEstimationIndex
																					].product_lca_version_emission!.emissions =
																						floatValue ?? 0;
																				}
																				setCertification(auxCertification);
																			}}
																			style={{
																				flex: 1,
																				minWidth: "140px",
																			}}
																		/>
																	</Box>
																</Box>
															</Box>

															<Box
																sx={{
																	display: "flex",
																	flexDirection: onlySmallScreen
																		? "column"
																		: "row",
																	alignItems: "center",
																}}
															>
																{user?.is_superuser && (
																	<Box
																		sx={{
																			display: "flex",
																			flexDirection: isHistorical
																				? onlyLargeScreen
																					? "column"
																					: "row"
																				: onlyMediumScreen
																				? "row"
																				: "column",
																			gap: 2,
																			overflow: "hidden",
																			marginTop: onlySmallScreen ? 2 : 0,
																			marginLeft: 2,
																		}}
																	>
																		<Button
																			variant="contained"
																			size="small"
																			disabled={
																				/*(!showSales && !estimation.checklist_document) ||*/
																				isLoadingUpdateProductLCAVersionEmission ||
																				lcaVersion?.approved_date ===
																					undefined ||
																				lcaVersion?.approved_date === null ||
																				isLoadingToggleEstimation ||
																				(estimation.approved_date
																					? true
																					: false)
																			}
																			sx={{ minHeight: "29px" }}
																			onClick={async () => {
																				setApproveLoading(index);
																				setShowConfirmation(false);

																				if (certification.is_historical) {
																					for (const auxEstimation of certification.estimations) {
																						if (auxEstimation) {
																							// TO DO: HERE
																							await new Promise(
																								(resolve, reject) => {
																									mutateDocuments(
																										{
																											productId:
																												auxEstimation.product
																													.id,
																											productLCA:
																												auxEstimation.lca_document!,
																											productLCAId:
																												auxEstimation.lca_document!
																													.id,
																										},
																										{
																											onSuccess: () => {
																												resolve(true);
																											},
																											onError: (error) => {
																												console.log(error);
																												enqueueSnackbar(
																													"Something went wrong.",
																													{
																														variant: "error",
																													}
																												);
																												reject();
																											},
																										}
																									);
																								}
																							);

																							await new Promise(
																								(resolve, reject) => {
																									updateEstimationMutate(
																										{
																											id: auxEstimation.id,
																											amount:
																												auxEstimation.amount ??
																												0,
																										},
																										{
																											onSuccess: () => {
																												resolve(true);
																											},
																											onError: (error: any) => {
																												console.log(error);
																												enqueueSnackbar(
																													"Something went wrong.",
																													{
																														variant: "error",
																													}
																												);
																												reject();
																											},
																										}
																									);
																								}
																							);
																						}

																						for (const auxSale of auxEstimation
																							.product?.sales ?? []) {
																							await new Promise(
																								(resolve, reject) => {
																									updateSaleMutate(
																										{
																											id: auxSale.id,
																											amount: auxSale.amount,
																										},
																										{
																											onSuccess: () => {
																												resolve(true);
																											},
																											onError: (error: any) => {
																												console.log(error);
																												enqueueSnackbar(
																													"Something went wrong.",
																													{
																														variant: "error",
																													}
																												);
																												reject();
																											},
																										}
																									);
																								}
																							);
																						}
																					}
																				}

																				/**
																				 * If carbon footprint changed
																				 * update ProductLCAVersionEmissions
																				 * tell user
																				 */

																				for (const emissions of initialEmissions.filter(
																					(e) =>
																						lcaVersion &&
																						lcaVersion?.products.find(
																							(p) =>
																								e.product &&
																								p.id === e.product.id
																						) !== undefined
																				)) {
																					if (emissions && certification) {
																						let changed = false;

																						for (const product of lcaVersion?.products ??
																							[]) {
																							if (
																								product
																									.product_lca_version_emission
																									?.emissions !==
																								emissions?.emissions
																							) {
																								changed = true;
																							}
																						}
																						if (changed) {
																							await new Promise(
																								(resolve, reject) => {
																									mutateUpdateProductLCAVersionEmission(
																										{
																											id: emissions.id,
																											productLcaVersionEmission:
																												emissions.emissions!,
																											lcaVersionId:
																												lcaVersion!.id,
																											lcaId:
																												lcaVersion!.lca_id!,
																										},
																										{
																											onSuccess: () => {
																												resolve(true);
																											},
																											onError: (error: any) => {
																												console.log(error);
																												enqueueSnackbar(
																													"Something went wrong.",
																													{ variant: "error" }
																												);
																												reject(error);
																											},
																										}
																									);
																								}
																							);
																						}
																					}
																				}

																				updateToggleMutate({
																					...estimation,
																					approved_date:
																						new Date().toISOString(),
																					rejected_date: null,
																					reject_reason: null,
																					overwrite_certification_date:
																						estimation.overwrite_certification_date,
																				});
																			}}
																		>
																			{(isLoadingToggleEstimation ||
																				isLoadingUpdateEstimation ||
																				isLoadingUpdateSale ||
																				isLoadingUpdateProductLCAVersionEmission ||
																				isLoadingDocuments) &&
																			approveLoading === index ? (
																				<CircularProgress
																					size={12}
																					sx={{ color: "white" }}
																				/>
																			) : (
																				"Approve"
																			)}
																		</Button>
																		{/*<Button
													variant="outlined"
													size="small"
													disabled={
														(!showSales && !estimation.checklist_document) ||
														isLoadingToggleEstimation ||
														(estimation.rejected_date ? true : false)
													}
													sx={{
														minHeight: "29px",
														"&.Mui-disabled": {
															color: "#25406D",
															background: "white",
														},
													}}
													onClick={() => {
														setShowConfirmation(true);
														setConfirmationData({
															title: "Do you want to reject this product?",
															description: "The product will be rejected",
															textfield: "Rejected reason",
															callback: (rejectReason) => {
																setRejectLoading(index);
																updateToggleMutate({
																	...estimation,
																	approved_date: null,
																	rejected_date: new Date().toISOString(),
																	reject_reason: rejectReason,
																});
																setShowConfirmation(false);
															},
														});
													}}
												>
													{isLoadingToggleEstimation &&
													rejectLoading === index ? (
														<CircularProgress
															size={12}
															sx={{ color: "white" }}
														/>
													) : (
														"Reject"
													)}
												</Button>*/}
																		{user?.is_superuser && (
																			<>
																				<Button
																					variant="outlined"
																					size="small"
																					sx={{
																						minHeight: "29px",
																						"&.Mui-disabled": {
																							color: "#25406D",
																							background: "white",
																						},
																					}}
																					disabled={
																						lcaVersion?.approved_date ===
																							undefined ||
																						lcaVersion?.approved_date === null
																					}
																					onClick={() => {
																						setShowConfirmation(true);

																						setConfirmationData({
																							title:
																								"Do you want to remove this product from this certification process?",
																							description:
																								"The product will be remove from this certification",
																							textfield: "",
																							callback: () => {
																								deleteEstimationMutate(
																									estimation
																								);
																								setShowConfirmation(false);
																							},
																						});
																					}}
																				>
																					{isLoadingDeleteEstimation ? (
																						<CircularProgress
																							size={12}
																							sx={{ color: "white" }}
																						/>
																					) : (
																						"Remove"
																					)}
																				</Button>
																			</>
																		)}
																	</Box>
																)}
															</Box>
														</Box>
													</Box>
													{estimation.reject_reason && (
														<Alert
															severity="error"
															sx={{ marginTop: 2, marginBottom: 2 }}
														>
															{estimation.reject_reason}
															{" -> "}
															<span
																style={{
																	textDecoration: "underline",
																	cursor: "pointer",
																}}
																onClick={() =>
																	navigate(`/lcas/${lcaVersion?.lca_id}`)
																}
															>
																Go to LCA
															</span>
														</Alert>
													)}
													<Divider
														sx={{
															marginTop: 2,
															marginBottom: 2,
															opacity: 0.5,
														}}
													/>
												</Box>
											);
										})}
								</Box>
							</Box>
						</LCACollapsible>
					</Box>
				);
			})}
			{user &&
				user.is_superuser &&
				!certification.certification_process_steps[currentStep]
					.reject_reason && (
					<Box
						sx={{
							display: "flex",
							marginTop: 4,
							justifyContent: "flex-end",
						}}
					>
						{certification?.certification_process_steps[currentStep].step
							.step_type === "CERTIFICATION_SUMMARY" &&
							user?.is_superuser &&
							(!certification.company.first_time_fee_paid ||
								(certification.company.first_time_fee_paid &&
									new Date(
										certification.company.first_time_fee_paid
									).getTime() <
										new Date().getTime() - 365 * 24 * 60 * 60 * 1000)) &&
							!certification?.requested_approval &&
							Math.ceil(
								certification?.estimations.reduce((prev, current) => {
									return (
										prev +
										Math.ceil(
											(current.product_lca_version_emission?.emissions ?? 0) *
												(!isNaN(current.amount ?? 0) && current.amount
													? current.amount
													: 0) *
												0.001
										)
									);
								}, 0) ?? 0
							) *
								12.5 +
								certification?.estimations.length * 250 +
								1000 <
								5000 && (
								<Typography sx={{ marginRight: 2 }}>
									<small
										style={{
											color: "#EB4B4B",
											fontWeight: "bold",
										}}
									>
										User hasn't agreed on requesting approval yet
									</small>
								</Typography>
							)}
						{!showSales && (
							<Button
								variant="contained"
								sx={{ minHeight: 34 }}
								disabled={
									isLoadingUpdateCertificationStep ||
									(certification.lca_versions ?? []).find(
										(l) =>
											l.approved_date === undefined || l.approved_date === null
									) !== undefined ||
									certification.estimations.length === 0 ||
									certification.estimations.find(
										(estimation) => !estimation.approved_date
									)
										? true
										: false
								}
								onClick={() => {
									setShowConfirmation(true);
									setManualApproving(false);
									setConfirmationData({
										title: "Do you want to approve this certification?",
										description:
											"Once you confirm, the certification will be approved",
										textfield: null,
										callback: () => {
											updateCertificationStepMutate({
												status: "COMPLETED",
											});
											setShowConfirmation(false);
										},
									});
								}}
							>
								{(isLoadingUpdateCertificationStep ||
									isLoadingUpdateSale ||
									isLoadingUpdateEstimation) &&
								!manualApproving ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									"Approve"
								)}
							</Button>
						)}
						<Button
							variant="outlined"
							sx={{
								minHeight: 34,
								minWidth: 141,
								marginLeft: 2,
								"&.Mui-disabled": {
									color: "#25406D",
									background: "white",
								},
							}}
							disabled={
								isLoadingUpdateCertificationStep ||
								(certification.lca_versions ?? []).find(
									(l) =>
										l.approved_date === undefined || l.approved_date === null
								) !== undefined ||
								certification.estimations.length === 0 ||
								certification.estimations.find(
									(estimation) => !estimation.approved_date
								)
									? true
									: false
							}
							onClick={() => {
								setShowConfirmation(true);
								setManualApproving(true);
								setConfirmationData({
									title: "Do you want to manually approve this certification?",
									description:
										"Once you confirm, the certification will be approved",
									textfield: null,
									callback: () => {
										updateCertificationStepMutate({
											status: "COMPLETED",
											rejectReason: undefined,
											manualApprove: true,
										});
										setShowConfirmation(false);

										if (!user.is_superuser && setShowHistoricalInfo) {
											setShowHistoricalInfo(true);
										}
									},
								});
							}}
						>
							{isLoadingUpdateCertificationStep && manualApproving ? (
								<CircularProgress size={12} sx={{ color: "#25406D" }} />
							) : (
								"Manual Approve"
							)}
						</Button>
					</Box>
				)}
		</>
	);
}
