import { Typography, Box, Button, List, ListItem } from "@mui/material";

import { PropTypes } from "./LCARecommendations.types";

export function LCARecommendations({ setLCARecommendations }: PropTypes) {
	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 4 }}
			>
				What is an LCA?
			</Typography>
			<Typography sx={{ marginBottom: 2 }}>
				A Life Cycle Assessment (LCA) is a methodology for assessing
				environmental impacts associated with all stages of a product's life
				cycle from cradle to grave. Please use this link to find an LCA Provider
				in your country.
			</Typography>

			<List>
				<ListItem
					disablePadding
					sx={{
						marginBottom: 2,
						gap: 2,
						display: "block",
						"& a": { whiteSpace: "break-spaces" },
					}}
				>
					-{" "}
					<a
						href="https://www.environdec.com/resources/lca-consultants"
						target="_blank"
						rel="noreferrer"
					>
						(LIST: LCA Consultants | EPD International (environdec.com))
					</a>
				</ListItem>
			</List>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
					}}
					onClick={() => {
						setLCARecommendations(false);
					}}
				>
					OK
				</Button>
			</Box>
		</Box>
	);
}
