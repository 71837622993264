import * as React from "react";
import { Typography, Box, Button } from "@mui/material";
import { PropTypes } from "./CertificateProduct.types";

export function CertificateProduct(props: PropTypes) {
	const { goToProducts, goToCertification, addProduct, firstTime } = props;
	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				{firstTime
					? "Certify your first product(s)"
					: "Proceed to certify your product(s)"}
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				{!firstTime && (
					<>
						<Button
							variant="outlined"
							sx={{
								marginTop: 2,
								maxWidth: 300,
								alignSelf: "end",
								marginRight: 2,
							}}
							onClick={() => {
								goToProducts();
							}}
						>
							Cancel
						</Button>
						<Button
							variant="outlined"
							sx={{
								marginTop: 2,
								maxWidth: 300,
								alignSelf: "end",
								marginRight: 2,
							}}
							onClick={() => {
								addProduct();
							}}
						>
							Add new product
						</Button>
					</>
				)}
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
					}}
					onClick={() => {
						goToCertification();
					}}
				>
					Certify
				</Button>
			</Box>
		</Box>
	);
}
