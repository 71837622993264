import {
	Autocomplete,
	Box,
	TextField,
	Typography,
	Button,
} from "@mui/material";
import React, { useState } from "react";
import { PropTypes } from "./ChooseProvider.types";
import { useQuery } from "react-query";
import { certification as certificationAction } from "../../controllers";
import { useSnackbar } from "notistack";

type Provider = {
	id: number;
	name: string;
	certification_type: string;
};

export default function ChooseProvider({
	selectedProvider,
	setSelectedProvider,
	mutate,
	providerTypes,
}: PropTypes) {
	const [providers, setProviders] = useState<Provider[]>([]);
	const { enqueueSnackbar } = useSnackbar();

	useQuery(
		"providers",
		() =>
			certificationAction.listProviders(
				// TO BE FIXED
				providerTypes ? { certification_type: providerTypes[0] } : {}
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProviders(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: {
						xs: "calc(100% - 82px)",
						sm: "100%",
					},
				}}
			>
				<Typography
					variant="h5"
					sx={{
						fontWeight: 700,
					}}
				>
					Choose the external provider for this certification
				</Typography>
				<Typography sx={{ marginTop: 1, marginBottom: 4 }} variant="subtitle1">
					Select here the provider for the new certification you want to start.
				</Typography>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						options={providers}
						getOptionLabel={(option) => option.name}
						sx={{ width: 300 }}
						value={selectedProvider}
						onChange={(_, value) => {
							setSelectedProvider(value);
						}}
						renderInput={(params) => (
							<TextField {...params} placeholder="Providers" />
						)}
					/>
				</Box>
			</Box>
			<Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
				<Button
					variant="contained"
					onClick={() => {
						mutate();
					}}
					sx={{ marginTop: 8, justifySelf: "flex-end" }}
					disabled={!selectedProvider}
				>
					CREATE
				</Button>
			</Box>
		</>
	);
}
