import React from "react";
import { Box, Typography } from "@mui/material";
import { PropTypes } from "./ProductStatus.types";

export function ProductStatus({ title, subtitle, status }: PropTypes) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				border: "1px solid #efefef",
				padding: 2,
				gap: 2,
			}}
		>
			<Box>
				<Typography>{title}</Typography>
				<Typography>{subtitle}</Typography>
			</Box>
			<Box
				sx={{
					width: 12,
					height: 12,
					borderRadius: "50%",
					backgroundColor:
						status === "completed" || status === "active"
							? "#6ab04c"
							: status === "completed" || status === "active"
							? "#eb4b4b"
							: "#f9ca24",
				}}
			></Box>
		</Box>
	);
}
