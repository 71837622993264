import { Box, Typography } from "@mui/material";
import Avatar from "react-avatar";
import { PropTypes } from "./User.types";
import { MoreButton } from "../MoreButton";
import { connectionsClient } from "../../utils/ConnectionsClient";
import { useContext } from "react";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";

export function User(props: PropTypes) {
	const { name, company } = props;
	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();

	const logout = () => {
		connectionsClient.updateTokens({
			access: null,
			refresh: null,
			userId: null,
		});
		setUser(null);
		navigate("/");
		window.location.href = window.location.origin;
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "left",
					alignItems: "center",
					flex: 1,
				}}
			>
				<Avatar
					name={name}
					size="34"
					round="6px"
					style={{ fontSize: "18px", marginRight: "12px" }}
				></Avatar>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "left",
						alignItems: "flex-start",
						flex: 1,
						textAlign: "left",
					}}
				>
					<Typography
						variant="body2"
						gutterBottom
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							"-webkit-box-orient": "vertical",
							"-webkit-line-clamp": "1",
							marginBottom: 0,
							wordBreak: "break-word",
							fontSize: 12,
						}}
					>
						{name}
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							"-webkit-box-orient": "vertical",
							"-webkit-line-clamp": "1",
							marginBottom: 0,
							textTransform: "capitalize",
							wordBreak: "break-word",
						}}
					>
						{company}
					</Typography>
				</Box>
			</Box>
			<MoreButton
				menuOptions={[
					{
						label: "Go to profile",
						callback: () => navigate("/profile"),
					},
					{
						label: "Go to company profile",
						callback: () => navigate("/company-profile"),
					},
					{
						label: "Logout",
						callback: logout,
					},
				]}
			/>
		</Box>
	);
}
