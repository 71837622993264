import { useState, useEffect, useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
} from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { user as userActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { IEntity, IUser } from "../../models";
import { UserContext } from "../../contexts/user";
import { UserActionBar } from "../UserActionBar";
import { Filters } from "../../routes/Routes.types";

export function Users() {
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser ? { companies: [] } : {}),
		certifications: [],
	});
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: "updated_at",
			sort: "desc",
		})
	);
	const [rowCount, setRowCount] = useState<number>(0);
	const [products, setProducts] = useState<Array<IUser>>([]);

	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"users",
		() =>
			userActions.list(
				!user?.is_superuser ? user?.company.id : undefined,
				search,
				filters.companies,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProducts(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	const columns = [
		{
			field: "id",
			headerName: "ID",
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
		},
		{ field: "first_name", headerName: "Name", flex: 1, minWidth: 150 },
		{ field: "last_name", headerName: "Last Name", flex: 1, minWidth: 150 },
		{ field: "email", headerName: "Email", flex: 1, minWidth: 150 },
		{
			field: "company",
			headerName: "Company",
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<IUser>) => {
				return params.row.company?.name ?? "--";
			},
		},
		{
			field: "action",
			headerName: "Actions",
			sortable: false,
			headerAlign: "right",
			align: "right",
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					navigate("/user/" + row.id);
				};

				return (
					<>
						<Button onClick={onClickEdit}>Edit</Button>
					</>
				);
			},
		},
	];

	return (
		<Box>
			<Heading
				title="Users"
				showSearch={user && user!.is_superuser ? true : false}
				setSearch={setSearch}
				actions={() => {
					return user && user!.is_superuser ? (
						<UserActionBar
							selectedRows={selectedRows}
							refetch={refetch}
							filters={filters}
							setFilters={setFilters}
						/>
					) : (
						<></>
					);
				}}
			/>
			{user?.is_superuser ? (
				<DataTable
					rows={products}
					columns={columns}
					setSelectedRows={setSelectedRows}
					rowCount={rowCount}
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
					sortModel={sortModel}
					setSortModel={setSortModel}
					isLoading={isLoading || isRefetching || isFetching}
				/>
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						marginTop: "-32px",
					}}
				>
					<Box
						sx={{
							textAlign: "center",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							width: 600,
							padding: 4,
						}}
					>
						<Typography
							variant="body2"
							sx={{ marginBottom: 2, fontSize: 18, fontWeight: 700 }}
						>
							Coming soon
						</Typography>
						<Typography variant="body2" sx={{ marginBottom: 2 }}>
							Soon, you will also be able to manage several users for your
							company account. In the meantime, continue using our platform.
						</Typography>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								flexDirection: {
									xs: "column",
									sm: "row",
								},
							}}
						>
							<Button
								variant="outlined"
								onClick={() => {
									navigate("/lcas");
								}}
								sx={{ width: 175 }}
							>
								Add new LCA(s)
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									navigate("/climeco-certifications");
								}}
								sx={{ width: 175 }}
							>
								Certify with ClimeCo
							</Button>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
}
