import { useState, useEffect, useContext } from "react";
import {
	Autocomplete,
	Box,
	CircularProgress,
	Grid,
	TextField,
} from "@mui/material";
import { PropTypes } from "./AdminAnalytics.types";
import { Heading } from "../Heading";
import { useQuery } from "react-query";
import { dashboard as dashboardActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { InfoAmount } from "../InfoAmount";
import { InfoChart } from "../InfoChart";
import { ICohort } from "../../models";
import { DashboardTypes } from "../../controllers/dashboard/dashboard.types";
import { CompanySteps } from "../CompanySteps";

const debounce = <F extends (...args: any[]) => void>(
	func: F,
	delay: number
): ((...args: Parameters<F>) => void) => {
	let timer: NodeJS.Timeout | null = null;
	return (...args: Parameters<F>): void => {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

export function AdminAnalytics({ title }: PropTypes) {
	const { user } = useContext(UserContext);

	const { enqueueSnackbar } = useSnackbar();

	const [productApproval, setProductApproval] = useState({
		title: "Product Approval",
		subtitle: "Time first product to submitting the product for approval",
		value: "0 min",
		percentage: 0,
	});

	const [productsInCertification, setProductInCertification] = useState({
		title: "Products in Certification",
		subtitle: "Amount of current products in Certification",
		value: "0 products",
		percentage: 0,
	});

	const [productSubmittedToApproval, setProductSubmittedToApproval] = useState({
		title: "Product Submitted to Approval",
		subtitle:
			"Time it takes after the customer submitted for approval to when those products were approved",
		value: "0 min",
		percentage: 0,
	});

	const [productApprovalAdmin, setProductApprovalAdmin] = useState({
		title: "Product Approval Admin",
		subtitle: "Time it takes to admin between approving each product",
		value: "0 min",
		percentage: 0,
	});

	const [revenue, setRevenue] = useState({
		title: "Revenue",
		subtitle: "Revenue from the beginning of the time",
		value: "$0",
		percentage: 0,
	});

	const [offsetsRevenue, setOffsetsRevenue] = useState({
		title: "Offsets Revenue",
		subtitle: "Offsets Revenue from the beginning of the time",
		value: "0",
		percentage: 0,
	});

	const [productsRevenue, setProductsRevenue] = useState({
		title: "Products Revenue",
		subtitle: "Products Revenue from the beginning of the time",
		value: "0",
		percentage: 0,
	});

	const [registrationRevenue, setRegistrationRevenue] = useState({
		title: "Registration Revenue",
		subtitle: "Registration Revenue from the beginning of the time",
		value: "0",
		percentage: 0,
	});

	const [productsCertified, setProductsCertified] = useState<
		Array<{ day: string; count: number }>
	>([]);
	const [offsetsSold, setOffsetsSold] = useState<
		Array<{ day: string; count: number }>
	>([]);

	const [cohorts, setCohorts] = useState<Array<ICohort> | null>(null);
	const [selectedCohorts, setSelectedCohorts] = useState<Array<ICohort> | null>(
		null
	);
	const [cohortsSearch, setCohortsSearch] = useState("");
	const [companySteps, setCompanySteps] =
		useState<DashboardTypes.CompanySteps | null>(null);

	const {
		refetch: refetchDashboardProductCreationToRequestApprovalAverageTime,
	} = useQuery(
		"products-creation-to-request-approval-average-time",
		() =>
			dashboardActions.productCreationToRequestApprovalAverageTime(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: ({ current, previous }) => {
				setProductApproval(
					(prev: {
						title: string;
						subtitle: string;
						value: string;
						percentage: number;
					}) => {
						prev["value"] =
							Intl.NumberFormat("en", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 0,
							}).format(current / 1000 / 60) + " min";
						prev["percentage"] =
							previous === 0
								? current > 0
									? 100
									: 0
								: (current - previous) / previous;
						return prev;
					}
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchDashboardProductsInCertification } = useQuery(
		"products-in-certification",
		() =>
			dashboardActions.productsInCertification(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: ({ current, previous }) => {
				setProductInCertification(
					(prev: {
						title: string;
						subtitle: string;
						value: string;
						percentage: number;
					}) => {
						prev["value"] = current + " products";
						prev["percentage"] =
							previous === 0
								? current > 0
									? 100
									: 0
								: (current - previous) / previous;
						return prev;
					}
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const {
		refetch: refetchDashboardProductRequestApprovalToApprovalAverageTime,
	} = useQuery(
		"products-request-approval-to-approval-average-time",
		() =>
			dashboardActions.productRequestApprovalToApprovalAverageTime(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: ({ current, previous }) => {
				setProductSubmittedToApproval(
					(prev: {
						title: string;
						subtitle: string;
						value: string;
						percentage: number;
					}) => {
						prev["value"] =
							Intl.NumberFormat("en", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 0,
							}).format(current / 1000 / 60) + " min";
						prev["percentage"] =
							previous === 0
								? current > 0
									? 100
									: 0
								: (current - previous) / previous;

						return prev;
					}
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchAdminProductApprovalAverageTime } = useQuery(
		"products-admin-approval-average-time",
		() =>
			dashboardActions.adminProductApprovalAverageTime(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: ({ current, previous }) => {
				setProductApprovalAdmin(
					(prev: {
						title: string;
						subtitle: string;
						value: string;
						percentage: number;
					}) => {
						prev["value"] =
							Intl.NumberFormat("en", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 0,
							}).format(current / 1000 / 60) + " min";
						prev["percentage"] =
							previous === 0
								? current > 0
									? 100
									: 0
								: (current - previous) / previous;

						return prev;
					}
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchRevenue } = useQuery(
		"admin-revenue",
		() => dashboardActions.revenue(selectedCohorts?.map((cohort) => cohort.id)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: ({ current, previous }) => {
				setRevenue(
					(prev: {
						title: string;
						subtitle: string;
						value: string;
						percentage: number;
					}) => {
						prev["value"] = Intl.NumberFormat("en", {
							maximumFractionDigits: 2,
							minimumFractionDigits: 0,
							currency: "usd",
							style: "currency",
						}).format(current);
						prev["percentage"] =
							previous === 0
								? current > 0
									? 100
									: 0
								: (current - previous) / previous;

						return prev;
					}
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const {
		refetch: refetchProductsCertified,
		isLoading: isLoadingProductsCertified,
	} = useQuery(
		"products-certified",
		() =>
			dashboardActions.productsCertified(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res: Array<{ day: string; count: number }>) => {
				setProductsCertified(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchOffsetsSold, isLoading: isLoadingOffsetsSold } =
		useQuery(
			"offsets-sold-chart",
			() =>
				dashboardActions.offsetsSold(
					selectedCohorts?.map((cohort) => cohort.id)
				),
			{
				enabled: false,
				refetchOnWindowFocus: false,
				retry: false,
				onSuccess: (res: Array<{ day: string; count: number }>) => {
					setOffsetsSold(res);
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong", { variant: "error" });
				},
			}
		);

	const { refetch: refetchOffsetsRevenue } = useQuery(
		"admin-offsets-revenue",
		() =>
			dashboardActions.offsetsRevenue(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: ({ current, previous }) => {
				setOffsetsRevenue(
					(prev: {
						title: string;
						subtitle: string;
						value: string;
						percentage: number;
					}) => {
						prev["value"] = Intl.NumberFormat("en", {
							maximumFractionDigits: 2,
							minimumFractionDigits: 0,
							currency: "usd",
							style: "currency",
						}).format(current);
						prev["percentage"] =
							previous === 0
								? current > 0
									? 100
									: 0
								: (current - previous) / previous;

						return prev;
					}
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchProductsRevenue } = useQuery(
		"admin-products-revenue",
		() =>
			dashboardActions.productsRevenue(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: ({ current, previous }) => {
				setProductsRevenue(
					(prev: {
						title: string;
						subtitle: string;
						value: string;
						percentage: number;
					}) => {
						prev["value"] = Intl.NumberFormat("en", {
							maximumFractionDigits: 2,
							minimumFractionDigits: 0,
							currency: "usd",
							style: "currency",
						}).format(current);
						prev["percentage"] =
							previous === 0
								? current > 0
									? 100
									: 0
								: (current - previous) / previous;

						return prev;
					}
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchRegistrationRevenue } = useQuery(
		"admin-registration-revenue",
		() =>
			dashboardActions.registrationRevenue(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: ({ current, previous }) => {
				setRegistrationRevenue(
					(prev: {
						title: string;
						subtitle: string;
						value: string;
						percentage: number;
					}) => {
						prev["value"] = Intl.NumberFormat("en", {
							maximumFractionDigits: 2,
							minimumFractionDigits: 0,
							currency: "usd",
							style: "currency",
						}).format(current);
						prev["percentage"] =
							previous === 0
								? current > 0
									? 100
									: 0
								: (current - previous) / previous;

						return prev;
					}
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchCohorts, isLoading: isLoadingCohorts } = useQuery(
		"admin-cohorts",
		() => dashboardActions.cohorts(cohortsSearch),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (result) => {
				setCohorts(result);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchCompaniesSteps } = useQuery(
		"companies-steps",
		() =>
			dashboardActions.companiesSteps(
				selectedCohorts?.map((cohort) => cohort.id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanySteps(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.company?.id) {
			refetchDashboardProductCreationToRequestApprovalAverageTime();
			refetchDashboardProductsInCertification();
			refetchDashboardProductRequestApprovalToApprovalAverageTime();
			refetchAdminProductApprovalAverageTime();
			refetchRevenue();
			refetchProductsCertified();
			refetchOffsetsSold();
			refetchOffsetsRevenue();
			refetchProductsRevenue();
			refetchRegistrationRevenue();
			refetchCohorts();
			refetchCompaniesSteps();
		}
	}, [
		user,
		refetchDashboardProductCreationToRequestApprovalAverageTime,
		refetchDashboardProductsInCertification,
		refetchDashboardProductRequestApprovalToApprovalAverageTime,
		refetchAdminProductApprovalAverageTime,
		refetchRevenue,
		refetchProductsCertified,
		refetchOffsetsSold,
		refetchOffsetsRevenue,
		refetchProductsRevenue,
		refetchRegistrationRevenue,
		refetchCohorts,
		refetchCompaniesSteps,
		selectedCohorts,
	]);

	const handleSearchChange = (value: string) => {
		setCohortsSearch(value);
	};

	const debouncedSearchChange = debounce(handleSearchChange, 500);

	return (
		<Box sx={{ marginTop: 6 }}>
			<Heading
				title={title}
				showSearch={false}
				actions={() => (
					<Box
						sx={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
					>
						<Autocomplete
							disablePortal
							id="company-box"
							options={cohorts ?? []}
							sx={{
								width: {
									xs: 240,
									sm: 240,
									md: 240,
									lg: 240,
									xl: 300,
								},
								"& .MuiInputBase-root": {
									padding: "0px 0px 0px 8px",
									overflow: "auto",
									maxHeight: "38px",
								},
							}}
							multiple
							getOptionLabel={(cohort) => cohort?.name ?? ""}
							/*value={filters.companies!.map((company) =>
								companies.find((auxCompany) => auxCompany.id === company)
							)}*/
							onChange={(_, value) => {
								setSelectedCohorts(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									placeholder="Cohort"
									sx={{
										maxHeight: 56,
										overflow: "auto!important",
										border: "1px solid #ececec",
										borderRadius: 2,
										backgroundColor: "#fff",
										"& input": {
											fontSize: 14,
										},
									}}
									onChange={(ev) => {
										// dont fire API if the user delete or not entered anything
										if (ev.target.value !== "" || ev.target.value !== null) {
											debouncedSearchChange(ev.target.value);
										}
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{isLoadingCohorts ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</Box>
				)}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={3}>
					<InfoAmount
						title={productApproval.title}
						subtitle={productApproval.subtitle}
						value={productApproval.value}
						percentage={productApproval.percentage}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<InfoAmount
						title={productsInCertification.title}
						subtitle={productsInCertification.subtitle}
						value={productsInCertification.value}
						percentage={productsInCertification.percentage}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<InfoAmount
						title={productSubmittedToApproval.title}
						subtitle={productSubmittedToApproval.subtitle}
						value={productSubmittedToApproval.value}
						percentage={productSubmittedToApproval.percentage}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<InfoAmount
						title={productApprovalAdmin.title}
						subtitle={productApprovalAdmin.subtitle}
						value={productApprovalAdmin.value}
						percentage={productApprovalAdmin.percentage}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<InfoAmount
						title={revenue.title}
						subtitle={revenue.subtitle}
						value={revenue.value}
						percentage={revenue.percentage}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<InfoAmount
						title={offsetsRevenue.title}
						subtitle={offsetsRevenue.subtitle}
						value={offsetsRevenue.value}
						percentage={offsetsRevenue.percentage}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<InfoAmount
						title={productsRevenue.title}
						subtitle={productsRevenue.subtitle}
						value={productsRevenue.value}
						percentage={productsRevenue.percentage}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<InfoAmount
						title={registrationRevenue.title}
						subtitle={registrationRevenue.subtitle}
						value={registrationRevenue.value}
						percentage={registrationRevenue.percentage}
					/>
				</Grid>
			</Grid>
			<Box sx={{ marginTop: 2 }}></Box>
			<Grid sx={{ marginTop: 2 }} container spacing={2}>
				<Grid item xs={12} sm={12} md={6}>
					<InfoChart
						title="Certified Products"
						data={productsCertified}
						isLoading={isLoadingProductsCertified}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<InfoChart
						title="Offsets sold"
						data={offsetsSold}
						isLoading={isLoadingOffsetsSold}
					/>
				</Grid>
			</Grid>
			<Box sx={{ marginTop: 4 }}>
				<Heading title={"Company process steps"} showSearch={false} />

				<CompanySteps companySteps={companySteps} />
			</Box>
		</Box>
	);
}
