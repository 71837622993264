import { Button, Box, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 16,
		marginBottom: 4,
		fontWeight: 700,
		color: "#686868",
	},
	fileContainer: {
		backgroundColor: "#fafafa",
		maxHeight: 53,
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	fileName: {
		textAlign: "center",
		width: 150,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		direction: "rtl",
	},
});

export function UploadFile({
	padding,
	flex,
	accept,
	value,
	disabled,
	loading,
	onFileChange,
}: {
	padding: number;
	flex?: number;
	accept: string;
	value: File | string | null | undefined;
	disabled?: boolean;
	loading?: boolean;
	onFileChange: (file: File | null) => void;
}) {
	const classes = useStyles();
	const [draggingOver, setDraggingOver] = useState<boolean>(false);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [uniqueId] = useState(Math.random());
	const { enqueueSnackbar } = useSnackbar();
	const input = useRef(null);

	const handleFileChange = (files: FileList | null) => {
		if (files && files.length > 0) {
			const file = files[0];
			// 10MB
			if (file.size > 10485760) {
				enqueueSnackbar("The file must be under 10mb", {
					variant: "error",
				});
			} else {
				const renameFile = (originalFile: File, newName: string): File => {
					return new File([originalFile], newName, {
						type: originalFile.type,
						lastModified: originalFile.lastModified,
					});
				};
				const renamedFile = renameFile(file, file.name.slice(0, 85));
				setSelectedFile(renamedFile);
				onFileChange(renamedFile);
			}
		} else {
			setSelectedFile(null);
			onFileChange(null as unknown as File);
		}
	};

	const handleFileChangeEvent = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const files = event.target.files;
		handleFileChange(files);
	};

	const handleDrop = (event: React.DragEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const droppedFiles = event.dataTransfer.files;
		handleFileChange(droppedFiles);
		setDraggingOver(false);
	};

	const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setDraggingOver(true);
	};
	const handleDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setDraggingOver(false);
	};

	useEffect(() => {
		if (!value && input.current) {
			setSelectedFile(null);
			(input.current as HTMLInputElement).value = "";
		}
	}, [value, input]);

	return (
		<>
			<Box
				className={classes.fileContainer}
				sx={{ padding: padding || "12px", flex: flex }}
			>
				<label htmlFor={"file-upload" + uniqueId}>
					<Button
						variant="outlined"
						component="span"
						sx={{
							minWidth: 120,
							maxWidth: 300,
							minHeight: 34,
							cursor: draggingOver ? "cell" : "pointer",
						}}
						disabled={disabled}
						onDrop={handleDrop}
						onDragOver={handleDragOver}
						onDragLeave={handleDragLeave}
					>
						{loading ? <CircularProgress size={12} /> : "Upload file"}
					</Button>
				</label>
				<input
					ref={input}
					type="file"
					id={"file-upload" + uniqueId}
					accept={accept}
					style={{ display: "none" }}
					onChange={handleFileChangeEvent}
				/>
				<Typography
					variant="body2"
					sx={{ textAlign: "center", marginLeft: 1 }}
					className={classes.fileName}
				>
					{selectedFile ? (
						selectedFile.name
					) : value ? (
						typeof value == "object" ? (
							value.name
						) : (
							<a href={value} target="_blank" rel="noreferrer">
								{value.split("/")[value.split("/").length - 1]}
							</a>
						)
					) : (
						"No file. (10MB max.)"
					)}
				</Typography>
			</Box>
		</>
	);
}
