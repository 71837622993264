import { useContext } from "react";
import { AppBar, Toolbar, IconButton, Chip, Box } from "@mui/material";
import Logo from "../../assets/logo.png";
import { HiMenuAlt3 as MenuIcon } from "react-icons/hi";
import { makeStyles } from "@mui/styles";
import { PropTypes } from "./TopBar.types";
import { UserContext } from "../../contexts/user";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const useStyles = makeStyles({
	logo: {
		height: 34,
	},
});

export function TopBar(props: PropTypes) {
	const classes = useStyles();
	const { user } = useContext(UserContext);
	const { handleDrawerToggle, mobileOpen, drawerWidth } = props;

	return (
		<AppBar
			position="fixed"
			elevation={0}
			sx={{
				width: { xs: `calc(100% - ${mobileOpen ? drawerWidth : 0}px)` },
				ml: { xs: `${mobileOpen ? drawerWidth : 0}px` },
				backgroundColor: "#fafafa",
				alignItems: { md: "flex-end", xs: "space-between" },
			}}
		>
			<Toolbar
				sx={{
					justifyContent: "space-between",
					width: { sx: "calc(100% - 32px)", md: "100%" },
				}}
			>
				<IconButton
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{
						fontSize: 18,
						mr: 2,
						visibility: { xs: mobileOpen ? "hidden" : "visible" },
					}}
				>
					<MenuIcon />
				</IconButton>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContext: "flex-end",
						gap: 2,
					}}
				>
					{user?.is_superuser && (
						<Chip
							sx={{
								backgroundColor: "#03b093",
								color: "#fff",
								fontWeight: "bold",
								"& .MuiChip-icon": {
									color: "#25406D!important",
								},
							}}
							icon={<AdminPanelSettingsIcon />}
							label="ADMIN"
						/>
					)}
					<img src={Logo} alt="climeco" className={classes.logo} />
				</Box>
			</Toolbar>
		</AppBar>
	);
}
