import { useContext, useEffect, useState } from "react";
import { Drawer, Divider, Box, IconButton } from "@mui/material";
import { User, MenuItem } from "../../components";
import { PropTypes } from "./SideBar.types";
import { HiMenuAlt3 as MenuIcon } from "react-icons/hi";
import { UserContext } from "../../contexts/user";
import {
	TbLayoutDashboard as DashboardIcon,
	TbClipboardText as ProductIcon,
	TbCertificate as CertificateIcon,
	TbReportAnalytics as ReportIcon,
	TbUserSquareRounded as UserIcon,
	TbBuildingCommunity as CompanyIcon,
	TbNotification as NotificationIcon,
	TbFileDescription as DocumentIcon,
	TbListDetails as LCAIcon,
} from "react-icons/tb";
import { useLocation } from "react-router-dom";
const drawerWidth = 280;

export function SideBar({ mobileOpen, handleDrawerToggle }: PropTypes) {
	const { user } = useContext(UserContext);
	const location = useLocation();
	const [pathname, setPathname] = useState(location.pathname);

	useEffect(() => {
		setPathname(location.pathname);
	}, [location.pathname]);

	const pages = [
		{
			name: "Home",
			icon: (
				<DashboardIcon
					color={pathname === "/" ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></DashboardIcon>
			),
			link: "/",
			id: "dashboard",
		},
		{
			name: "LCA Library",
			icon: (
				<LCAIcon
					color={pathname.includes("/lca") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></LCAIcon>
			),
			link: "/lcas/",
			id: "lca",
		},
		{
			name: "Products",
			icon: (
				<ProductIcon
					color={pathname.includes("/product") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></ProductIcon>
			),
			link: "/products/",
			id: "products",
		},
		{
			name: "ClimeCo Certifications",
			icon: (
				<CertificateIcon
					color={
						pathname.includes("/climeco-certifications") ||
						pathname.includes("/climeco-certificationss")
							? "#25406D"
							: "inherit"
					}
					style={{ marginRight: 8, minWidth: 16 }}
				></CertificateIcon>
			),
			link: "/climeco-certifications/",
			id: "climeco-certifications",
		},
		{
			name: "External Certifications",
			icon: (
				<CertificateIcon
					color={pathname.includes("/certification") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></CertificateIcon>
			),
			link: "/certifications/",
			id: "certifications",
		},
		{
			name: "Sales Report",
			icon: (
				<ReportIcon
					color={pathname.includes("/sale") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></ReportIcon>
			),
			link: "/sales/",
			id: "sales",
		},
		{
			name: "Notifications",
			icon: (
				<NotificationIcon
					color={pathname.includes("/notification") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></NotificationIcon>
			),
			link: "/notifications/",
			id: "notifications",
		},
		{
			name: "Users",
			icon: (
				<UserIcon
					color={pathname.includes("/user") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></UserIcon>
			),
			link: "/users/",
			id: "users",
		},
	];

	if (user?.is_superuser) {
		pages.push({
			name: "Documents",
			icon: (
				<DocumentIcon
					color={pathname.includes("/document") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></DocumentIcon>
			),
			link: "/documents/",
			id: "documents",
		});

		pages.push({
			name: "Companies",
			icon: (
				<CompanyIcon
					color={pathname.includes("/compan") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></CompanyIcon>
			),
			link: "/companies/",
			id: "companies",
		});
	}

	const drawer = (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "space-between",
				padding: 2,
				textAlign: "center",
				height: "100%",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<IconButton
					aria-label="menu"
					sx={{ alignSelf: "flex-end", marginBottom: 2, fontSize: 18 }}
					onClick={() => handleDrawerToggle()}
				>
					<MenuIcon />
				</IconButton>
				<Box>
					{pages.map((page) => {
						return (
							<MenuItem
								key={page.name}
								title={page.name}
								icon={page.icon}
								link={page.link}
								selected={
									pathname === page.link ||
									pathname + "s" === page.link ||
									pathname === page.link.slice(0, page.link.length - 2) ||
									((pathname.includes(page.link) ||
										pathname.includes(page.link.slice(0, -2))) &&
										page.link !== "/" &&
										page.link !== "")
								}
								callback={() => {
									if (document.body.clientWidth <= 900) {
										handleDrawerToggle();
									}
								}}
							/>
						);
					})}
				</Box>
			</Box>

			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Divider sx={{ opacity: 0.3, margin: 2 }} />

				<Divider sx={{ opacity: 0.3, margin: 2 }} />
				<User name={user?.username ?? ""} company={user?.company?.name ?? ""} />
			</Box>
		</Box>
	);

	return (
		<>
			<Box
				component="nav"
				sx={{
					width: {
						md: mobileOpen ? drawerWidth : 0,
					},
					flexShrink: { md: 0 },
				}}
			>
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: {
							backgroundColor: "#fff",
							border: "none",
							borderRight: "1px solid #ececec",
						},
					}}
					sx={{
						display: { xs: "block", sm: "block", md: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="persistent"
					PaperProps={{
						sx: {
							backgroundColor: "#fff",
							border: "none",
							borderRight: "1px solid #ececec",
						},
					}}
					sx={{
						display: { xs: "none", sm: "none", md: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
					open={mobileOpen}
				>
					{drawer}
				</Drawer>
			</Box>
		</>
	);
}
