import * as React from "react";
import { Typography, Box, Button } from "@mui/material";
import { PropTypes } from "./DeleteConfirmation.types";

export function DeleteConfirmation(props: PropTypes) {
	const { title, handleDeleteModalClose, callback, decertifying } = props;

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				{decertifying
					? "Do you want to request decertification for this items?"
					: "Do you want to delete this items?"}
			</Typography>
			<Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
				The item <span style={{ fontStyle: "italic" }}>{title}</span> will be{" "}
				{decertifying ? "requested for decertification" : "deleted"}.
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="outlined"
					sx={{ marginTop: 2, maxWidth: 300, alignSelf: "end", marginRight: 2 }}
					onClick={() => {
						handleDeleteModalClose();
					}}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						backgroundColor: decertifying ? "#25406D" : "#e25757",
						"&:hover": {
							backgroundColor: decertifying ? "#25406D" : "#b44545",
						},
					}}
					onClick={() => {
						callback();
					}}
				>
					{decertifying ? "Request Decertification" : "Delete"}
				</Button>
			</Box>
		</Box>
	);
}
