import { IProductLCA, ISale } from "../../models";
import { PropTypes } from "./ListTable.types";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
export function ListTable({ headers, data }: PropTypes) {
	return (
		<TableContainer sx={{ marginTop: 2 }}>
			<Table sx={{ minWidth: 400 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						{headers.map((header) => (
							<TableCell key={header.title}>{header.title}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (
						<TableRow
							key={row.id}
							sx={{
								"&:last-child td, &:last-child th": { border: 0 },
							}}
						>
							{headers.map((header) => {
								const untypedRow = row as any;
								if (header.type === "download") {
									return (
										<TableCell component="th" scope="row">
											<a
												style={{ maxWidth: 270, display: "block" }}
												href={untypedRow[header.key] as string}
												download
											>
												{untypedRow[header.key] as string}
											</a>
										</TableCell>
									);
								} else if (header.type === "lca_document_emissions") {
									return (
										<TableCell component="th" scope="row">
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 4,
											}).format(
												(untypedRow[header.key] as IProductLCA).emissions ?? 0
											)}
										</TableCell>
									);
								} else if (header.type === "sales") {
									return (
										<TableCell component="th" scope="row">
											<Box sx={{ display: "flex", gap: 3 }}>
												{(untypedRow[header.key] as Array<ISale>)
													?.sort(
														(a, b) =>
															new Date(a.start_date).getTime() -
															new Date(b.start_date).getTime()
													)
													.map((sale: ISale) => {
														return (
															<Box key={sale.id}>
																<Typography fontSize={12} fontWeight={"bold"}>
																	{"Q" +
																		Math.ceil(
																			((new Date(
																				sale.start_date
																			).getUTCMonth() +
																				1) /
																				12) *
																				4
																		).toString() +
																		"-" +
																		new Date(sale.start_date)
																			.getUTCFullYear()
																			.toString()}
																</Typography>
																<Typography fontSize={12}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 4,
																	}).format(sale.lca_document?.emissions ?? 0)}
																</Typography>
															</Box>
														);
													})}
											</Box>
										</TableCell>
									);
								} else {
									return (
										<TableCell component="th" scope="row">
											{untypedRow[header.key]?.toString()}
										</TableCell>
									);
								}
							})}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
