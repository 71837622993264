import { useContext, useEffect, useState } from "react";
import {
	Autocomplete,
	Box,
	Button,
	Divider,
	TextField,
	Typography,
} from "@mui/material";
import { Heading } from "../Heading";
import { Stepper } from "../Stepper";
import { MultiItemSelector } from "..";
import { ICertification, IProduct } from "../../models";
//import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { certification as certificationActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";

export function CertificationProcess() {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);
	const [currentProducts, setCurrentProducts] = useState(Array<IProduct>());
	//const navigate = useNavigate();
	const [products] = useState<Array<IProduct>>([
		{
			id: 1,
			name: "Nordic table",
			description: "",
			status: "PENDING",
		},
		{
			id: 2,
			name: "Nordic chair",
			description: "",
			status: "PENDING",
		},
	]);

	const [certifications] = useState<Array<ICertification>>([
		{
			id: 1,
			name: "Amazon",
			certification_process_steps: [],
			estimations: [],
			company: {
				id: 0,
				name: "",
			},
		},
	]);

	const [climecoCertifications, setClimecoCertifications] = useState<
		Array<ICertification>
	>([]);

	const { refetch: refetchClimecoCertifications } = useQuery(
		"certifications",
		() => certificationActions.list(true, user?.company.id),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setClimecoCertifications(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetchClimecoCertifications();
	}, [refetchClimecoCertifications]);

	const [currentStep, setCurrentStep] = useState(0);
	const steps = [
		{
			id: 1,
			stepTitle: "ClimeCo Certifications",
			title: "Select your ClimeCo certification",
			description: "Select what certification you would like to start",
			type: "climeco_certification_dropdown",
			button: "Submit",
			callback: () => {
				setCurrentStep(1);
			},
		},
		{
			id: 1,
			stepTitle: "Certifications",
			title: "Select certification",
			description: "Select what kind of certification you would like to start",
			type: "certification_dropdown",
			button: "Submit",
			callback: () => {
				setCurrentStep(2);
			},
		},
		{
			id: 2,
			stepTitle: "Submit",
			title: "Submit certification",
			description: "Once you proceed, the certification process will start.",
			type: "certification_summary",
			button: "Submit",
			callback: () => {
				setCurrentStep(3);
			},
		},
		{
			id: 3,
			stepTitle: "Certification in progress",
			title: "Certification in progress",
			description:
				"Your certification has been submitted and it is now in progress.",
			type: "waiting",
			button: "Waiting",
			callback: () => {
				setCurrentStep(4);
			},
		},
		{
			id: 4,
			stepTitle: "Completed",
			title: "Certification completed",
			description:
				"Your certification has been completed. Here you can download it:",
			type: "download_certificate",
			button: "Done",
			callback: () => {
				//setCurrentStep(5);
			},
		},
	];

	return (
		<Box>
			{/*<Typography
				variant="body2"
				fontSize={10}
				marginBottom={2}
				sx={{ cursor: "pointer" }}
				onClick={() => {
					navigate("/certifications");
				}}
			>
				{"BACK"}
			</Typography>*/}
			<Heading title="Certifications" showSearch={false} />
			<Stepper
				currentStep={currentStep}
				lastStep={steps.length - 1}
				steps={steps.map((step) => step.stepTitle)}
			/>
			<Typography
				variant="h5"
				sx={{
					fontWeight: 700,

					marginTop: 6,
				}}
			>
				{steps[currentStep].title}
			</Typography>
			<Typography sx={{ marginTop: 1, marginBottom: 4 }} variant="subtitle1">
				{steps[currentStep].description}
			</Typography>
			{steps[currentStep].type === "multi_item_selector" && (
				<MultiItemSelector
					title="Select Product(s)"
					placeholderAutocomplete="Select Products"
					buttonTitle="Add product(s)"
					listTitle="Product(s) Selected"
					emptyState="No products applied yet"
					setFilter={() => {}}
					elements={products}
					selectedElements={currentProducts}
					setSelectedElements={(elements) =>
						setCurrentProducts(elements as Array<IProduct>)
					}
				/>
			)}
			{steps[currentStep].type === "textarea" && (
				<Box sx={{ flex: 1 }}>
					<TextField
						sx={{ width: "100%" }}
						autoFocus
						multiline
						rows={8}
						placeholder="Write here"
					/>
				</Box>
			)}
			{steps[currentStep].type === "product_list" && (
				<Box>
					{currentProducts.map((product, index) => {
						return (
							<Box
								sx={{ display: "flex", flexDirection: "column" }}
								key={product.id}
							>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Typography flex={1}>
										{product.name +
											(product.asin
												? " - " + product.asin
												: product.upc
												? " - " + product.upc
												: "")}
									</Typography>
									<TextField
										placeholder="kg of CO2e"
										sx={{ marginLeft: 2 }}
										type="number"
										onChange={(e) => {
											const auxProducts = [...currentProducts];
											auxProducts[index].emissions = parseInt(e.target.value);
											setCurrentProducts([...auxProducts]);
										}}
									/>
									<TextField
										placeholder="estimated sales"
										sx={{ marginLeft: 2 }}
										onChange={(e) => {
											const auxProducts = [...currentProducts];
											auxProducts[index].amount = parseInt(e.target.value);
											setCurrentProducts([...auxProducts]);
										}}
									/>
								</Box>
								<Divider sx={{ marginTop: 2, marginBottom: 2, opacity: 0.5 }} />
							</Box>
						);
					})}
					<Box
						sx={{
							backgroundColor: "#fafafa",
							padding: 4,
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Typography fontWeight={500}>Offsets to be purchased:</Typography>
						<Typography fontWeight={500}>
							{currentProducts.reduce((prev, current) => {
								return prev + (current.emissions ?? 0) * (current.amount ?? 0);
							}, 0)}
						</Typography>
					</Box>
				</Box>
			)}

			{steps[currentStep].type === "certification_dropdown" && (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						options={
							steps[currentStep].type === "certification_dropdown"
								? certifications
								: climecoCertifications
						}
						getOptionLabel={(option) => option.name}
						sx={{ width: 300 }}
						renderInput={(params) => (
							<TextField {...params} placeholder="Certifications" />
						)}
					/>
				</Box>
			)}
			{steps[currentStep].type === "climeco_certification_dropdown" && (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						options={
							steps[currentStep].type === "certification_dropdown"
								? certifications
								: climecoCertifications
						}
						getOptionLabel={(option) => option.name}
						sx={{ width: 300 }}
						renderInput={(params) => (
							<TextField {...params} placeholder="Certifications" />
						)}
					/>
				</Box>
			)}
			{steps[currentStep].type === "certification_summary" && (
				<Box>
					<Typography flex={1} sx={{ fontSize: 12, marginBottom: 2 }}>
						SUMMARY
					</Typography>
					<Box sx={{ backgroundColor: "#fafafa", padding: 4 }}>
						{currentProducts.map((product, index) => {
							return (
								<Box
									sx={{ display: "flex", flexDirection: "column" }}
									key={product.id}
								>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Typography flex={1}>
											{product.name +
												(product.asin
													? " - " + product.asin
													: product.upc
													? " - " + product.upc
													: "")}
										</Typography>
										<Typography sx={{ marginLeft: 2 }}>
											20 kg of CO2e
										</Typography>
										<Typography sx={{ marginLeft: 2 }}>
											202 estimated sales
										</Typography>
									</Box>
									<Divider
										sx={{ marginTop: 2, marginBottom: 2, opacity: 0.5 }}
									/>
								</Box>
							);
						})}
						<Box
							sx={{
								display: "flex",
								marginTop: 4,
								justifyContent: "space-between",
							}}
						>
							<Typography sx={{ fontWeight: 500 }}>
								Offsets to be purchased:
							</Typography>
							<Typography sx={{ fontWeight: 500 }}>23213</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								marginTop: 4,
								justifyContent: "space-between",
							}}
						>
							<Typography sx={{ fontWeight: 500 }}>Certifications:</Typography>
							<Typography sx={{ fontWeight: 500 }}>Amazon - LCA</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								marginTop: 4,
								justifyContent: "space-between",
							}}
						>
							<Typography sx={{ fontWeight: 500 }}>Price:</Typography>
							<Typography sx={{ fontWeight: 500 }}>$160</Typography>
						</Box>
					</Box>
				</Box>
			)}
			{steps[currentStep].type === "download_certificate" && (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Button variant="contained">Download certificate</Button>
				</Box>
			)}
			{steps[currentStep].button && (
				<Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 6 }}>
					<Button variant="contained" onClick={steps[currentStep].callback}>
						{steps[currentStep].button}
					</Button>
				</Box>
			)}
		</Box>
	);
}
