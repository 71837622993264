import * as React from "react";
import { Typography, Box, Button } from "@mui/material";
import { PropTypes } from "./HistoricalCertificationInfo.types";

export function HistoricalCertificationInfo({
	title,
	subtitle,
	buttonTitle,
	callback,
	setShowHistoricalModal,
}: PropTypes) {
	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				{title}
			</Typography>
			<Typography sx={{ textAlign: "center", marginBottom: 2 }}>
				{subtitle}
			</Typography>
			{buttonTitle && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Button
						variant="contained"
						sx={{
							marginTop: 2,
							maxWidth: 300,
							alignSelf: "end",
						}}
						onClick={() => {
							if (callback) {
								callback();
							}
							setShowHistoricalModal(false);
						}}
					>
						{buttonTitle}
					</Button>
				</Box>
			)}
		</Box>
	);
}
