import { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@mui/material";
import { PropTypes } from "./CompanyAnalytics.types";
import { Heading } from "../Heading";
import { useQuery } from "react-query";
import { dashboard as dashboardActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { InfoAmount } from "../InfoAmount";

export function CompanyAnalytics({ title, companyId }: PropTypes) {
	const { user } = useContext(UserContext);

	const { enqueueSnackbar } = useSnackbar();

	const [productData, setProductData] = useState([
		{
			title: "Total products",
			subtitle: "Overview of Products",
			value: "--",
			percentage: 0,
		},
		{
			title: "Total ClimeCo Certified Products",
			subtitle: "ClimeCo Certifications",
			value: "--",
			percentage: 0,
		},
		{
			title: "Total Amazon Certified Products",
			subtitle: "Amazon Certifications",
			value: "--",
			percentage: 0,
		},
		{
			title: "Total emissions retired (mtCO2e)",
			subtitle: "Offset Investments",
			value: "--",
			percentage: 0,
		},
	]);

	const { refetch: refetchProductsCount } = useQuery(
		"products-count",
		() => dashboardActions.productCount(companyId),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProductData((p) => {
					p[0].value = Intl.NumberFormat("en", {
						maximumFractionDigits: 2,
						minimumFractionDigits: 0,
					}).format(res["current_value"]);
					p[0].percentage =
						res["past_value"] === 0
							? res["current_value"] > 0
								? 100
								: 0
							: (res["current_value"] - res["past_value"]) / res["past_value"];

					return p;
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchClimeCoCertifiedSum } = useQuery(
		"climeco-certified-sum",
		() => dashboardActions.climecoCertifiedSum(companyId),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProductData((p) => {
					p[1].value = Intl.NumberFormat("en", {
						maximumFractionDigits: 2,
						minimumFractionDigits: 0,
					}).format(res["current_value"]);
					p[1].percentage =
						res["past_value"] === 0
							? res["current_value"] > 0
								? 100
								: 0
							: (res["current_value"] - res["past_value"]) / res["past_value"];

					return p;
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchAmazonCertifiedSum } = useQuery(
		"amazon-certified-sum",
		() => dashboardActions.amazonCertifiedSum(companyId),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProductData((p) => {
					p[2].value = Intl.NumberFormat("en", {
						maximumFractionDigits: 2,
						minimumFractionDigits: 0,
					}).format(res["current_value"]);
					p[2].percentage =
						res["past_value"] === 0
							? res["current_value"] > 0
								? 100
								: 0
							: (res["current_value"] - res["past_value"]) / res["past_value"];

					return p;
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { refetch: refetchOffsetsSum } = useQuery(
		"offsets-sum",
		() => dashboardActions.offsetsSum(companyId),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProductData((p) => {
					p[3].value = Intl.NumberFormat("en", {
						maximumFractionDigits: 2,
						minimumFractionDigits: 0,
					}).format(res["current_value"]);
					p[3].percentage =
						res["past_value"] === 0
							? res["current_value"] > 0
								? 100
								: 0
							: (res["current_value"] - res["past_value"]) / res["past_value"];

					return p;
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.company?.id) {
			refetchProductsCount();
			refetchClimeCoCertifiedSum();
			refetchAmazonCertifiedSum();
			refetchOffsetsSum();
		}
	}, [
		user,
		refetchProductsCount,
		refetchClimeCoCertifiedSum,
		refetchAmazonCertifiedSum,
		refetchOffsetsSum,
	]);

	return (
		<Box>
			<Heading
				title={title}
				showSearch={false}
				noMarginBottom
				tooltip="The percentages shown are relative to the data from 30 days ago"
			/>
			<Grid container spacing={2}>
				{productData.map((data) => {
					return (
						<Grid item xs={12} sm={6} md={3} key={data.title}>
							<InfoAmount
								title={data.title}
								subtitle={data.subtitle}
								value={data.value}
								percentage={data.percentage}
							/>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
}
