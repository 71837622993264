import React, { useState } from "react";
import {
	Box,
	FormControl,
	OutlinedInput,
	Chip,
	MenuItem,
	Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { PropTypes } from "./MarketplaceSelector.types";

type Marketplace = {
	id: number;
	name: string;
};

export default function MarketplaceSelector({
	index,
	setSelectedElements,
	selectedElements,
	removeElement,
}: PropTypes) {
	const marketplaces = [
		{ id: 1, name: "United States" },
		{ id: 3, name: "United Kingdom" },
		{ id: 4, name: "Germany" },
		{ id: 35691, name: "Italy" },
		{ id: 44551, name: "Spain" },
	];

	const [selectedMarketplace, setSelectedMarketplace] = useState<Marketplace[]>(
		[]
	);

	return (
		<FormControl
			sx={{
				width: {
					xs: 200,
					sm: 300,
					md: 300,
				},
				marginLeft: {
					xs: 0,
					sm: 0,
					md: 2,
				},
			}}
		>
			<Select
				displayEmpty
				labelId="marketplace-selector"
				id="marketplace-selector-chip"
				multiple
				value={selectedMarketplace.map((marketplace) => marketplace.name)}
				onChange={(event) => {
					const {
						target: { value },
					} = event;
					const names = typeof value === "string" ? value.split(",") : value;

					if (names.includes("NoCertify")) {
						removeElement(index);
					} else {
						const newMarketplaces = marketplaces.filter((marketplace) =>
							names.includes(marketplace.name)
						);
						setSelectedMarketplace(newMarketplaces);
						const auxSelectedElements = [...selectedElements];
						auxSelectedElements[index].marketplaces = newMarketplaces;
						setSelectedElements(auxSelectedElements);
					}
				}}
				input={<OutlinedInput id="select-multiple-chip" />}
				renderValue={(selectedNames) => (
					<Box
						sx={{
							display: "flex",
							gap: 0.5,
							overflow: "auto",
							minHeight: 32,
							alignItems: "center",
						}}
					>
						{selectedNames.length > 0 ? (
							selectedNames.map((name) => <Chip key={name} label={name} />)
						) : (
							<Typography
								color="textSecondary"
								sx={{ fontSize: 14, color: "#EB4B4B" }}
							>
								Select One Or More Marketplaces
							</Typography>
						)}
					</Box>
				)}
				MenuProps={{
					PaperProps: {
						style: {},
					},
				}}
			>
				{marketplaces.map((marketplace) => (
					<MenuItem key={marketplace.id} value={marketplace.name}>
						{marketplace.name}
					</MenuItem>
				))}
				<MenuItem value="NoCertify">Do Not Certify</MenuItem>
			</Select>
		</FormControl>
	);
}
