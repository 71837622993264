import { useContext, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Heading } from "../Heading";
import { Stepper } from "../Stepper";
import { BasicModal, HistoricalCertificationSteps } from "..";
import { ICertification, ISale } from "../../models";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { certification as certificationActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfettiExplosion from "react-confetti-explosion";

export function HistoricalCertificationProcess() {
	const { id } = useParams();
	const [currentStep, setCurrentStep] = useState(0);

	const [certification, setCertification] = useState<ICertification | null>(
		null
	);
	const [reloadCertification, setReloadCertification] = useState(true);
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const [showTitleModal, setShowTitleModal] = useState(false);

	const getQuartersBetween = (startDate: Date, endDate: Date): ISale[] => {
		if (startDate > endDate) {
			return [];
		}

		const currentQuarter = Math.floor(new Date().getUTCMonth() / 3) + 1;
		const currentYear = new Date().getUTCFullYear();

		const startQuarter = Math.floor(startDate.getUTCMonth() / 3) + 1;
		const endQuarter = Math.floor(endDate.getUTCMonth() / 3) + 1;

		let quarters: string[] = [];
		for (
			let year = startDate.getUTCFullYear();
			year <= endDate.getUTCFullYear();
			year++
		) {
			for (let quarter = 1; quarter <= 4; quarter++) {
				if (year === startDate.getUTCFullYear() && quarter < startQuarter) {
					continue;
				}
				if (year === endDate.getUTCFullYear() && quarter > endQuarter) {
					break;
				}
				if (year === currentYear && quarter === currentQuarter) {
					break;
				}
				quarters.push(`Q${quarter}-${year}`);
			}
			if (year === currentYear && currentQuarter <= endQuarter) {
				break;
			}
		}

		let sales: ISale[] = [];
		let saleId = 1;

		quarters.forEach((quarter) => {
			const [q, year] = quarter.split("-");
			let start = "";
			let end = "";
			if (parseInt(q.split("Q")[1]) === 1) {
				start = `${year}-01-01T00:00:00Z`;
				end = `${year}-03-30T00:00:00Z`;
			} else if (parseInt(q.split("Q")[1]) === 2) {
				start = `${year}-04-01T00:00:00Z`;
				end = `${year}-06-30T00:00:00Z`;
			} else if (parseInt(q.split("Q")[1]) === 3) {
				start = `${year}-07-01T00:00:00Z`;
				end = `${year}-09-30T00:00:00Z`;
			} else {
				start = `${year}-10-01T00:00:00Z`;
				end = `${year}-12-30T00:00:00Z`;
			}

			const sale: ISale = {
				id: -1 * saleId++,
				amount: 0,
				start_date: start,
				end_date: end,
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
				lca_document: undefined,
			};

			sales.push(sale);
		});

		return sales;
	};

	const { refetch: refetchCertification } = useQuery(
		"climeco-certifications-detail",
		() =>
			certificationActions.getOne(
				true,
				parseInt(id!),
				user?.is_superuser ? undefined : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (!res.is_historical) {
					navigate("/historical-data/" + id);
				} else {
					let step = 0;
					const rejectedStep = res.certification_process_steps.findIndex(
						(step) => step.status === "REJECTED"
					);

					const pendingStep = res.certification_process_steps.findIndex(
						(step) => step.status === "PENDING"
					);

					step =
						rejectedStep > -1
							? rejectedStep
							: pendingStep > -1
							? pendingStep
							: res.certification_process_steps.length - 1;

					if (
						res.certification_process_steps[step].step.step_type ===
						"PRODUCT_LIST"
					) {
						res.estimations.map((estimation) => {
							estimation.amount = undefined;
							estimation.product.sales = getQuartersBetween(
								new Date(estimation.overwrite_certification_date ?? ""),
								new Date()
							);
							return estimation;
						});
					}
					setCertification(res);
					setReloadCertification(true);
				}
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	useEffect(() => {
		if (id) {
			refetchCertification();
		}
	}, [id, refetchCertification]);

	const getTitle = () => {
		if (!certification?.estimations || certification.estimations.length === 0) {
			return "Historical Data";
		}

		if (certification.estimations.length <= 2) {
			return (
				"Historical Data - " +
				certification.estimations
					.map(
						(est) =>
							`${
								est.product?.name +
								(est.product?.asin
									? " - " + est.product?.asin
									: est.product?.upc
									? " - " + est.product?.upc
									: "")
							}`
					)
					.join(", ")
			);
		}

		const firstTwoProducts = certification.estimations
			.slice(0, 2)
			.map((est) => `${est.product?.name} - ${est.product?.asin}`)
			.join(", ");
		const remainingCount = certification.estimations.length - 2;

		return `Historical Data - ${firstTwoProducts} and ${remainingCount} more products`;
	};

	return (
		<Box>
			{/*<Typography
				variant="body2"
				fontSize={10}
				marginBottom={2}
				sx={{ cursor: "pointer" }}
				onClick={() => {
					navigate("/climeco-certifications");
				}}
			>
				{"BACK"}
			</Typography>*/}

			{currentStep ===
				(certification?.certification_process_steps.length ?? 0) - 1 && (
				<Box
					sx={{
						position: "absolute",
						top: "20%",
						left: "50%",
						transform: "translate(-20%, -50%)",
					}}
				>
					<ConfettiExplosion />
				</Box>
			)}

			<Heading
				title={getTitle()}
				showSearch={false}
				onTitleClick={() =>
					certification && certification.estimations.length > 2
						? setShowTitleModal(true)
						: null
				}
			/>

			<Box
				sx={{
					display: "flex",
					flexDirection: {
						xs: "row",
						sm: "column",
					},
					gap: !matches ? 4 : 0,
				}}
			>
				{certification &&
					certification?.certification_process_steps &&
					certification?.certification_process_steps.length > 0 && (
						<>
							<Stepper
								orientation={!matches ? "vertical" : "horizontal"}
								currentStep={currentStep}
								lastStep={certification?.certification_process_steps.length - 1}
								steps={certification?.certification_process_steps
									.filter((step) => step.step.step_type !== "OTHER")
									.map((step) => step.step.step_title)}
							/>
							<HistoricalCertificationSteps
								currentStep={currentStep}
								setCurrentStep={setCurrentStep}
								certification={certification}
								setCertification={setCertification}
								reloadCertification={reloadCertification}
								setReloadCertification={setReloadCertification}
								refetchCertification={refetchCertification}
							/>
						</>
					)}
			</Box>
			<BasicModal
				width={800}
				showModal={showTitleModal}
				handleClose={() => {
					setShowTitleModal(false);
				}}
				children={
					<Box>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Products List
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							{certification &&
								certification.estimations.map((est, index) => (
									<p key={est.id}>
										{est.product?.name} - {est.product?.asin}
									</p>
								))}
						</Typography>
					</Box>
				}
			/>
		</Box>
	);
}
