import { useContext, useState, useEffect } from "react";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { Heading } from "../Heading";
import { Stepper } from "../Stepper";
import { makeStyles } from "@mui/styles";
import {
	BasicModal,
	BulkProducts,
	CertificationAgree,
	CertificationConfirmation,
	MultiItemSelector,
	ProductSummary,
	ShareImpact,
} from "..";
import {
	ICertification,
	ICertificationSteps,
	IEstimation,
	IProduct,
	IStep,
} from "../../models";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	certification as certificationActions,
	certificationProcessStep as certificationProcessStepActions,
	estimation as estimationsActions,
	sale as saleActions,
	strings as stringActions,
	product as productActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { ConfirmationDataPropTypes } from "../CertificationConfirmation/CertificationConfirmation.types";
import { PurchaseOffsets } from "../PurchaseOffsets";
import { NumericFormat } from "react-number-format";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfettiExplosion from "react-confetti-explosion";
import ReactHtmlParser from "react-html-parser";
import { motion } from "framer-motion";
import { UploadContracts } from "../UploadContracts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
		color: "#888",
	},
});

let loadingDocuSignResultInterval: NodeJS.Timer | null = null;
let loadingOffsetsInterval: NodeJS.Timer | null = null;

export function ClimecoCertificationProcess() {
	const classes = useStyles();
	const { id } = useParams();
	const location = useLocation();
	const [currentStep, setCurrentStep] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const [productsFilter, setProductsFilter] = useState("");
	const [currentProducts, setCurrentProducts] = useState(
		location.state?.products
			? (location.state.products as Array<IProduct>)
			: Array<IProduct>()
	);
	const [currentEstimations] = useState(Array<IEstimation>());
	const [originalEstimations, setOriginalEstimations] = useState(
		Array<IEstimation>()
	);
	const [products, setProducts] = useState<Array<IProduct>>([]);
	const [description, setDescription] = useState<string | undefined>(undefined);
	const [certification, setCertification] = useState<ICertification | null>(
		null
	);
	const [reloadCertification, setReloadCertification] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] =
		useState<ConfirmationDataPropTypes>({
			title: "",
			description: "",
			textfield: null,
			callback: () => {},
		});
	const [loadingDocuSignResult, setLoadingDocuSignResult] = useState(false);
	const [loadingOffsetsResult, setLoadingOffsetsResult] = useState(false);
	const { user } = useContext(UserContext);
	const [showCreateProductModal, setShowCreateProductModal] = useState(false);
	const [showMarkItAsPaidModal, setShowMarkItAsPaidModal] = useState(false);
	const [balance, setBalance] = useState<number>(0);
	const [productsCount, setProductsCount] = useState<number>(0);
	const [showAgreeModal, setShowAgreeModal] = useState(false);
	const [manualOffsets, setManualOffsets] = useState<number | undefined>(
		undefined
	);
	const [manualOffsetsPrice, setManualOffsetsPrice] = useState<number | string>(
		""
	);
	const [messagesIndex, setMessagesIndex] = useState(0);
	const [showPurchaseOffsets, setShowPurchaseOffsets] = useState(false);
	const [docuSignLoadingMessages, setDocuSignLoadingMessages] = useState([
		"Waiting for DocuSign to callback...",
		"Finalizing contracts...",
		"Fetching offsets information...",
		"Configuring available offers...",
		"Almost finishing!",
	]);
	const [offsetsLoadingMessages, setOffsetsLoadingMessages] = useState([
		"Confirming payment...",
		"Reserving offsets...",
		"Checking details...",
		"Finalizing certification...",
		"About to finish!",
	]);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const [showTitleModal, setShowTitleModal] = useState(false);
	const [showAmazonModal, setShowAmazonModal] = useState(false);

	useQuery("strings", () => stringActions.list(), {
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
		onSuccess: (res) => {
			if (res.results.length > 0) {
				setDocuSignLoadingMessages([
					(res.results[0] as any)["docusign_loading_1"],
					(res.results[0] as any)["docusign_loading_2"],
					(res.results[0] as any)["docusign_loading_3"],
					(res.results[0] as any)["docusign_loading_4"],
					(res.results[0] as any)["docusign_loading_5"],
				]);
				setOffsetsLoadingMessages([
					(res.results[0] as any)["offsets_loading_1"],
					(res.results[0] as any)["offsets_loading_2"],
					(res.results[0] as any)["offsets_loading_3"],
					(res.results[0] as any)["offsets_loading_4"],
					(res.results[0] as any)["offsets_loading_5"],
				]);
			}
		},
		onError: (error: any) => {
			console.log(error);
		},
	});

	const { refetch: refetchLink } = useQuery(
		"generate-link",
		() =>
			certificationActions.generateLink(
				true,
				parseInt(id!),
				user?.is_superuser ? certification?.company.id : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
				searchParams.delete("event");
				setSearchParams(searchParams);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchProducts } = useQuery(
		"products",
		() =>
			productActions.list(
				user?.is_superuser ? certification?.company.id : user?.company.id,
				productsFilter
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				const filteredProducts = res.results.filter(
					(p) =>
						(p.certification_processes ?? []).filter(
							(c) =>
								c.certification?.certification_type ===
									"CLIMECO_INTERNAL_CERTIFICATION" && c.is_historical
						).length === 0
				);
				setProducts(filteredProducts);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchActiveProducts } = useQuery(
		"products-active",
		() =>
			productActions.list(
				user?.is_superuser ? certification?.company.id : user?.company.id,
				"",
				undefined,
				"ClimeCo certified"
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProductsCount(
					Math.max(0, res.count - (certification?.estimations.length ?? 0))
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchBalance } = useQuery(
		"balance",
		() => saleActions.getBalance([], certification?.company.id),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				const num =
					Math.sign((res?.estimated_amount ?? 0) - (res?.sales_amount ?? 0)) *
						Math.ceil(
							Math.abs((res?.estimated_amount ?? 0) - (res?.sales_amount ?? 0))
						) +
					Math.ceil(res?.offsets_amount ?? 0);
				setBalance(
					Math.ceil(num) === -0 ? 0 : Math.sign(num) * Math.ceil(Math.abs(num))
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (loadingDocuSignResult) {
			setMessagesIndex(0);
			loadingDocuSignResultInterval = setInterval(() => {
				setMessagesIndex((prevIndex) =>
					prevIndex === docuSignLoadingMessages.length - 1 ? 0 : prevIndex + 1
				);
			}, 5000);
		} else if (loadingDocuSignResultInterval) {
			clearInterval(loadingDocuSignResultInterval);
		}

		return () => {
			if (loadingDocuSignResultInterval) {
				clearInterval(loadingDocuSignResultInterval);
			}
		};
	}, [loadingDocuSignResult, docuSignLoadingMessages.length]);

	useEffect(() => {
		if (loadingOffsetsResult) {
			setMessagesIndex(0);
			loadingOffsetsInterval = setInterval(() => {
				setMessagesIndex((prevIndex) =>
					prevIndex === offsetsLoadingMessages.length - 1 ? 0 : prevIndex + 1
				);
			}, 5000);
		} else if (loadingOffsetsInterval) {
			clearInterval(loadingOffsetsInterval);
		}

		return () => {
			if (loadingOffsetsInterval) {
				clearInterval(loadingOffsetsInterval);
			}
		};
	}, [loadingOffsetsResult, offsetsLoadingMessages.length]);

	useEffect(() => {
		if (
			!user?.is_superuser &&
			certification &&
			certification.certification_process_steps[currentStep].step.step_type ===
				"OTHER" &&
			!(certification.estimations ?? []).find(
				(estimation) => (estimation.product.marketplaces ?? []).length > 0
			)
				? true
				: false
		) {
			setShowAmazonModal(true);
		}
	}, [certification, currentStep, user]);

	useEffect(() => {
		if (certification) {
			refetchProducts();
		}
	}, [certification, refetchProducts, productsFilter]);

	useEffect(() => {
		if (certification) {
			refetchActiveProducts();
			refetchBalance();
		}
	}, [certification, refetchActiveProducts, refetchBalance]);

	const { refetch: refetchCertification } = useQuery(
		"climeco-certifications-detail",
		() =>
			certificationActions.getOne(
				true,
				parseInt(id!),
				user?.is_superuser ? undefined : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (res.is_historical) {
					navigate("/historical-data/" + id);
				} else {
					let step = 0;
					const rejectedStep = res.certification_process_steps.findIndex(
						(step) => step.status === "REJECTED"
					);

					const pendingStep = res.certification_process_steps.findIndex(
						(step) => step.status === "PENDING"
					);

					step =
						rejectedStep > -1
							? rejectedStep
							: pendingStep > -1
							? pendingStep
							: res.certification_process_steps.length - 1;

					if (
						res.certification_process_steps[step].step.step_type ===
						"PRODUCT_LIST"
					) {
						res.estimations.map((estimation) => {
							estimation.amount = undefined;
							return estimation;
						});
					}
					setCertification(res);
					setReloadCertification(true);
				}
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	const getEstimations = () => {
		if (currentStep === 0) {
			return currentProducts.map((product, index) => {
				return {
					id: index,
					value: 0,
					//emissions: 0,
					product: product,
				};
			});
		} else {
			return currentEstimations;
		}
	};

	const {
		isLoading: isLoadingCreateCertification,
		mutate: createCertification,
	} = useMutation(
		"create-certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: true,
				name: "New",
				companyId: user!.company.id,
				isHistorical: false,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingCreateEstimation,
		mutate: createEstimationMutate,
	} = useMutation(
		"estimations",
		(estimation: IEstimation) =>
			estimationsActions.create({
				amount: estimation.amount,
				//emissions: estimation.emissions,
				product: estimation.product.id,
				certificationProcess:
					estimation.certification_process?.id ?? certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingDeleteEstimation,
		mutate: deleteEstimationMutate,
	} = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingUpdateEstimation,
		mutate: updateEstimationMutate,
	} = useMutation(
		"estimations-update",
		(estimation: IEstimation) =>
			estimationsActions.update({
				id: estimation.id,
				amount: estimation.amount,
				//emissions: estimation.emissions,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading, mutate } = useMutation(
		"certification-detail",
		() =>
			certificationActions.update({
				isClimeCo: true,
				estimations: getEstimations(),
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
				id: certification?.id ?? -1,
				description: description,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingMarkItAsPaid, mutate: mutateMarkItAsPaid } =
		useMutation(
			"mark-it-as-paid",
			() =>
				certificationProcessStepActions.markItAsPaid({
					id: certification!.certification_process_steps[currentStep].id,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					refetchCertification();
					setShowMarkItAsPaidModal(false);
					enqueueSnackbar("Certification marked as paid.", {
						variant: "success",
					});
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const { isLoading: isLoadingMutateAddOffsets, mutate: mutateAddOffsets } =
		useMutation(
			"certification-add-offsets",
			() =>
				certificationActions.addOffsets({
					isClimeCo: true,
					id: certification?.id ?? -1,
					companyId: certification?.company.id,
					quantity: manualOffsets,
					amount:
						typeof manualOffsetsPrice === "string"
							? parseFloat(manualOffsets!.toString()) * 12.5
							: parseFloat(manualOffsets!.toString()) * manualOffsetsPrice,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					refetchCertification();
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const { isLoading: isLoadingApproval, mutate: mutateApproval } = useMutation(
		"certification-detail-approval",
		() =>
			certificationActions.toggleApproval({
				isClimeCo: true,
				id: certification?.id ?? -1,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
				setShowAgreeModal(false);
				enqueueSnackbar(
					"Certification requested. Now, waiting for Admin's approval.",
					{ variant: "success" }
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchCertificationSteps } = useQuery(
		"fetch-steps",
		() =>
			certificationActions.getSteps("CLIMECO_INTERNAL_CERTIFICATION", false),
		{
			enabled: false,
			retry: false,
			onSuccess: (res: IStep[]) => {
				const certificationSteps = res.map((step) => {
					return {
						id: new Date().getTime(),
						status: "PENDING",
						payment_status: null,
						reject_reason: "",
						step,
					} as ICertificationSteps;
				});
				setCertification({
					id: -1,
					name: "ClimeCo Certification - " + new Date().getTime(),
					certification_process_steps: certificationSteps,
					company: user!.company,
					estimations: Array<IEstimation>(),
					is_historical: false,
					status: "COMPLETED",
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (id && user) {
			if (id === "create") {
				refetchCertificationSteps();
			} else {
				refetchCertification();
			}
		}
	}, [
		id,
		refetchCertification,
		user,
		enqueueSnackbar,
		refetchCertificationSteps,
	]);

	useEffect(() => {
		if (user && certification && currentStep) {
			if (searchParams.get("event") === "ttl_expired") {
				if (
					(!user?.is_superuser &&
						certification.certification_process_steps[currentStep].step
							.step_type === "REDIRECTION") ||
					(user?.is_superuser &&
						certification.certification_process_steps[currentStep].step
							.step_type === "WAITING")
				) {
					enqueueSnackbar("Link has expired. Generating a new one...", {
						variant: "info",
					});
					refetchLink();
				} else {
					searchParams.delete("event");
					setSearchParams(searchParams);
				}
			} else if (searchParams.get("event") !== null) {
				setLoadingDocuSignResult(true);
				enqueueSnackbar(
					"Signing successful. Reloading... (it might take a minute)",
					{
						variant: "success",
						autoHideDuration: 25000,
					}
				);
				searchParams.delete("event");
				setSearchParams(searchParams);
				setTimeout(() => {
					refetchCertification();
					setLoadingDocuSignResult(false);
				}, 25000);
			} /*else if (
				user?.is_superuser &&
				certification.certification_process_steps[currentStep].step
					.step_type === "WAITING" &&
				!certification?.climeco_contract_url_admin
			) {
				enqueueSnackbar("Generating a DocuSign link...", { variant: "info" });
				refetchLink();
			}*/
		}
	}, [
		searchParams,
		certification,
		refetchLink,
		enqueueSnackbar,
		refetchCertification,
		setSearchParams,
		currentStep,
		user,
	]);

	useEffect(() => {
		if (
			reloadCertification &&
			certification &&
			certification.certification_process_steps
		) {
			const rejectedStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "REJECTED"
			);

			const pendingStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "PENDING"
			);

			setCurrentStep(
				rejectedStep > -1
					? rejectedStep
					: pendingStep > -1
					? pendingStep
					: certification.certification_process_steps.length - 1
			);
			setReloadCertification(false);
		}
	}, [certification, reloadCertification]);

	useEffect(() => {
		if (
			!loadingDocuSignResult &&
			certification?.certification_process_steps[currentStep].step.step_type ===
				"PURCHASE_OFFSETS" &&
			certification?.certification_process_steps[currentStep].payment_status ===
				"NOT_STARTED" &&
			(!certification?.manual_approval || !user?.is_superuser)
		) {
			setShowPurchaseOffsets(true);
		}
	}, [loadingDocuSignResult, certification, currentStep, user]);

	const getTitle = () => {
		if (!certification?.estimations || certification.estimations.length === 0) {
			return "ClimeCo Certification";
		}

		if (certification.estimations.length <= 2) {
			return (
				"ClimeCo Certification - " +
				certification.estimations
					.map(
						(est) =>
							`${est.product?.name} - ${
								est.product?.asin || est.product?.upc || "--"
							}`
					)
					.join(", ")
			);
		}

		const firstTwoProducts = certification.estimations
			.slice(0, 2)
			.map(
				(est) =>
					`${est.product?.name} - ${
						est.product?.asin || est.product?.upc || "--"
					}`
			)
			.join(", ");
		const remainingCount = certification.estimations.length - 2;

		return `ClimeCo Certification - ${firstTwoProducts} and ${remainingCount} more products`;
	};

	return (
		<Box>
			{/*<Typography
				variant="body2"
				fontSize={10}
				marginBottom={2}
				sx={{ cursor: "pointer" }}
				onClick={() => {
					navigate("/climeco-certifications");
				}}
			>
				{"BACK"}
			</Typography>*/}

			{currentStep ===
				(certification?.certification_process_steps.length ?? 0) - 1 && (
				<Box
					sx={{
						position: "absolute",
						top: "20%",
						left: "50%",
						transform: "translate(-20%, -50%)",
					}}
				>
					<ConfettiExplosion />
				</Box>
			)}

			<Heading
				title={getTitle()}
				showSearch={false}
				onTitleClick={() =>
					certification && certification.estimations.length > 2
						? setShowTitleModal(true)
						: null
				}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: {
						xs: "row",
						sm: "column",
					},
					gap: !matches ? 4 : 0,
				}}
			>
				{certification &&
					certification?.certification_process_steps &&
					certification?.certification_process_steps.length > 0 && (
						<>
							<Stepper
								orientation={!matches ? "vertical" : "horizontal"}
								currentStep={currentStep}
								lastStep={certification?.certification_process_steps.length - 1}
								steps={certification?.certification_process_steps.map(
									(step) => step.step.step_title
								)}
							/>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: {
										xs: "calc(100% - 82px)",
										sm: "100%",
									},
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Box>
										<Typography
											variant="h5"
											sx={{
												fontWeight: 700,
												marginTop: !matches ? 0 : 4,
											}}
										>
											{
												certification?.certification_process_steps[currentStep]
													.step.name
											}
										</Typography>
										<Typography
											sx={{ marginTop: 1, marginBottom: 4 }}
											variant="subtitle1"
										>
											{ReactHtmlParser(
												certification?.certification_process_steps[currentStep]
													.step.description ?? ""
											)}
										</Typography>
									</Box>
									<Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
										{(certification?.certification_process_steps[currentStep]
											.step.step_type === "INFORMATION" ||
											certification?.certification_process_steps[currentStep]
												.step.step_type === "PRODUCT_LIST") && (
											<Button
												variant="outlined"
												onClick={() => {
													setCurrentStep(currentStep - 1);
													setOriginalEstimations(certification.estimations);
													setCurrentProducts(
														certification.estimations.map(
															(estimation) => estimation.product
														)
													);
												}}
												sx={{ marginRight: 0 }}
											>
												BACK
											</Button>
										)}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												.step.button_title && (
												<Button
													variant="contained"
													sx={{
														minHeight: 34,
														display:
															(certification?.certification_process_steps[
																currentStep
															].step.step_type === "WAITING" &&
																!user?.is_superuser) ||
															(certification?.certification_process_steps[
																currentStep
															].step.step_type === "REDIRECTION" &&
																user?.is_superuser) ||
															(certification?.certification_process_steps[
																currentStep
															].step.step_type === "PURCHASE_OFFSETS" &&
																certification?.manual_approval &&
																user?.is_superuser)
																? "none"
																: "block",
													}}
													disabled={(() => {
														switch (
															certification?.certification_process_steps[
																currentStep
															].step.step_type
														) {
															case "CHOOSE_PRODUCTS": {
																return currentProducts.length === 0;
															}
															case "INFORMATION": {
																return !description;
															}
															case "PRODUCT_LIST": {
																return certification.estimations.find(
																	(estimation) =>
																		estimation.amount === undefined
																)
																	? true
																	: false;
															}
															case "CERTIFICATION_SUMMARY": {
																return false;
															}
															case "REDIRECTION": {
																return searchParams.get("event") ||
																	(!user?.is_superuser &&
																		!certification?.climeco_contract_url_customer)
																	? true
																	: false;
															}
															case "WAITING": {
																return (user?.is_superuser ?? false) &&
																	!certification?.climeco_contract_url_admin
																	? true
																	: false;
															}

															default: {
																return false;
															}
														}
													})()}
													onClick={async () => {
														switch (
															certification?.certification_process_steps[
																currentStep
															].step.step_type
														) {
															case "CHOOSE_PRODUCTS": {
																let index = 0;

																for (const estimation of originalEstimations) {
																	deleteEstimationMutate(estimation);
																}
																const createEstimation = (
																	estimationId: number,
																	amount: number,
																	//emissions: number,
																	product: IProduct,
																	index: number,
																	certificationProcess?: ICertification
																) => {
																	createEstimationMutate(
																		{
																			id: estimationId,
																			amount: amount,
																			//emissions: emissions,
																			product: product,
																			certification_process:
																				certificationProcess,
																		},
																		{
																			onSuccess: () => {
																				index += 1;
																				if (index < currentProducts.length) {
																					createEstimation(
																						index,
																						0,
																						//0,
																						currentProducts[index],
																						index,
																						certificationProcess
																					);
																				} else {
																					if (
																						certificationProcess &&
																						id === "create"
																					) {
																						navigate(
																							"/climeco-certifications/" +
																								certificationProcess!.id
																						);
																					} else {
																						refetchCertification();
																					}
																				}
																			},
																			onError: (error) => {
																				console.log(error);
																				enqueueSnackbar(
																					"Something went wrong.",
																					{
																						variant: "error",
																					}
																				);
																			},
																		}
																	);
																};

																if (certification.id === -1) {
																	createCertification(null, {
																		onSuccess: (res) => {
																			createEstimation(
																				index,
																				0,
																				//0,
																				currentProducts[index],
																				index,
																				res
																			);
																		},
																		onError: (error) => {
																			console.log(error);
																			enqueueSnackbar("Something went wrong.", {
																				variant: "error",
																			});
																		},
																	});
																} else {
																	createEstimation(
																		index,
																		0,
																		//0,
																		currentProducts[index],
																		index
																	);
																}

																break;
															}
															case "INFORMATION": {
																mutate();
																break;
															}
															case "REDIRECTION": {
																if (
																	!user?.is_superuser &&
																	certification?.climeco_contract_url_customer
																) {
																	window.location.href =
																		certification?.climeco_contract_url_customer;
																}
																break;
															}
															case "WAITING": {
																if (
																	(user?.is_superuser ?? false) &&
																	certification?.climeco_contract_url_admin
																) {
																	window.location.href =
																		certification?.climeco_contract_url_admin;
																}
																break;
															}
															case "PRODUCT_LIST": {
																let index = 0;

																const updateEstimation = (
																	id: number,
																	amount: number,
																	//emissions: number,
																	product: IProduct,
																	index: number
																) => {
																	updateEstimationMutate(
																		{
																			id: id,
																			amount: amount,
																			//emissions: emissions,
																			product: product,
																		},
																		{
																			onSuccess: () => {
																				index += 1;
																				if (
																					index <
																					certification.estimations.length
																				) {
																					updateEstimation(
																						certification.estimations[index].id,
																						certification.estimations[index]
																							.amount!,
																						/*certification.estimations[index]
																						.emissions!,*/
																						certification.estimations[index]
																							.product,
																						index
																					);
																				} else {
																					refetchCertification();
																				}
																				setShowConfirmation(false);
																			},
																			onError: (error) => {
																				console.log(error);
																				enqueueSnackbar(
																					"Something went wrong.",
																					{
																						variant: "error",
																					}
																				);
																				setShowConfirmation(false);
																			},
																		}
																	);
																};

																/*setShowConfirmation(true);
															setConfirmationData({
																title:
																	"Do you want to proceed with the Climeco Certification?",
																description:
																	"Have in mind, once you continue you will not be able to modify it",
																textfield: null,
																callback: () => {
																	updateEstimation(
																		certification.estimations[index].id,
																		certification.estimations[index].amount!,
																		//certification.estimations[index].emissions!,
																		certification.estimations[index].product,
																		index
																	);
																},
															});*/

																updateEstimation(
																	certification.estimations[index].id,
																	certification.estimations[index].amount!,
																	//certification.estimations[index].emissions!,
																	certification.estimations[index].product,
																	index
																);

																break;
															}
															case "OTHER": {
																navigate("/products");
																break;
															}
															default: {
																if (
																	currentStep + 1 <
																	certification.certification_process_steps
																		.length
																) {
																	setCurrentStep(currentStep + 1);
																}
															}
														}
													}}
												>
													{isLoadingCreateEstimation ||
													isLoadingUpdateEstimation ||
													isLoading ||
													isLoadingDeleteEstimation ||
													isLoadingCreateCertification ? (
														<CircularProgress
															size={12}
															sx={{ color: "white" }}
														/>
													) : (
														certification?.certification_process_steps[
															currentStep
														].step.button_title
													)}
												</Button>
											)}
									</Box>
								</Box>
								{loadingDocuSignResult && (
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: 4,
											flexDirection: "column",
										}}
									>
										<CircularProgress size={24} />
										<motion.div
											key={messagesIndex}
											initial={{ opacity: 0, x: -100 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: 100 }}
										>
											<Typography
												variant="body2"
												sx={{
													marginTop: 2,
													maxWidth: 400,
													textAlign: "center",
												}}
											>
												{docuSignLoadingMessages[messagesIndex]}
											</Typography>
										</motion.div>
									</Box>
								)}
								{!loadingDocuSignResult &&
									certification?.certification_process_steps[currentStep].step
										.step_type === "CHOOSE_PRODUCTS" && (
										<MultiItemSelector
											title="Select Product(s)"
											placeholderAutocomplete="Select Product(s)"
											buttonTitle="Add product(s)"
											listTitle="Product(s) Selected"
											emptyState="No products applied yet"
											setFilter={setProductsFilter}
											elements={products}
											selectedElements={currentProducts}
											setSelectedElements={(elements) =>
												setCurrentProducts(elements as Array<IProduct>)
											}
											setShowCreateProductModal={setShowCreateProductModal}
										/>
									)}
								{!loadingDocuSignResult &&
									certification?.certification_process_steps[currentStep].step
										.step_type === "INFORMATION" && (
										<Box sx={{ flex: 1 }}>
											<TextField
												sx={{ width: "100%" }}
												autoFocus
												multiline
												rows={8}
												placeholder="Write here"
												value={description}
												onChange={(e) => {
													setDescription(e.target.value);
												}}
											/>
										</Box>
									)}
								{!loadingDocuSignResult &&
									certification?.certification_process_steps[currentStep].step
										.step_type === "PRODUCT_LIST" && (
										<Box>
											{certification?.estimations &&
												certification?.estimations
													.sort((a, b) => {
														const dateA = new Date(
															a.created_at ?? ""
														).getTime();
														const dateB = new Date(
															b.created_at ?? ""
														).getTime();

														return dateA - dateB;
													})
													.map((estimation, index) => {
														return (
															<Box
																key={estimation.id}
																sx={{
																	display: "flex",
																	flexDirection: "column",
																}}
															>
																<Box
																	sx={{
																		display: "flex",
																		alignItems: "center",
																		flexDirection: {
																			xs: "column",
																			sm: "column",
																			md: "row",
																		},
																		gap: 2,
																		flex: 1,
																	}}
																>
																	<Typography
																		flex={1}
																		sx={{
																			marginTop: "20px",
																			flex: 1,
																			maxWidth: 400,
																		}}
																	>
																		{estimation.product.asin
																			? estimation.product.name +
																			  " - " +
																			  estimation.product.asin
																			: estimation.product.upc
																			? estimation.product.name +
																			  " - " +
																			  estimation.product.upc
																			: estimation.product.name}
																	</Typography>
																	<Box
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			width: "100%",
																			flex: 1,
																		}}
																	>
																		<span className={classes.inputLabel}>
																			Emissions
																		</span>
																		<NumericFormat
																			value={
																				certification.estimations[index]
																					.lca_document?.emissions ?? 0
																			}
																			name={"emissions"}
																			placeholder="kg of CO2e"
																			customInput={TextField}
																			style={{ background: "white" }}
																			suffix={" Kg"}
																			type="text"
																			allowNegative={false}
																			disabled={true}
																			thousandSeparator={","}
																			onValueChange={({ value: v }) => {
																				/*const auxCertification = {
																				...certification,
																			};
																			auxCertification.estimations[
																				index
																			].emissions = parseInt(v);
																			setCertification(auxCertification);*/
																			}}
																		/>
																	</Box>
																	<Box
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			width: "100%",
																			flex: 1,
																		}}
																	>
																		<span className={classes.inputLabel}>
																			Estimated Total Units Sold for 12 Months
																		</span>
																		<NumericFormat
																			value={
																				certification.estimations[index].amount
																			}
																			style={{
																				border: "1px solid rgb(3, 176, 147)",
																				borderRadius: 4,
																			}}
																			name={"amount"}
																			//placeholder={`Estimate Total Units Sold The Rest of ${new Date().getUTCFullYear()}`}
																			customInput={TextField}
																			type="text"
																			allowNegative={false}
																			decimalScale={0}
																			autoFocus={index === 0}
																			thousandSeparator={","}
																			onValueChange={({ value: v }) => {
																				const auxCertification = {
																					...certification,
																				};
																				auxCertification.estimations[
																					index
																				].amount = v ? parseInt(v) : undefined;
																				setCertification(auxCertification);
																			}}
																		/>
																	</Box>
																	<Box
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			textAlign: "right",
																			paddingRight: "20px",
																			"& input": {
																				width: "60px",
																				textAlign: "right",
																			},
																		}}
																	>
																		<span className={classes.inputLabel}>
																			Offsets (MT)
																		</span>
																		<NumericFormat
																			value={Intl.NumberFormat("en", {
																				maximumFractionDigits: 0,
																				minimumFractionDigits: 0,
																			}).format(
																				Math.ceil(
																					(certification.estimations[index]
																						.lca_document?.emissions ?? 0) *
																						(certification.estimations[index]
																							.amount ?? 0) *
																						0.001 ?? 0
																				)
																			)}
																			name={"offsets"}
																			placeholder="0"
																			customInput={TextField}
																			style={{
																				background: "white",
																			}}
																			type="text"
																			disabled={true}
																			thousandSeparator={","}
																			onValueChange={({ value: v }) => {}}
																		/>
																	</Box>
																</Box>
																<Divider
																	sx={{
																		marginTop: 2,
																		marginBottom: 2,
																		opacity: 0.5,
																	}}
																/>
															</Box>
														);
													})}
											<Box
												sx={{
													backgroundColor: "#fafafa",
													padding: 4,
													display: "flex",
													gap: 12,
													justifyContent: {
														xs: "space-between",
														sm: "space-between",
														md: "flex-end",
													},
												}}
											>
												<Typography fontWeight={500}>
													Offsets to be purchased:
												</Typography>
												<Typography fontWeight={500}>
													{Intl.NumberFormat("en", {
														maximumFractionDigits: 0,
														minimumFractionDigits: 0,
													}).format(
														Math.ceil(
															certification?.estimations.reduce(
																(prev, current) => {
																	return (
																		prev +
																		Math.ceil(
																			(current.lca_document?.emissions ?? 0) *
																				(!isNaN(current.amount ?? 0) &&
																				current.amount
																					? current.amount
																					: 0) *
																				0.001
																		)
																	);
																},
																0
															) ?? 0
														)
													)}{" "}
													(MT)
												</Typography>
											</Box>
										</Box>
									)}

								{false &&
									!loadingDocuSignResult &&
									certification?.certification_process_steps[currentStep].step
										.step_type === "OTHER" && (
										<ShareImpact
											first_time_fee_paid={
												certification?.first_time_fee_paid ?? null
											}
											estimations={certification?.estimations}
										/>
									)}
								{certification.certification_process_steps[currentStep].step
									.step_type === "CERTIFICATION_SUMMARY" && (
									<Grid
										container
										spacing={2}
										sx={{
											flex: 1,
											marginTop: -4,
											display: "flex",
											justifyContent:
												user && user.is_superuser
													? "space-between"
													: "flex-end",
										}}
									>
										<Grid
											item
											xs={12}
											sm={12}
											md={12}
											lg={!user?.is_superuser ? 12 : 6}
											sx={{
												height: { md: "auto", lg: "100%" },
												display: "flex",
												flexDirection: "column",
											}}
										>
											<Typography
												sx={{
													fontSize: 12,
													marginBottom: 1,
													marginTop: 4,
												}}
											>
												PURCHASE SUMMARY
											</Typography>
											<Box
												sx={{
													backgroundColor: "#fafafa",
													padding: 4,
													minHeight: "256px",
													display: "flex",
													flexDirection: "column",
												}}
											>
												<Box
													sx={{
														display: "flex",
														justifyContent: "space-between",
														flexDirection: {
															xs: "column",
															sm: "row",
														},
													}}
												>
													<Typography fontWeight={500}>
														Offsets to be purchased:
													</Typography>
													<Typography fontWeight={500}>
														{Intl.NumberFormat("en", {
															maximumFractionDigits: 0,
															minimumFractionDigits: 0,
														}).format(
															Math.ceil(
																certification?.estimations.reduce(
																	(prev, current) => {
																		return (
																			prev +
																			Math.ceil(
																				(current.lca_document?.emissions ?? 0) *
																					(!isNaN(current.amount ?? 0) &&
																					current.amount
																						? current.amount
																						: 0) *
																					0.001
																			)
																		);
																	},
																	0
																) ?? 0
															)
														)}
													</Typography>
												</Box>
												<Box
													sx={{
														display: "flex",
														marginTop: 1,
														justifyContent: "space-between",
														flexDirection: {
															xs: "column",
															sm: "row",
														},
													}}
												>
													<Typography fontWeight={500}>
														Price per offset:
													</Typography>
													<Typography fontWeight={500}>
														{Intl.NumberFormat("en", {
															maximumFractionDigits: 2,
															minimumFractionDigits: 2,
															style: "currency",
															currency: "usd",
														}).format(12.5)}
													</Typography>
												</Box>
												{!certification.company.first_time_fee_paid && (
													<Box
														sx={{
															display: "flex",
															marginTop: 1,
															justifyContent: "space-between",
															flexDirection: {
																xs: "column",
																sm: "row",
															},
														}}
													>
														<Typography fontWeight={500}>
															First time certification fee:
														</Typography>
														<Typography fontWeight={500}>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 2,
																minimumFractionDigits: 2,
																style: "currency",
																currency: "usd",
															}).format(1000)}
														</Typography>
													</Box>
												)}

												<Box
													sx={{
														display: "flex",
														marginTop: 1,
														justifyContent: "space-between",
														flexDirection: {
															xs: "column",
															sm: "row",
														},
													}}
												>
													<Typography fontWeight={500}>
														Annual certification fee (per product):
													</Typography>
													<Typography fontWeight={500} textAlign={"right"}>
														{certification.estimations.length} x{" "}
														{Intl.NumberFormat("en", {
															maximumFractionDigits: 2,
															minimumFractionDigits: 2,
															style: "currency",
															currency: "usd",
														}).format(250)}
													</Typography>
												</Box>
												<Box
													sx={{
														display: "flex",
														marginTop: 4,
														justifyContent: "space-between",
													}}
												>
													<Typography sx={{ fontWeight: 500 }}>
														Total Price:
													</Typography>
													<Typography sx={{ fontWeight: 500 }}>
														{Intl.NumberFormat("en", {
															maximumFractionDigits: 2,
															minimumFractionDigits: 2,
															style: "currency",
															currency: "usd",
														}).format(
															Math.ceil(
																certification?.estimations.reduce(
																	(prev, current) => {
																		return (
																			prev +
																			Math.ceil(
																				(current.lca_document?.emissions ?? 0) *
																					(!isNaN(current.amount ?? 0) &&
																					current.amount
																						? current.amount
																						: 0) *
																					0.001
																			)
																		);
																	},
																	0
																) ?? 0
															) *
																12.5 +
																certification?.estimations.length * 250 +
																(!certification?.company.first_time_fee_paid
																	? 1000
																	: 0)
														)}
													</Typography>
												</Box>

												{Math.ceil(
													certification?.estimations.reduce((prev, current) => {
														return (
															prev +
															Math.ceil(
																(current.lca_document?.emissions ?? 0) *
																	(!isNaN(current.amount ?? 0) && current.amount
																		? current.amount
																		: 0) *
																	0.001
															)
														);
													}, 0) ?? 0
												) *
													12.5 +
													certification?.estimations.length * 250 +
													(!certification?.company.first_time_fee_paid
														? 1000
														: 0) <
													5000 &&
													!certification.company.first_time_fee_paid && (
														<>
															<Box
																sx={{
																	display: "flex",
																	marginTop: 4,
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	sx={{ fontWeight: 500, color: "#EB4B4B" }}
																>
																	Annual product certification fees + offsets
																	minimum:
																</Typography>
																<Typography
																	sx={{ fontWeight: 500, color: "#EB4B4B" }}
																>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(5000)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	sx={{ fontWeight: 500, color: "#EB4B4B" }}
																>
																	Additional offsets you will be purchasing to
																	reach minimum:
																</Typography>
																<Typography
																	sx={{ fontWeight: 500, color: "#EB4B4B" }}
																>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 0,
																		minimumFractionDigits: 0,
																	}).format(
																		(5000 -
																			(!certification.company
																				.first_time_fee_paid
																				? 1000
																				: 0) -
																			(Math.ceil(
																				certification?.estimations.reduce(
																					(prev, current) => {
																						return (
																							prev +
																							Math.ceil(
																								(current.lca_document
																									?.emissions ?? 0) *
																									(!isNaN(
																										current.amount ?? 0
																									) && current.amount
																										? current.amount
																										: 0) *
																									0.001
																							)
																						);
																					},
																					0
																				) ?? 0
																			) *
																				12.5 +
																				certification?.estimations.length *
																					250)) /
																			12.5
																	)}
																</Typography>
															</Box>
														</>
													)}
											</Box>
										</Grid>
										{user && user.is_superuser && (
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={6}
												sx={{ height: { md: "auto", lg: "100%" } }}
											>
												<Typography
													sx={{
														fontSize: 12,
														marginBottom: 1,
														marginTop: 4,
													}}
												>
													COMPANY SUMMARY
												</Typography>
												<Box
													sx={{
														backgroundColor: "#fafafa",
														padding: 4,
														minHeight: "256px",
														flex: 1,
														display: "flex",
														flexDirection: "column",
													}}
												>
													<Box
														sx={{
															display: "flex",
															justifyContent: "space-between",
															flexDirection: {
																xs: "column",
																sm: "row",
															},
														}}
													>
														<Typography fontWeight={500}>
															Company Name:
														</Typography>
														<Typography fontWeight={500}>
															{certification.company.name}
														</Typography>
													</Box>

													<Box
														sx={{
															display: "flex",
															marginTop: 1,
															justifyContent: "space-between",
															flexDirection: {
																xs: "column",
																sm: "row",
															},
														}}
													>
														<Typography fontWeight={500}>
															Client's Name:
														</Typography>
														<Typography fontWeight={500}>
															{certification.company.contact_name ?? ""}
														</Typography>
													</Box>
													<Box
														sx={{
															display: "flex",
															marginTop: 1,
															justifyContent: "space-between",
															flexDirection: {
																xs: "column",
																sm: "row",
															},
														}}
													>
														<Typography fontWeight={500}>
															Company Offset Remaining:
														</Typography>
														<Typography fontWeight={500}>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 0,
																minimumFractionDigits: 0,
															}).format(balance)}
														</Typography>
													</Box>
													<Box
														sx={{
															display: "flex",
															marginTop: 1,
															justifyContent: "space-between",
															flexDirection: {
																xs: "column",
																sm: "row",
															},
														}}
													>
														<Typography fontWeight={500}>
															Offsets To Be Purchased:
														</Typography>
														<Typography fontWeight={500}>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 0,
																minimumFractionDigits: 0,
															}).format(
																Math.ceil(
																	certification?.estimations.reduce(
																		(prev, current) => {
																			return (
																				prev +
																				Math.ceil(
																					(current.lca_document?.emissions ??
																						0) *
																						(!isNaN(current.amount ?? 0) &&
																						current.amount
																							? current.amount
																							: 0) *
																						0.001
																				)
																			);
																		},
																		0
																	) ?? 0
																)
															)}
														</Typography>
													</Box>
													<Box
														sx={{
															display: "flex",
															marginTop: 1,
															justifyContent: "space-between",
															flexDirection: {
																xs: "column",
																sm: "row",
															},
														}}
													>
														<Typography fontWeight={500}>
															Future Offset Inventory:
														</Typography>
														<Typography fontWeight={500}>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 0,
																minimumFractionDigits: 0,
															}).format(
																Math.ceil(
																	certification?.estimations.reduce(
																		(prev, current) => {
																			return (
																				prev +
																				Math.ceil(
																					(current.lca_document?.emissions ??
																						0) *
																						(!isNaN(current.amount ?? 0) &&
																						current.amount
																							? current.amount
																							: 0) *
																						0.001
																				)
																			);
																		},
																		0
																	) ?? 0
																) + balance
															)}
														</Typography>
													</Box>
													<Box
														sx={{
															display: "flex",
															marginTop: 1,
															justifyContent: "space-between",
															flexDirection: {
																xs: "column",
																sm: "row",
															},
														}}
													>
														<Typography fontWeight={500}>
															Active Products:
														</Typography>
														<Typography fontWeight={500}>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 0,
																minimumFractionDigits: 0,
															}).format(productsCount)}
														</Typography>
													</Box>
												</Box>
											</Grid>
										)}
									</Grid>
								)}
								{certification?.certification_process_steps[currentStep].step
									.step_type === "CERTIFICATION_SUMMARY" &&
								!user?.is_superuser ? (
									!certification?.requested_approval ? (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												marginTop: 2,
												width: "100%",
											}}
										>
											<Typography sx={{ marginBottom: 1 }}>
												{Math.ceil(
													certification?.estimations.reduce((prev, current) => {
														return (
															prev +
															Math.ceil(
																(current.lca_document?.emissions ?? 0) *
																	(!isNaN(current.amount ?? 0) && current.amount
																		? current.amount
																		: 0) *
																	0.001
															)
														);
													}, 0) ?? 0
												) *
													12.5 +
													certification?.estimations.length * 250 +
													1000 <
													5000 && !certification?.company.first_time_fee_paid
													? 'By clicking the "Agree" button, you are agreeing to purchase the additional offsets to reach the annual certification fee minimum of $5,000. These additional offsets will be added to the company\'s pool of offsets and will be credited against the quarterly sales of the products you have certified.'
													: 'By clicking the "Agree" button, you are agreeing to purchase the displayed options.'}
											</Typography>
											<Box
												sx={{
													display: "flex",
													gap: 1,
													justifyContent: "flex-end",
												}}
											>
												{!certification?.requested_approval && (
													<Button
														variant="outlined"
														sx={{ maxWidth: 150 }}
														onClick={() => {
															setCurrentStep(currentStep - 1);
															setOriginalEstimations(certification.estimations);
															setCurrentProducts(
																certification.estimations.map(
																	(estimation) => estimation.product
																)
															);
														}}
													>
														Back
													</Button>
												)}
												<Button
													variant="contained"
													sx={{ maxWidth: 150 }}
													onClick={() => {
														setShowAgreeModal(true);
													}}
												>
													Agree
												</Button>
											</Box>
										</Box>
									) : (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												marginTop: 2,
												width: "100%",
											}}
										>
											<Alert severity="info">
												Certification requested. Now, waiting for Admin's
												approval.
											</Alert>
										</Box>
									)
								) : null}
								{!loadingDocuSignResult &&
									certification?.certification_process_steps[currentStep].step
										.step_type === "CERTIFICATION_SUMMARY" && (
										<Box>
											{certification.certification_process_steps[currentStep]
												.reject_reason && (
												<Alert severity="error" sx={{ marginBottom: 4 }}>
													Your certification has been rejected:{" "}
													{
														certification.certification_process_steps[
															currentStep
														].reject_reason
													}
												</Alert>
											)}
											{certification?.estimations && (
												<ProductSummary
													certification={certification}
													setCertification={setCertification}
													refetchCertification={refetchCertification}
													setShowConfirmation={setShowConfirmation}
													setConfirmationData={setConfirmationData}
													currentStep={currentStep}
												/>
											)}
										</Box>
									)}

								{!loadingDocuSignResult &&
								certification?.certification_process_steps[currentStep].step
									.step_type === "PURCHASE_OFFSETS" &&
								(!certification?.manual_approval || !user?.is_superuser) ? (
									certification?.certification_process_steps[currentStep]
										.payment_status === "NOT_STARTED" ? (
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
											}}
										>
											<Button
												variant="contained"
												onClick={() => {
													setShowPurchaseOffsets(true);
												}}
												sx={{ marginRight: 2 }}
											>
												START PURCHASE PROCESS
											</Button>
										</Box>
									) : certification?.certification_process_steps[currentStep]
											.payment_status === "PENDING" ? (
										user?.is_superuser ? (
											<Box>
												<Alert severity="info">
													This payment has been executed with a "pay-later"
													method.
													<br />
													<b>
														If you want to mark it as paid, please, click the
														button below.
													</b>
												</Alert>
												<Button
													variant="contained"
													onClick={() => {
														setShowMarkItAsPaidModal(true);
													}}
													sx={{ marginTop: 2 }}
												>
													MARK IT AS PAID
												</Button>
											</Box>
										) : (
											<Alert severity="info">
												Your payment order has been submitted and is now waiting
												for approval and verification from a ClimeCo
												representative.
												<br />
												<b>
													You will receive an email when completed (1-2 business
													days).
												</b>
											</Alert>
										)
									) : null
								) : null}
								{!loadingDocuSignResult &&
									certification?.certification_process_steps[currentStep].step
										.step_type === "PURCHASE_OFFSETS" &&
									certification?.manual_approval &&
									user?.is_superuser && (
										<>
											<Divider sx={{ marginBottom: 3 }} />
											<Typography variant="h6">
												Manually add offsets for this certification
											</Typography>
											<span className={classes.inputLabel}>OFFSETS</span>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													gap: 2,
												}}
											>
												<NumericFormat
													value={manualOffsets}
													style={{
														border: "1px solid rgb(3, 176, 147)",
														borderRadius: 4,
														maxWidth: 200,
													}}
													name={"amount"}
													placeholder="Offsets"
													customInput={TextField}
													type="text"
													allowNegative={false}
													decimalScale={0}
													autoFocus={true}
													thousandSeparator={","}
													onValueChange={({ floatValue }) => {
														setManualOffsets(floatValue);
													}}
												/>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													flex: 1,
												}}
											>
												<span className={classes.inputLabel}>
													Price per Offset
												</span>
												<NumericFormat
													value={manualOffsetsPrice}
													placeholder="Price per Offset"
													customInput={TextField}
													onValueChange={({ value }) => {
														setManualOffsetsPrice(parseFloat(value));
													}}
													style={{
														border: "1px solid rgb(3, 176, 147)",
														borderRadius: 4,
														maxWidth: 200,
													}}
													allowNegative={false}
													decimalScale={2}
													thousandSeparator={","}
												/>
											</Box>
											<Button
												variant="contained"
												onClick={() => {
													mutateAddOffsets();
												}}
												sx={{
													marginTop: 4,
													alignSelf: "center",
													minHeight: 34,
												}}
												disabled={
													isLoading ||
													isLoadingMutateAddOffsets ||
													manualOffsets === undefined ||
													manualOffsets < 0
												}
											>
												{isLoading || isLoadingMutateAddOffsets ? (
													<CircularProgress size={12} sx={{ color: "white" }} />
												) : (
													"Save manual offsets"
												)}
											</Button>
										</>
									)}

								{!loadingDocuSignResult &&
									certification?.certification_process_steps[currentStep].step
										.step_type === "REDIRECTION" &&
									!certification?.manual_approval &&
									!user?.is_superuser &&
									certification?.climeco_contract_url_customer && (
										<Box sx={{ marginTop: 4, marginBottom: 4 }}>
											<Typography sx={{ marginBottom: 2 }}>
												Click on the link or copy it if you want to share it.
												Have in mind <b>it expires</b>, so come back here to
												generate a new one if needed.
											</Typography>
											<br />
											<a href={certification?.climeco_contract_url_customer}>
												Contract link
											</a>{" "}
											<IconButton
												onClick={async () => {
													try {
														await navigator.clipboard.writeText(
															certification!.climeco_contract_url_customer!
														);
														enqueueSnackbar("Link has been copied!", {
															variant: "success",
														});
													} catch (err) {
														console.error("Failed to copy: ", err);
														enqueueSnackbar("Link can't be copied!", {
															variant: "success",
														});
													}
												}}
											>
												<ContentCopyIcon></ContentCopyIcon>
											</IconButton>
										</Box>
									)}
								{!loadingDocuSignResult &&
									certification?.certification_process_steps[currentStep].step
										.step_type === "REDIRECTION" &&
									certification?.manual_approval &&
									user?.is_superuser && (
										<>
											<Divider sx={{ marginBottom: 3 }} />
											<Typography variant="h6">
												Add all contract documents for this certification
											</Typography>
											<span className={classes.inputLabel}>
												ADD CONTRACT DOCUMENTS
											</span>
											<UploadContracts
												id={certification.id}
												companyId={certification.company.id}
												certificationProcessId={
													certification?.certification_process_steps[
														currentStep
													].id
												}
												callback={refetchCertification}
											/>
										</>
									)}
								<Box
									sx={{
										display: "flex",
										justifyContent: ["REDIRECTION", "WAITING"].find(
											(step) =>
												step ===
												certification?.certification_process_steps[currentStep]
													.step.step_type
										)
											? "center"
											: "flex-end",
										marginTop: 6,
									}}
								>
									{certification?.certification_process_steps[currentStep].step
										.step_type === "CERTIFICATION_SUMMARY" &&
										certification.certification_process_steps[currentStep]
											.reject_reason && (
											<Button
												variant="outlined"
												onClick={() => {
													setCurrentStep(currentStep - 1);
													setOriginalEstimations(certification.estimations);
													setCurrentProducts(
														certification.estimations.map(
															(estimation) => estimation.product
														)
													);
												}}
												sx={{ marginRight: 2 }}
											>
												RESUBMIT
											</Button>
										)}
								</Box>
							</Box>
						</>
					)}
			</Box>
			<BasicModal
				width={800}
				showModal={showAgreeModal}
				handleClose={() => {
					setShowAgreeModal(false);
				}}
				children={
					certification && (
						<CertificationAgree
							text="By clicking 'AGREE' you are agreeing to the costs outlined in the below 'Purchase Summary' and advancing to the payment step."
							button1="Cancel"
							button1Callback={() => {
								setShowAgreeModal(false);
							}}
							button2="Agree"
							button2Callback={() => {
								mutateApproval();
							}}
							isLoading={isLoadingApproval}
							certification={certification}
						/>
					)
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showConfirmation}
				handleClose={() => {
					setShowConfirmation(false);
				}}
				children={
					<CertificationConfirmation
						confirmationData={confirmationData}
						handleModalClose={() => {
							setShowConfirmation(false);
						}}
						isLoading={
							isLoadingCreateEstimation ||
							isLoadingUpdateEstimation ||
							isLoading ||
							isLoadingDeleteEstimation
						}
					/>
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showMarkItAsPaidModal}
				handleClose={() => {
					setShowMarkItAsPaidModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							Are you sure you want to mark this certification as paid?
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowMarkItAsPaidModal(false);
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={isLoadingMarkItAsPaid}
								onClick={() => {
									mutateMarkItAsPaid();
								}}
							>
								{isLoadingMarkItAsPaid ? (
									<CircularProgress size={12} style={{ color: "white" }} />
								) : (
									"Mark it as paid"
								)}
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={800}
				showModal={showCreateProductModal}
				handleClose={() => {
					setShowCreateProductModal(false);
				}}
				children={
					<BulkProducts
						inModal
						inModalCallback={(products) => {
							setShowCreateProductModal(false);
							refetchActiveProducts();
							setCurrentProducts((elements) => [...elements, ...products]);
						}}
					/>
				}
			/>
			<BasicModal
				width={800}
				showModal={showTitleModal}
				handleClose={() => {
					setShowTitleModal(false);
				}}
				children={
					<Box>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Products List
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							{certification &&
								certification.estimations.map((est, index) => (
									<p key={est.id}>
										{est.product?.name}
										{(est.product?.asin || est.product?.upc) && (
											<>
												{" - "}
												{est.product?.asin || est.product?.upc || "--"}
											</>
										)}
									</p>
								))}
						</Typography>
					</Box>
				}
			/>
			<BasicModal
				width={800}
				showModal={showAmazonModal}
				handleClose={() => {
					setShowAmazonModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h6"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							Congratulations on getting your products ClimeCo Certified! If you
							are an Amazon seller, you are now eligible to receive the Climate
							Pledge Friendly badge. Please navigate to the External
							Certification tab to get the badge.
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowAmazonModal(false);
								}}
							>
								No
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={false}
								onClick={() => {
									navigate("/certifications/create", {
										state: {
											products: certification?.estimations.map(
												(estimation) => estimation.product
											) as IProduct[],
										},
									});
								}}
							>
								Yes
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={1200}
				showModal={showPurchaseOffsets}
				handleClose={() => {
					setShowPurchaseOffsets(false);
				}}
				hideClose={false}
				children={
					certification?.estimations && (
						<PurchaseOffsets
							quantity={
								(5000 -
									(!certification.company.first_time_fee_paid ? 1000 : 0) -
									(Math.ceil(
										certification?.estimations.reduce((prev, current) => {
											return (
												prev +
												Math.ceil(
													(current.lca_document?.emissions ?? 0) *
														(!isNaN(current.amount ?? 0) && current.amount
															? current.amount
															: 0) *
														0.001
												)
											);
										}, 0) ?? 0
									) *
										12.5 +
										certification?.estimations.length * 250)) /
									12.5 >
									0 && !certification.company.first_time_fee_paid
									? (5000 -
											(!certification.company.first_time_fee_paid ? 1000 : 0) -
											(Math.ceil(
												certification?.estimations.reduce((prev, current) => {
													return (
														prev +
														Math.ceil(
															(current.lca_document?.emissions ?? 0) *
																(!isNaN(current.amount ?? 0) && current.amount
																	? current.amount
																	: 0) *
																0.001
														)
													);
												}, 0) ?? 0
											) *
												12.5 +
												certification?.estimations.length * 250)) /
											12.5 +
									  certification.estimations.reduce(
											(prev, current) =>
												prev +
												Math.ceil(
													(current.lca_document?.emissions ?? 0) *
														(current.amount ?? 0)
												),
											0
									  ) /
											1000
									: Math.ceil(
											certification.estimations.reduce(
												(prev, current) =>
													prev +
													Math.ceil(
														(current.lca_document?.emissions ?? 0) *
															(current.amount ?? 0) *
															0.001
													),
												0
											)
									  )
							}
							productsCount={certification.estimations.length}
							needsFirstTimeFee={
								certification.company.first_time_fee_paid === null
							}
							certificationProcessId={certification.id}
							callback={() => {
								enqueueSnackbar("Successful payment! Moving to next step...", {
									variant: "success",
								});

								setTimeout(() => {
									refetchCertification();
								}, 5000);
							}}
							height={"700px"}
							inModal={true}
							closeModal={() => {
								setShowPurchaseOffsets(false);
							}}
							offsetsLoadingMessages={offsetsLoadingMessages}
							messagesIndex={messagesIndex}
							setLoadingOffsetsResult={setLoadingOffsetsResult}
						/>
					)
				}
			/>
		</Box>
	);
}
