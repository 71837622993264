import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export function NotFoundPage() {
	const navigate = useNavigate();

	return (
		<Container
			component="main"
			maxWidth="sm"
			sx={{
				mt: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
			}}
		>
			<Typography component="h1" variant="h2" gutterBottom>
				404 - Page Not Found
			</Typography>
			<Typography variant="body1" gutterBottom sx={{ marginBottom: 4 }}>
				Oops! We can't find the page you're looking for.
			</Typography>
			<Button variant="contained" color="primary" onClick={() => navigate("/")}>
				Go to Dashboard
			</Button>
		</Container>
	);
}
