import React, { useState, useEffect } from "react";
import { TextField, IconButton, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import { PropTypes } from "./ArrayInput.types";

const ArrayInput = ({ array, setArray, title }: PropTypes) => {
	const initialArray = array && array.length > 0 ? array : [""];
	const [inputValues, setInputValues] = useState<string[]>(initialArray);

	useEffect(() => {
		setInputValues(array ?? [""]);
	}, [array]);

	const handleInputChange = (index: number, value: string) => {
		const newInputValues = [...inputValues];
		newInputValues[index] = value;
		setInputValues(newInputValues);
		setArray(newInputValues);
	};

	const handleAddInput = () => {
		setInputValues([...inputValues, ""]);
		setArray([...inputValues, ""]);
	};

	const handleRemoveInput = (index: number) => {
		if (index === 0 && inputValues.length === 1) return;
		const newInputValues = [...inputValues];
		newInputValues.splice(index, 1);
		setInputValues(newInputValues);
		setArray(newInputValues);
	};

	return (
		<Box sx={{ width: "100%" }}>
			{inputValues.map((value, index) => (
				<Box
					key={index + " - " + value}
					display="flex"
					alignItems="center"
					marginBottom={2}
				>
					<TextField
						placeholder={title}
						variant="outlined"
						value={value}
						onChange={(e) => handleInputChange(index, e.target.value)}
						sx={{ flex: 1 }}
					/>
					<IconButton
						color="primary"
						onClick={() => handleRemoveInput(index)}
						disabled={index === 0 && inputValues.length === 1}
					>
						<RemoveIcon />
					</IconButton>
					<IconButton color="primary" onClick={handleAddInput}>
						<AddIcon />
					</IconButton>
				</Box>
			))}
		</Box>
	);
};

export default ArrayInput;
