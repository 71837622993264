import { useContext, useState, useEffect } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Typography,
} from "@mui/material";
import { Heading } from "../Heading";
import { Stepper } from "../Stepper";
import { BasicModal, MultiItemSelector } from "..";
import {
	ICertification,
	ICertificationSteps,
	IEstimation,
	IProduct,
	IStep,
} from "../../models";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	certification as certificationActions,
	certificationProcessStep as certificationProcessStepActions,
	estimation as estimationsActions,
	product as productActions,
	certification as certificationAction,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfettiExplosion from "react-confetti-explosion";
import ReactHtmlParser from "react-html-parser";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AmazonBadge from "../../assets/amazon-badge.png";

type Provider = {
	id: number;
	name: string;
	certification_type: string;
};

export function ExternalCertificationProcess() {
	const { id } = useParams();
	const [currentStep, setCurrentStep] = useState(0);

	const [productsFilter, setProductsFilter] = useState("");
	const [currentProducts, setCurrentProducts] = useState(Array<IProduct>());
	const [currentEstimations] = useState(Array<IEstimation>());
	const [originalEstimations, setOriginalEstimations] = useState(
		Array<IEstimation>()
	);
	const [products, setProducts] = useState<Array<IProduct>>([]);
	const [certification, setCertification] = useState<ICertification | null>(
		null
	);
	const [reloadCertification, setReloadCertification] = useState(true);
	const { user } = useContext(UserContext);

	const [providers, setProviders] = useState<Provider[]>([]);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const [showTitleModal, setShowTitleModal] = useState(false);
	const [showCongratulationModal, setShowCongratulationModal] = useState(false);

	useQuery(
		"providers",
		() =>
			certificationAction.listProviders({
				certification_type: "DEFAULT_EXTERNAL_CERTIFICATION",
			}),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProviders(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const marketplaces = [
		{ id: 1, name: "United States", domain: "https://www.amazon.com/dp/" },
		{ id: 3, name: "United Kingdom", domain: "https://www.amazon.co.uk/dp/" },
		{ id: 4, name: "Germany", domain: "https://www.amazon.de/dp/" },
		{ id: 35691, name: "Italy", domain: "https://www.amazon.it/dp/" },
		{ id: 44551, name: "Spain", domain: "https://www.amazon.es/dp/" },
	];

	const getMarketPlace = (id: number) => {
		return marketplaces.find((marketplace) => marketplace.id === id)!.name;
	};

	const { refetch: refetchActiveProducts, isLoading: isLoadingProducts } =
		useQuery(
			"products-active",
			() =>
				productActions.list(
					user?.is_superuser ? certification?.company.id : user?.company.id,
					undefined,
					[],
					"ClimeCo certified",
					1,
					undefined,
					1000
				),
			{
				enabled: false,
				refetchOnWindowFocus: false,
				retry: false,
				onSuccess: (res) => {
					const products = res.results.filter(
						(product) =>
							product.certification_processes?.find(
								(certificationProcess) =>
									certificationProcess.certification?.certification_type ===
										"CLIMECO_INTERNAL_CERTIFICATION" &&
									!certificationProcess.certification_process_steps.find(
										(certificationProcessStep) =>
											certificationProcessStep.status !== "COMPLETED"
									)
							) &&
							!product.certification_processes?.find(
								(certificationProcess) =>
									certificationProcess.certification?.certification_type ===
									"DEFAULT_EXTERNAL_CERTIFICATION"
							)
					);
					setProducts(products);
					setCurrentProducts(products);
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	useEffect(() => {
		if (certification) {
			refetchActiveProducts();
		}
	}, [certification, refetchActiveProducts]);

	const { refetch: refetchCertification } = useQuery(
		"climeco-certifications-detail",
		() =>
			certificationActions.getOne(
				false,
				parseInt(id!),
				user?.is_superuser ? undefined : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				let step = 0;
				const rejectedStep = res.certification_process_steps.findIndex(
					(step) => step.status === "REJECTED"
				);

				const pendingStep = res.certification_process_steps.findIndex(
					(step) => step.status === "PENDING"
				);

				step =
					rejectedStep > -1
						? rejectedStep
						: pendingStep > -1
						? pendingStep
						: res.certification_process_steps.length - 1;

				if (
					res.certification_process_steps[step].step.step_type ===
					"PRODUCT_LIST"
				) {
					res.estimations.map((estimation) => {
						estimation.amount = undefined;
						return estimation;
					});
				}
				setCertification(res);
				setReloadCertification(true);
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	const getEstimations = () => {
		if (currentStep === 0) {
			return currentProducts.map((product, index) => {
				return {
					id: index,
					value: 0,
					//emissions: 0,
					product: product,
				};
			});
		} else {
			return currentEstimations;
		}
	};

	const {
		isLoading: isLoadingUpdateProductMutate,
		mutate: updateProductMutate,
	} = useMutation(
		"update-product",
		({
			productId,
			asin,
			marketplaces,
		}: {
			productId: number;
			asin: string;
			marketplaces: string;
		}) =>
			productActions.update({
				id: productId,
				asin: asin,
				marketplaces: marketplaces,
				companyId: certification!.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingCreateCertification,
		mutate: createCertification,
	} = useMutation(
		"create-certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: false,
				name: "New",
				companyId: user!.company.id,
				isHistorical: false,
				certification: providers.find(
					(p) => p.certification_type === "DEFAULT_EXTERNAL_CERTIFICATION"
				)!.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingCreateEstimation,
		mutate: createEstimationMutate,
	} = useMutation(
		"estimations",
		(estimation: IEstimation) =>
			estimationsActions.create({
				amount: estimation.amount,
				//emissions: estimation.emissions,
				product: estimation.product.id,
				certificationProcess:
					estimation.certification_process?.id ?? certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingDeleteEstimation,
		mutate: deleteEstimationMutate,
	} = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser ? undefined : user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingUpdateEstimation,
		mutate: updateEstimationMutate,
	} = useMutation(
		"estimations-update",
		(estimation: IEstimation) =>
			estimationsActions.update({
				id: estimation.id,
				amount: estimation.amount,
				//emissions: estimation.emissions,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingToggleBadge, mutate: toggleBadge } = useMutation(
		"estimations-toggle-marketplace-badge",
		({
			estimationId,
			marketPlaceBadgeId,
		}: {
			estimationId: number;
			marketPlaceBadgeId: number;
		}) =>
			estimationsActions.toggleBadge({
				id: estimationId,
				marketPlaceBadgeId: marketPlaceBadgeId,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const {
		isLoading: isLoadingCertificationStep,
		mutate: mutateCertificationStep,
	} = useMutation(
		"certification-step-detail",
		() =>
			certificationProcessStepActions.update({
				id: certification!.certification_process_steps[currentStep].id,
				status: "COMPLETED",
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"certification-detail",
		() =>
			certificationActions.update({
				isClimeCo: false,
				estimations: getEstimations(),
				companyId: certification?.company.id,
				id: certification?.id ?? -1,
				description: "Amazon estimation",
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingGenerateCSV, mutate: generateCSV } = useMutation(
		"certification-generate-csv",
		(certificationProcess?: ICertification) =>
			certificationActions.generateCSV({
				isClimeCo: false,
				id: certificationProcess?.id ?? certification?.id ?? -1,
				companyId: user?.is_superuser
					? certificationProcess?.company?.id ?? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		refetch: refetchCertificationSteps,
		isLoading: isLoadingCertificationSteps,
	} = useQuery(
		"fetch-steps",
		() =>
			certificationActions.getSteps("DEFAULT_EXTERNAL_CERTIFICATION", false),
		{
			retry: false,
			enabled: false,
			onSuccess: (res: IStep[]) => {
				const certificationSteps = res.map((step) => {
					return {
						id: new Date().getTime(),
						status: "PENDING",
						payment_status: null,
						reject_reason: "",
						step,
					} as ICertificationSteps;
				});
				setCertification({
					id: -1,
					name: "ClimeCo Certification - " + new Date().getTime(),
					certification_process_steps: certificationSteps,
					company: user!.company,
					estimations: Array<IEstimation>(),
					is_historical: false,
					status: "COMPLETED",
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (id && user) {
			if (id === "create") {
				refetchCertificationSteps();
			} else {
				refetchCertification();
			}
		}
	}, [
		id,
		refetchCertification,
		user,
		enqueueSnackbar,
		refetchCertificationSteps,
	]);

	useEffect(() => {
		if (
			reloadCertification &&
			certification &&
			certification.certification_process_steps
		) {
			const rejectedStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "REJECTED"
			);

			const pendingStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "PENDING"
			);

			setCurrentStep(
				rejectedStep > -1
					? rejectedStep
					: pendingStep > -1
					? pendingStep
					: certification.certification_process_steps.length - 1
			);
			setReloadCertification(false);
		}
	}, [certification, reloadCertification]);

	const getTitle = () => {
		if (!certification?.estimations || certification.estimations.length === 0) {
			return "Amazon Certification";
		}

		if (certification.estimations.length <= 2) {
			return (
				"Amazon Certification - " +
				certification.estimations
					.map(
						(est) =>
							`${est.product?.name} - ${
								est.product?.asin || est.product?.upc || "--"
							}`
					)
					.join(", ")
			);
		}

		const firstTwoProducts = certification.estimations
			.slice(0, 2)
			.map(
				(est) =>
					`${est.product?.name} - ${
						est.product?.asin || est.product?.upc || "--"
					}`
			)
			.join(", ");
		const remainingCount = certification.estimations.length - 2;

		return `Amazon Certification - ${firstTwoProducts} and ${remainingCount} more products`;
	};

	useEffect(() => {
		if (
			currentStep ===
			(certification?.certification_process_steps.length ?? 0) - 1
		) {
			setTimeout(() => {
				setShowCongratulationModal(true);
			}, 3000);
		}
	}, [certification?.certification_process_steps.length, currentStep]);

	const isValidASIN = (asin: string): boolean => {
		const asinRegex = /^(B[\dA-Z]{9}|\d{9}[\dX])$/;
		return asinRegex.test(asin);
	};

	return (
		<Box>
			{/*<Typography
				variant="body2"
				fontSize={10}
				marginBottom={2}
				sx={{ cursor: "pointer" }}
				onClick={() => {
					navigate("/certifications");
				}}
			>
				{"BACK"}
			</Typography>*/}

			{currentStep ===
				(certification?.certification_process_steps.length ?? 0) - 1 && (
				<Box
					sx={{
						position: "absolute",
						top: "20%",
						left: "50%",
						transform: "translate(-20%, -50%)",
					}}
				>
					<ConfettiExplosion />
				</Box>
			)}

			<Heading
				title={getTitle()}
				showSearch={false}
				onTitleClick={() =>
					certification && certification.estimations.length > 2
						? setShowTitleModal(true)
						: null
				}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: {
						xs: "row",
						sm: "column",
					},
					gap: !matches ? 4 : 0,
				}}
			>
				{certification &&
					certification?.certification_process_steps &&
					certification?.certification_process_steps.length > 0 && (
						<>
							<Stepper
								orientation={!matches ? "vertical" : "horizontal"}
								currentStep={currentStep}
								lastStep={certification?.certification_process_steps.length - 1}
								steps={certification?.certification_process_steps.map(
									(step) => step.step.step_title
								)}
							/>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: {
										xs: "calc(100% - 82px)",
										sm: "100%",
									},
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Box>
										<Typography
											variant="h5"
											sx={{
												fontWeight: 700,
												marginTop: !matches ? 0 : 4,
											}}
										>
											{
												certification?.certification_process_steps[currentStep]
													.step.name
											}
										</Typography>
										<Typography
											sx={{ marginTop: 1, marginBottom: 4 }}
											variant="subtitle1"
										>
											{ReactHtmlParser(
												certification?.certification_process_steps[currentStep]
													.step.description ?? ""
											)}
										</Typography>
									</Box>

									{certification?.certification_process_steps[currentStep].step
										.button_title && (
										<Button
											variant="contained"
											sx={{
												minHeight: 34,
											}}
											disabled={(() => {
												switch (
													certification?.certification_process_steps[
														currentStep
													].step.step_type
												) {
													case "CHOOSE_PRODUCTS": {
														return currentProducts.length === 0 ||
															currentProducts.find(
																(product) =>
																	!product.marketplaces ||
																	product.marketplaces?.length === 0 ||
																	!product.asin ||
																	!isValidASIN(product.asin)
															)
															? true
															: false;
													}
													case "PRODUCT_LIST": {
														return certification.estimations.find(
															(estimation) => estimation.amount === undefined
														)
															? true
															: false;
													}

													default: {
														return false;
													}
												}
											})()}
											onClick={async () => {
												switch (
													certification?.certification_process_steps[
														currentStep
													].step.step_type
												) {
													case "CHOOSE_PRODUCTS": {
														let index = 0;

														for (const estimation of originalEstimations) {
															deleteEstimationMutate(estimation);
														}
														const createEstimation = (
															estimationId: number,
															amount: number,
															//emissions: number,
															product: IProduct,
															index: number,
															certificationProcess?: ICertification
														) => {
															updateProductMutate(
																{
																	productId: product.id,
																	asin: product.asin!,
																	marketplaces: product!
																		.marketplaces!.map(
																			(marketplace: { id: number }) =>
																				marketplace.id
																		)
																		.join(","),
																},
																{
																	onSuccess: () => {
																		createEstimationMutate(
																			{
																				id: estimationId,
																				amount: amount,
																				//emissions: emissions,
																				product: product,
																				certification_process:
																					certificationProcess,
																			},
																			{
																				onSuccess: () => {
																					index += 1;
																					if (index < currentProducts.length) {
																						createEstimation(
																							index,
																							0,
																							//0,
																							currentProducts[index],
																							index,
																							certificationProcess
																						);
																					} else {
																						generateCSV(certificationProcess, {
																							onSuccess: (res) => {
																								if (
																									certificationProcess &&
																									id === "create"
																								) {
																									navigate(
																										"/certifications/" +
																											certificationProcess!.id
																									);
																								} else {
																									refetchCertification();
																								}
																							},
																							onError: (error: any) => {
																								console.log(error);
																								enqueueSnackbar(
																									"Something went wrong.",
																									{ variant: "error" }
																								);
																							},
																						});
																					}
																				},
																				onError: (error) => {
																					console.log(error);
																					enqueueSnackbar(
																						"Something went wrong.",
																						{
																							variant: "error",
																						}
																					);
																				},
																			}
																		);
																	},
																	onError: (error) => {
																		console.log(error);
																		enqueueSnackbar("Something went wrong.", {
																			variant: "error",
																		});
																	},
																}
															);
														};

														if (certification.id === -1) {
															createCertification(null, {
																onSuccess: (res) => {
																	createEstimation(
																		index,
																		0,
																		//0,
																		currentProducts[index],
																		index,
																		res
																	);
																},
																onError: (error) => {
																	console.log(error);
																	enqueueSnackbar("Something went wrong.", {
																		variant: "error",
																	});
																},
															});
														} else {
															createEstimation(
																index,
																0,
																//0,
																currentProducts[index],
																index
															);
														}
														break;
													}
													case "INFORMATION": {
														mutate();
														break;
													}
													case "REDIRECTION": {
														if (
															!user?.is_superuser &&
															certification?.climeco_contract_url_customer
														) {
															window.location.href =
																certification?.climeco_contract_url_customer;
														}
														break;
													}
													case "WAITING": {
														if (
															(user?.is_superuser ?? false) &&
															certification?.climeco_contract_url_admin
														) {
															window.location.href =
																certification?.climeco_contract_url_admin;
														}
														break;
													}
													case "PRODUCT_LIST": {
														let index = 0;

														const updateEstimation = (
															id: number,
															amount: number,
															//emissions: number,
															product: IProduct,
															index: number
														) => {
															updateEstimationMutate(
																{
																	id: id,
																	amount: amount,
																	//emissions: emissions,
																	product: product,
																},
																{
																	onSuccess: () => {
																		index += 1;
																		if (
																			index < certification.estimations.length
																		) {
																			updateEstimation(
																				certification.estimations[index].id,
																				certification.estimations[index]
																					.amount!,
																				/*certification.estimations[index]
																						.emissions!,*/
																				certification.estimations[index]
																					.product,
																				index
																			);
																		} else {
																			refetchCertification();
																		}
																	},
																	onError: (error) => {
																		console.log(error);
																		enqueueSnackbar("Something went wrong.", {
																			variant: "error",
																		});
																	},
																}
															);
														};

														updateEstimation(
															certification.estimations[index].id,
															certification.estimations[index].amount!,
															//certification.estimations[index].emissions!,
															certification.estimations[index].product,
															index
														);

														break;
													}
													case "CERTIFICATION_SUMMARY": {
														mutateCertificationStep();
														break;
													}
													case "OTHER": {
														navigate("/products");
														break;
													}
													default: {
														if (
															currentStep + 1 <
															certification.certification_process_steps.length
														) {
															setCurrentStep(currentStep + 1);
														}
													}
												}
											}}
										>
											{isLoadingCreateEstimation ||
											isLoadingUpdateProductMutate ||
											isLoadingUpdateEstimation ||
											isLoading ||
											isLoadingCertificationStep ||
											isLoadingGenerateCSV ||
											isLoadingDeleteEstimation ||
											isLoadingCreateCertification ? (
												<CircularProgress size={12} sx={{ color: "white" }} />
											) : (
												certification?.certification_process_steps[currentStep]
													.step.button_title
											)}
										</Button>
									)}
								</Box>

								{certification?.certification_process_steps[currentStep].step
									.step_type === "CHOOSE_PRODUCTS" && (
									<Box sx={{ display: "flex", justifyContent: "center" }}>
										<MultiItemSelector
											title="Product(s) List"
											placeholderAutocomplete="Select Product(s)"
											buttonTitle="Add product(s)"
											listTitle="Product(s) Selected"
											emptyState="No products applied yet"
											setFilter={setProductsFilter}
											elements={products.filter(
												(product) =>
													!productsFilter ||
													product.name
														.toLowerCase()
														.includes(productsFilter.toLowerCase()) ||
													product.description
														.toLowerCase()
														.includes(productsFilter.toLowerCase()) ||
													(product.asin || product.upc || "")
														.toLowerCase()
														.includes(productsFilter.toLowerCase())
											)}
											selectedElements={currentProducts}
											setSelectedElements={(elements) =>
												setCurrentProducts(elements as Array<IProduct>)
											}
											external
											externalLoading={
												isLoadingProducts ||
												isLoadingCertificationSteps ||
												reloadCertification ||
												!certification
											}
										/>
									</Box>
								)}

								{certification?.certification_process_steps[currentStep].step
									.step_type === "CERTIFICATION_SUMMARY" && (
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											marginTop: 2,
											width: "100%",
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												width: "100%",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Button
												variant="contained"
												sx={{ maxWidth: 150 }}
												onClick={() => {
													const link = document.createElement("a");
													link.download = certification.csv!.split("/").pop()!;
													link.href = certification.csv!;
													link.click();
												}}
											>
												Download csv
											</Button>
										</Box>
										<Box
											sx={{
												marginTop: 2,
											}}
										>
											{certification.estimations
												.sort((a, b) => {
													const dateA = new Date(a.created_at ?? "").getTime();
													const dateB = new Date(b.created_at ?? "").getTime();

													return dateA - dateB;
												})
												.map((estimation, index) => {
													return (estimation.product.marketplaces ?? []).map(
														(marketplace) => {
															return (
																<Box
																	key={
																		estimation.id +
																		"-" +
																		index +
																		"-" +
																		marketplace.toString()
																	}
																	sx={{
																		display: "flex",
																		flexDirection: "column",
																		marginTop: 4,
																	}}
																>
																	<Box
																		sx={{
																			display: "flex",
																			alignItems: {
																				xs: "flex-start",
																				sm: "flex-start",
																				md: "center",
																			},
																			flexDirection: {
																				xs: "column",
																				sm: "column",
																				md: "row",
																			},
																			gap: 2,
																			flex: 1,
																			justifyContent: "space-between",
																		}}
																	>
																		<Typography
																			flex={1}
																			sx={{
																				flex: 1,
																				maxWidth: 400,
																			}}
																		>
																			{estimation.product.name +
																				" - " +
																				estimation.product.asin}
																		</Typography>
																		<Box
																			sx={{
																				display: "flex",
																				justifyContent: "left",
																				minWidth: 200,
																			}}
																		>
																			<Typography>
																				{getMarketPlace(
																					parseInt(marketplace.toString())
																				)}
																			</Typography>
																		</Box>
																		<Box
																			sx={{
																				display: "flex",
																				flexDirection: "column",
																			}}
																		>
																			<Box
																				sx={{
																					display: "flex",
																					flexDirection: "row",
																					width: "100%",
																					flex: 1,
																					maxWidth: 200,
																					textAlign: "center",
																					alignItems: "center",
																					justifyContent: {
																						xs: "flex-start",
																						sm: "flex-start",
																						md: "center",
																					},
																					gap: 1,
																				}}
																			>
																				{estimation.marketplaces!.find(
																					(m) =>
																						m.marketplace_id.toString() ===
																						marketplace.toString()
																				) ? (
																					<CheckCircleIcon
																						sx={{
																							fontSize: 14,
																							color: "#6ab04c",
																						}}
																					/>
																				) : (
																					<QueryBuilderIcon
																						sx={{ fontSize: 14 }}
																					/>
																				)}
																				<Typography sx={{ fontSize: 14 }}>
																					{estimation.marketplaces!.find(
																						(m) =>
																							m.marketplace_id.toString() ===
																							marketplace.toString()
																					)
																						? "Badge Active"
																						: "Waiting"}
																				</Typography>
																			</Box>
																			{!estimation.marketplaces!.find(
																				(m) =>
																					m.marketplace_id.toString() ===
																					marketplace.toString()
																			) && (
																				<Box>
																					<Button
																						onClick={() => {
																							toggleBadge({
																								estimationId: estimation.id,
																								marketPlaceBadgeId: parseInt(
																									marketplace.toString()
																								),
																							});
																						}}
																						disabled={isLoadingToggleBadge}
																					>
																						{isLoadingToggleBadge ? (
																							<CircularProgress
																								size={12}
																								sx={{ color: "white" }}
																							/>
																						) : (
																							"Confirm Badge"
																						)}
																					</Button>
																				</Box>
																			)}
																		</Box>
																	</Box>
																	<Divider
																		sx={{
																			marginTop: 2,
																			marginBottom: 2,
																			opacity: 0.5,
																		}}
																	/>
																</Box>
															);
														}
													);
												})}
										</Box>
									</Box>
								)}

								<Divider sx={{ marginTop: 4, marginBottom: 4 }} />

								{certification?.certification_process_steps[currentStep].step
									.step_type === "OTHER" && (
									<>
										<Typography>
											The following product(s) are now featured in Amazon's
											Climate Pledge Friendly program:
										</Typography>
										<Box sx={{ marginTop: 2 }}>
											{certification.estimations &&
												certification.estimations.map((estimation) =>
													estimation.marketplaces &&
													estimation.marketplaces.length > 0 ? (
														estimation.marketplaces.map(
															(marketplaceEstimation) => {
																const marketplaceInfo = marketplaces.find(
																	(m) =>
																		m.id ===
																		marketplaceEstimation.marketplace_id
																);
																const domain = marketplaceInfo
																	? marketplaceInfo.domain
																	: "https://www.amazon.com/dp/";
																const name = marketplaceInfo
																	? marketplaceInfo.name
																	: "Default";

																return (
																	<Box
																		key={marketplaceEstimation.id}
																		sx={{
																			display: "flex",
																			flexDirection: "row",
																			alignItems: "center",
																			gap: 2,
																			marginBottom: 2,
																		}}
																	>
																		<img
																			style={{ height: 30, width: 30 }}
																			src={AmazonBadge}
																			alt="Climate Pledge Friendly"
																		/>
																		<Typography>
																			{estimation.product.asin
																				? `${estimation.product.name} - ${estimation.product.asin}`
																				: estimation.product.name}
																		</Typography>
																		<Box
																			sx={{
																				display: "flex",
																				flexDirection: "row",
																				gap: 1,
																			}}
																		>
																			<Typography>
																				{name} Marketplace:
																			</Typography>
																			<a
																				href={`${domain}${estimation.product.asin}`}
																				target="_blank"
																				rel="noreferrer"
																			>
																				<Typography>Amazon Link</Typography>
																			</a>
																		</Box>
																	</Box>
																);
															}
														)
													) : (
														<Typography>{estimation.product.name}</Typography>
													)
												)}
										</Box>
									</>
								)}
								<Box
									sx={{
										display: "flex",
										justifyContent: ["REDIRECTION", "WAITING"].find(
											(step) =>
												step ===
												certification?.certification_process_steps[currentStep]
													.step.step_type
										)
											? "center"
											: "flex-end",
										marginTop: 6,
									}}
								>
									{(certification?.certification_process_steps[currentStep].step
										.step_type === "INFORMATION" ||
										certification?.certification_process_steps[currentStep].step
											.step_type === "PRODUCT_LIST") && (
										<Button
											variant="outlined"
											onClick={() => {
												setCurrentStep(currentStep - 1);
												setOriginalEstimations(certification.estimations);
												setCurrentProducts(
													certification.estimations.map(
														(estimation) => estimation.product
													)
												);
											}}
											sx={{ marginRight: 2 }}
										>
											BACK
										</Button>
									)}
								</Box>
							</Box>
						</>
					)}
			</Box>

			<BasicModal
				width={800}
				showModal={showTitleModal}
				handleClose={() => {
					setShowTitleModal(false);
				}}
				children={
					<Box>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Products List
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							{certification &&
								certification.estimations.map((est, index) => (
									<p key={est.id}>
										{est.product?.name} - {est.product?.asin}
									</p>
								))}
						</Typography>
					</Box>
				}
			/>

			<BasicModal
				width={600}
				showModal={showCongratulationModal}
				handleClose={() => {
					setShowCongratulationModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							Congratulations for getting your product(s) certified with Amazon
						</Typography>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							Close this modal to check them or click on the button below to
							continue
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
								}}
								onClick={() => {
									navigate("/");
								}}
							>
								Go to Dashboard
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
