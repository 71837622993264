import { PropTypes } from "./Stepper.types";
import { Box, Stepper as Steps, Step, StepLabel } from "@mui/material";

export function Stepper(props: PropTypes) {
	const { steps, currentStep, orientation, lastStep } = props;
	return (
		<Box sx={{ width: "100%", flex: 1 }}>
			<Steps
				activeStep={lastStep === currentStep ? currentStep + 1 : currentStep}
				alternativeLabel={orientation !== "vertical"}
				orientation={orientation ?? "horizontal"}
				sx={{
					"& .MuiStepLabel-vertical": {
						flexDirection: "column",
						alignItems: "flex-start",
						justifyContent: "center",
						"& .MuiStepLabel-label": {
							fontSize: 10,
						},
					},
					maxWidth: orientation === "vertical" ? 50 : "100%",
				}}
			>
				{steps.map((label, index) => (
					<Step key={index}>
						<StepLabel>{label} </StepLabel>
					</Step>
				))}
			</Steps>
		</Box>
	);
}
