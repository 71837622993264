import { useContext, useEffect, useState } from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { PropTypes } from "./LCA.types";
import { Heading } from "../Heading";
import { CreateLCAsTable } from "../CreateLCAsTable";
import { useParams } from "react-router-dom";
import { lca as lcaActions } from "../../controllers";
import { useQuery } from "react-query";
import { ILCA } from "../../models";
import { useSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import ChevronIcon from "@mui/icons-material/ChevronLeft";

export function LCA(props: PropTypes) {
	const [showLCAInfo, setShowLCAInfo] = useState(false);
	const [selectedLCA, setSelectedLCA] = useState<ILCA | null>(null);
	const [collapsed, setCollapsed] = useState(true);
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const { id: LCAId } = useParams();

	const { refetch } = useQuery(
		"lca",
		() =>
			lcaActions.getOne(
				parseInt(LCAId!),
				user?.is_superuser ? undefined : user?.company.id ?? 0
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (res.lca_versions) {
					res.lca_versions.forEach((lcaVersion) => {
						if (
							lcaVersion.lca_standards &&
							!Array.isArray(lcaVersion.lca_standards)
						) {
							lcaVersion.lca_standards = JSON.parse(lcaVersion.lca_standards);
						}
					});
				}
				setSelectedLCA({ ...res });
				setShowLCAInfo(true);
				console.log(showLCAInfo);
			},
			onError: (error: any) => {
				console.log(error);
				setSelectedLCA(null);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (LCAId && !isNaN(parseInt(LCAId))) {
			refetch();
		} else {
			setShowLCAInfo(true);
		}
	}, [LCAId, refetch, setShowLCAInfo]);

	return (
		<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Heading
				title={
					LCAId
						? "Edit your Life Cycle Assessment (LCA)"
						: "Add new Life Cycle Assessments (LCAs)"
				}
				subtitle={
					LCAId
						? "Fill out the information below to edit the LCA and continue the process of certifying or recertifying your products."
						: "Fill out the information below to add new LCAs and begin the process of certifying your products. "
				}
				showSearch={false}
				setShowProductInformation={undefined}
				noMarginBottom
			/>

			<Box sx={{ marginTop: 2 }}>
				<CreateLCAsTable
					selectedLCA={selectedLCA}
					setShowLCAInfo={setShowLCAInfo}
					index={0}
				></CreateLCAsTable>
			</Box>

			<Divider sx={{ marginTop: 8, marginBottom: 8 }} />

			{(selectedLCA?.lca_versions?.length ?? 0) > 1 && (
				<Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							gap: 1,
							marginBottom: 2,
						}}
					>
						<Typography variant="h6">Previous Versions</Typography>
						<IconButton
							color="primary"
							onClick={() => setCollapsed((collapsed) => !collapsed)}
							sx={{
								transform: "rotate(" + (collapsed ? "-" : "") + "90deg)",
							}}
						>
							<ChevronIcon />
						</IconButton>
					</Box>
					<Box
						sx={{
							height: collapsed ? 0 : "auto",
							overflow: collapsed ? "hidden" : "visible",
						}}
					>
						{selectedLCA?.lca_versions
							?.filter((_, index) => index > 0)
							.map((_, index) => (
								<Box sx={{ marginBottom: 2 }}>
									<CreateLCAsTable
										selectedLCA={selectedLCA}
										setShowLCAInfo={() => {}}
										readMode={true}
										index={index}
									></CreateLCAsTable>
								</Box>
							))}
					</Box>
				</Box>
			)}
		</Box>
	);
}
